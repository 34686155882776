import React from "react"
import { Table } from "semantic-ui-react"

const EditableRow = ({
  editFormData,
  handleEditFormChange,
  handleCancelClick,
}) => {
  return (
    <Table.Row>
      <Table.Cell>
        <div className="ui input focus">
          <input
            type="text"
            required="required"
            placeholder="Enter a name..."
            name="name"
            value={editFormData.name}
            onChange={handleEditFormChange}
          ></input>
        </div>
      </Table.Cell>
      <Table.Cell>
        <div className="ui input focus">
          <input
            type="text"
            required="required"
            placeholder="Enter a bib number..."
            name="bib_number"
            value={editFormData.bib_number}
            onChange={handleEditFormChange}
          ></input>
        </div>
      </Table.Cell>
      <Table.Cell>
        <div className="ui input focus">
          <input
            type="text"
            placeholder="Enter gender ..."
            name="gender"
            value={editFormData.gender}
            onChange={handleEditFormChange}
          ></input>
        </div>
      </Table.Cell>
      <Table.Cell>
        <div className="ui input focus">
          <input
            type="text"
            placeholder="Enter age ..."
            name="age"
            value={editFormData.age}
            onChange={handleEditFormChange}
          ></input>
        </div>
      </Table.Cell>
      <Table.Cell>
        <div className="ui input focus">
          <input
            type="text"
            required="required"
            placeholder="Enter contact number..."
            name="contact_number"
            value={editFormData.contact_number}
            onChange={handleEditFormChange}
          ></input>
        </div>
      </Table.Cell>

      <Table.Cell>
        <div className="ui input focus">
          <input
            type="email"
            required="required"
            placeholder="Enter an email..."
            name="email"
            value={editFormData.email_id}
            onChange={handleEditFormChange}
          ></input>
        </div>
      </Table.Cell>

      <Table.Cell>
        <div className="ui input focus">
          <input
            type="text"
            placeholder="Enter dob ..."
            name="date_of_birth"
            value={editFormData.date_of_birth}
            onChange={handleEditFormChange}
          ></input>
        </div>
      </Table.Cell>
      <Table.Cell>
        <div className="ui input focus">
          <input
            type="text"
            name="age_group"
            placeholder="Age Group"
            value={editFormData.age_group}
            onChange={handleEditFormChange}
          />
        </div>
      </Table.Cell>

      <Table.Cell>
        <button className="ui positive basic button" type="submit">
          Save
        </button>
        <button
          className="ui secondary basic button"
          type="button"
          onClick={handleCancelClick}
        >
          Cancel
        </button>
      </Table.Cell>
    </Table.Row>
  )
}

export default EditableRow
