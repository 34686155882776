import React, { Fragment, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Segment,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from 'semantic-ui-react'
import Select from 'react-select'
import {
  clearAssignUserEvents,
  getEvents,
  getUserPhotographerTaggings,
} from '../../../events/actions/eventActions'
import Spinner from '../../../shared/components/Spinner'
import {
  addContractorAssignment,
  clearAssignedEventContractors,
  clearAssignedEventPhotographers,
  getAssignedEventContractors,
  getAssignedEventPhotographers,
  modifyContractorAssignment,
  removeContractorAssignment,
} from '../../actions/user'

const TaggingManagement = () => {
  const { events, tagging } = useSelector((state) => state.eventReducer)
  const { assignedEventPhotographers, assignedEventContractors, loading } =
    useSelector((state) => state.userReducer)
  const [eventDropdown, setEventDropdown] = useState([])
  const [contractorDropdown, setContractorDropdown] = useState(null)
  const [photographerContractorMap, setPhotographerContractorMap] =
    useState(null)
  const [eventId, setEventId] = useState(null)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(clearAssignedEventContractors())
    dispatch(clearAssignedEventPhotographers())
    dispatch(getEvents())
  }, [dispatch])

  useEffect(() => {
    let val = []
    if (events) {
      events.map((event) => {
        val.push({
          value: event['id'],
          label: event['name'],
        })
      })
      setEventDropdown(val)
    }
  }, [events])

  useEffect(() => {
    if (eventId) {
      setPhotographerContractorMap(null)
      dispatch(clearAssignedEventContractors())
      dispatch(clearAssignedEventPhotographers())
      dispatch(clearAssignUserEvents())
      dispatch(getAssignedEventPhotographers(eventId))
      dispatch(getAssignedEventContractors(eventId))
      dispatch(getUserPhotographerTaggings(eventId))
    }
  }, [dispatch, eventId])

  useEffect(() => {
    let val = []
    if (assignedEventContractors) {
      assignedEventContractors.map((assignedEventContractor) => {
        val.push({
          value: assignedEventContractor.user.id,
          label:
            assignedEventContractor.user.name +
            ' - ' +
            assignedEventContractor.user.email,
        })
      })
      setContractorDropdown(val)
    }
  }, [assignedEventContractors])

  useEffect(() => {
    let val = {}
    if (tagging && assignedEventPhotographers && assignedEventContractors) {
      assignedEventPhotographers.map((eventPhotographer) => {
        const eventUserIndex = tagging.findIndex(
          (obj) => obj.assigned_photographer === eventPhotographer.user.id
        )
        if (eventUserIndex >= 0) {
          const contractorIndex = assignedEventContractors.findIndex(
            (contractor) => contractor.user.id === tagging[eventUserIndex].user
          )

          if (contractorIndex >= 0) {
            val[eventPhotographer.user.id] = {
              value: assignedEventContractors[contractorIndex].user.id,
              label:
                assignedEventContractors[contractorIndex].user.name +
                ' - ' +
                assignedEventContractors[contractorIndex].user.email,
            }
          }
        } else {
          val[eventPhotographer.user.id] = {}
        }
      })
      setPhotographerContractorMap(val)
    }
  }, [tagging, assignedEventPhotographers, assignedEventContractors])

  const onChange = (e) => {
    setEventId(e.value)
  }

  const getValueByKey = (obj, matchingKey) => {
    for (const [key, value] of Object.entries(obj)) {
      if (key == matchingKey) {
        return value
      }
    }
  }

  const onContractorChange = (e, photographerId, defaultValue) => {
    //Contractor Id

    if (e === null) {
      dispatch(removeContractorAssignment(eventId, photographerId))
      photographerContractorMap[photographerId] = {}
    } else {
      const previousPhotographer = getValueByKey(
        photographerContractorMap,
        photographerId
      )

      if (Object.keys(previousPhotographer).length === 0) {
        dispatch(addContractorAssignment(eventId, e.value, photographerId))
      } else {
        dispatch(modifyContractorAssignment(eventId, e.value, photographerId))
      }
      photographerContractorMap[photographerId] = {
        value: e.value,
        label: e.label,
      }
    }
    setPhotographerContractorMap(photographerContractorMap)
  }

  return (
    <Fragment>
      {loading ? (
        <Spinner />
      ) : (
        <Fragment>
          <h1 className='large text-primary'>Tagging Management</h1>
          <p className='lead'>
            <i className='fab fa-connectdevelop'></i>Assign Photographer images
            to Contractors
          </p>
          {eventDropdown.length === 0 ? (
            <Spinner />
          ) : (
            <div>
              Select Event
              <Select
                className='basic-single'
                classNamePrefix='select'
                isDisabled={false}
                isLoading={false}
                isClearable={true}
                isRtl={false}
                isSearchable={true}
                name='events'
                id='events'
                options={eventDropdown}
                onChange={(event) => onChange(event)}
              />
            </div>
          )}
          <br />
          <br />
          <br />
          {assignedEventContractors &&
          assignedEventPhotographers &&
          photographerContractorMap &&
          contractorDropdown ? (
            <div className='profiles'>
              <form className='form'>
                <Table>
                  <TableHeader>
                    <TableRow>
                      <TableHeaderCell>Sl No</TableHeaderCell>
                      <TableHeaderCell>Photographer</TableHeaderCell>
                      <TableHeaderCell>Email</TableHeaderCell>
                      <TableHeaderCell>Contractor</TableHeaderCell>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {assignedEventPhotographers.length > 0 ? (
                      assignedEventPhotographers.map(
                        (assignedEventPhotographer, index) => (
                          <TableRow key={index}>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>
                              {assignedEventPhotographer.user.name}
                            </TableCell>
                            <TableCell>
                              {assignedEventPhotographer.user.email}
                            </TableCell>
                            <TableCell>
                              <div className='form-group'>
                                <Select
                                  className='basic-single'
                                  classNamePrefix='select'
                                  isDisabled={false}
                                  isLoading={false}
                                  isClearable={true}
                                  isRtl={false}
                                  isSearchable={true}
                                  name='contractors'
                                  id='contractors'
                                  options={contractorDropdown}
                                  defaultValue={
                                    photographerContractorMap[
                                      assignedEventPhotographer.user.id
                                    ]
                                  }
                                  onChange={(event) =>
                                    onContractorChange(
                                      event,
                                      assignedEventPhotographer.user.id,
                                      photographerContractorMap[
                                        assignedEventPhotographer.user.id
                                      ]
                                    )
                                  }
                                />
                              </div>
                            </TableCell>
                          </TableRow>
                        )
                      )
                    ) : (
                      <TableRow>
                        <TableCell>
                          <>No profiles found...</>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </form>
            </div>
          ) : (
            <Tab.Pane>
              <h3> Configure Photographers and Contractors for events</h3>
              <br></br>
              <Segment>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
              </Segment>
            </Tab.Pane>
          )}
        </Fragment>
      )}
    </Fragment>
  )
}

export default TaggingManagement
