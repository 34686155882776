import { SET_UPLOAD_FILE, UPLOAD_ERROR } from './types'

//
export const setSearchUploadFile = (file) => async (dispatch) => {
  try {
    dispatch({ type: SET_UPLOAD_FILE, payload: file })
  } catch (err) {
    dispatch({
      type: UPLOAD_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    })
  }
}

export const setUploadFile = (data) => async (dispatch) => {
  try {
    dispatch({ type: SET_UPLOAD_FILE, payload: data })
  } catch (err) {
    dispatch({
      type: UPLOAD_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    })
  }
}
