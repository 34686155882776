import {
  ADD_EVENT,
  UPDATE_EVENT,
  GET_EVENTS,
  EVENT_ERROR,
  GET_EVENT,
  GET_USER_EVENT_MAP,
  GET_UPLOADED_DATA,
  IDENTIFIER_EXECUTION_STARTED,
  IDENTIFIER_EXECUTION_FINISHED,
  BRANDING_STARTED,
  BRANDING_FINISHED,
  SET_TAGGING,
  INCREMENT_UPLOAD_COUNT,
  SET_UPLOADED_LOCAL_FILES,
  UPDATE_UPLOADED_LOCAL_FILES,
  RESET_UPLOAD_COUNT,
  INCREMENT_IGNORED_UPLOAD_COUNT,
  RESET_IGNORED_UPLOAD_COUNT,
  SET_EXECUTION_ENVIRONMENT,
  SET_BIB_FILTER,
  SET_BRANDING_COUNT,
  SET_NON_BRANDED_IMAGES,
  SET_FAILED_BRANDING_COUNT,
  SET_EVENT_TYPES,
  SET_PHOTO_POINTS,
  SET_BRANDING_FILTERS,
  SET_BRANDING_FILTER_OPTIONS,
  SET_BRANDING_CATEGORY,
  SET_ALL_BRANDING_CATEGORIES,
  SET_BRANDING_RULES,
  SET_BRANDING_RULE_CATEGORIES,
  BRANDING_IMAGES,
  SET_PHOTOGRAPHER_IMAGE_URLS,
  SET_EVENT_USERS,
} from "../actions/eventTypes"

const initialState = {
  events: [],
  eventTypes: null,
  event: "",
  loading: true,
  error: {},
  userEventMap: [],
  eventUsers: null,
  uploadedData: null,
  identifierExecution: null,
  brandingImages: null,
  tagging: null,
  uploadingCount: 0,
  ignoredCount: 0,
  uploadedLocalFiles: null,
  executionEnv: null,
  bibFilter: [],
  nonBrandedImages: null,
  brandingCount: 0,
  failedBrandingCount: 0,
  photoPoints: null,
  brandingFilters: null,
  brandingFilterOptions: null,
  brandingCategories: null,
  allBrandingCategories: null,
  brandingRules: null,
  brandingRuleCategories: null,
  photographerImageUrls: null,
}

export default function (state = initialState, actions) {
  const { type, payload } = actions

  switch (type) {
    case GET_EVENTS:
      return { ...state, events: payload, loading: false }

    case GET_EVENT:
      var result = state.events.find((event) => event.id == payload)
      return {
        ...state,
        event: result,
        loading: false,
      }
    case ADD_EVENT:
      return {
        ...state,
        events: [payload, ...state.events],
        loading: false,
      }
    case UPDATE_EVENT:
      return {
        ...state,
        events: [
          ...state.events.filter((eventItem) => eventItem.id !== payload.id),
          payload,
        ],
        loading: false,
      }
    case SET_BIB_FILTER:
      return { ...state, bibFilter: payload, loading: false }
    case GET_USER_EVENT_MAP:
      if (payload === null) {
      } else if ("data" in payload) {
        return { ...state, userEventMap: [], loading: false }
      }
      return { ...state, userEventMap: payload, loading: false }
    case SET_EVENT_TYPES:
      return { ...state, eventTypes: payload }
    case SET_PHOTO_POINTS:
      return { ...state, photoPoints: payload }
    case GET_UPLOADED_DATA:
      return { ...state, uploadedData: payload, loading: false }
    case EVENT_ERROR:
      return { ...state, error: payload, loading: false }
    case IDENTIFIER_EXECUTION_STARTED:
      return { ...state, identifierExecution: true }
    case IDENTIFIER_EXECUTION_FINISHED:
      return { ...state, identifierExecution: false }
    case BRANDING_STARTED:
      return { ...state, loading: true }
    case BRANDING_FINISHED:
      return { ...state, loading: false }
    case SET_NON_BRANDED_IMAGES:
      return { ...state, nonBrandedImages: payload }
    case SET_BRANDING_COUNT:
      return { ...state, brandingCount: state.brandingCount + payload }
    case SET_FAILED_BRANDING_COUNT:
      return {
        ...state,
        failedBrandingCount: state.failedBrandingCount + payload,
      }
    case BRANDING_IMAGES:
      return { ...state, brandingImages: payload }
    case SET_TAGGING:
      return { ...state, tagging: payload }
    case INCREMENT_UPLOAD_COUNT:
      return { ...state, uploadingCount: state.uploadingCount + 1 }
    case RESET_UPLOAD_COUNT:
      return { ...state, uploadingCount: 0 }
    case SET_UPLOADED_LOCAL_FILES:
      return { ...state, uploadedLocalFiles: payload, loading: false }
    case UPDATE_UPLOADED_LOCAL_FILES:
      return { ...state, uploadedLocalFiles: payload, loading: false }
    case INCREMENT_IGNORED_UPLOAD_COUNT:
      return { ...state, ignoredCount: state.ignoredCount + 1 }
    case RESET_IGNORED_UPLOAD_COUNT:
      return { ...state, ignoredCount: 0 }
    case SET_EXECUTION_ENVIRONMENT:
      return { ...state, executionEnv: payload }
    case SET_BRANDING_FILTERS:
      return { ...state, brandingFilters: payload }
    case SET_BRANDING_FILTER_OPTIONS:
      let values = {}
      Object.entries(payload).map(([key, value]) => {
        values[key.toUpperCase()] = value
      })
      return { ...state, brandingFilterOptions: values }
    case SET_BRANDING_CATEGORY:
      return { ...state, brandingCategories: payload }
    case SET_ALL_BRANDING_CATEGORIES:
      return { ...state, allBrandingCategories: payload }
    case SET_BRANDING_RULES:
      return { ...state, brandingRules: payload }
    case SET_BRANDING_RULE_CATEGORIES:
      return { ...state, brandingRuleCategories: payload }
    case SET_PHOTOGRAPHER_IMAGE_URLS:
      let matches = payload[0]["matches"]
      return { ...state, photographerImageUrls: matches }
    case SET_EVENT_USERS:
      return { ...state, eventUsers: payload }
    default:
      return state
  }
}
