import React from "react"
import { Button, Card, Icon } from "semantic-ui-react"
import { Link } from "react-router-dom"

const ParticipantCards = (event) => {
  return (
    <Card>
      <Card.Content>
        <Card.Header>Participants</Card.Header>
        <Card.Meta>Configure particpant information</Card.Meta>
        <Icon.Group size="large">
          <Icon name="users" />
        </Icon.Group>
        <Card.Description>
          Bulk upload and manage particpant information
        </Card.Description>
      </Card.Content>
      <Card.Content extra>
        <div className="ui two buttons">
          <Button basic color="green">
            <Link
              to={`/event/${event.id}/participant-home`}
              className="btn btn-light"
            >
              Manage
            </Link>
          </Button>
        </div>
      </Card.Content>
    </Card>
  )
}

export default ParticipantCards
