import React, { useEffect, Fragment } from "react"
import {
  Message,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from "semantic-ui-react"
import { useSelector, useDispatch } from "react-redux"
import { getUploadedData } from "../actions/eventActions"
import Spinner from "../../shared/components/Spinner"

function UploadStatus() {
  const { event } = useSelector((state) => state.eventReducer)
  const { uploadedData, loading } = useSelector((state) => state.eventReducer)
  const auth = useSelector((state) => state.auth)
  const { user } = auth

  const dispatch = useDispatch()

  useEffect(() => {
    if (event && user) {
      dispatch(getUploadedData(event.id, user.id))
    }
  }, [dispatch, event, user])

  return (
    <Fragment>
      {loading ? (
        <Spinner />
      ) : (
        <div>
          <Message>Folders uploaded by {user.name} are listed below</Message>
          <h2>Successfully Uploaded Folders</h2>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHeaderCell>Folders</TableHeaderCell>
                <TableHeaderCell>Image Count</TableHeaderCell>
              </TableRow>
            </TableHeader>
            <TableBody>
              {uploadedData && uploadedData.success ? (
                uploadedData.success.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>{item[0]}</TableCell>
                    <TableCell>{item[1]}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>No Uploads</TableRow>
              )}
            </TableBody>
          </Table>
          <h2>Failed Folders</h2>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHeaderCell>Folders</TableHeaderCell>
                <TableHeaderCell>Image Count</TableHeaderCell>
              </TableRow>
            </TableHeader>
            <TableBody>
              {uploadedData && uploadedData.fail ? (
                uploadedData.fail.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>{item[0]}</TableCell>
                    <TableCell>{item[1]}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell>No Uploads</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
      )}
    </Fragment>
  )
}

export default UploadStatus
