import axios from 'axios'
import { setAlert } from '../../shared/actions/alert'
import {
  SET_ASSIGNED_EVENT_CONTRACTORS,
  SET_ASSIGNED_EVENT_PHOTOGRAPHERS,
  SET_CONTRACTORS,
  SET_PHOTOGRAPHERS,
  USER_ERROR,
} from './types'

const config = {
  headers: { 'Content-Type': 'application/json' },
}

//
export const getAssignedEventPhotographers = (eventId) => async (dispatch) => {
  try {
    const res = await axios.get(
      '/api/user/event/role/mapping',
      {
        params: {
          event_id: eventId,
          role: 'PHOTOGRAPHER',
        },
      },
      config
    )
    // if (res.data.length === 0) {
    //   dispatch({ type: SET_ASSIGNED_EVENT_PHOTOGRAPHERS, payload: null })
    // } else {
    //   dispatch({ type: SET_ASSIGNED_EVENT_PHOTOGRAPHERS, payload: res.data })
    // }
    dispatch({ type: SET_ASSIGNED_EVENT_PHOTOGRAPHERS, payload: res.data })
  } catch (err) {
    dispatch({ type: USER_ERROR })
  }
}
//
export const getAssignedEventContractors = (eventId) => async (dispatch) => {
  try {
    const res = await axios.get(
      '/api/user/event/role/mapping',
      {
        params: {
          event_id: eventId,
          role: 'CONTRACTOR',
        },
      },
      config
    )
    dispatch({ type: SET_ASSIGNED_EVENT_CONTRACTORS, payload: res.data })
  } catch (err) {
    dispatch({ type: USER_ERROR })
  }
}
//
export const clearAssignedEventPhotographers = () => async (dispatch) => {
  try {
    dispatch({ type: SET_ASSIGNED_EVENT_PHOTOGRAPHERS, payload: null })
  } catch (err) {
    dispatch({ type: USER_ERROR })
  }
}
//
export const clearAssignedEventContractors = () => async (dispatch) => {
  try {
    dispatch({ type: SET_ASSIGNED_EVENT_CONTRACTORS, payload: null })
  } catch (err) {
    dispatch({ type: USER_ERROR })
  }
}
//
export const getEventPhotographers = () => async (dispatch) => {
  try {
    const res = await axios.get(
      '/api/users',
      {
        params: {
          role: 'PHOTOGRAPHER',
        },
      },
      config
    )
    dispatch({ type: SET_PHOTOGRAPHERS, payload: res.data })
  } catch (err) {
    dispatch({ type: USER_ERROR })
  }
}
//
export const getEventContractors = () => async (dispatch) => {
  try {
    const res = await axios.get(
      '/api/users',
      {
        params: {
          role: 'CONTRACTOR',
        },
      },
      config
    )
    dispatch({ type: SET_CONTRACTORS, payload: res.data })
  } catch (err) {
    dispatch({ type: USER_ERROR })
  }
}

export const assignUserToEvent = (eventId, userId) => async (dispatch) => {
  try {
    const body = JSON.stringify({
      event_id: eventId,
      user_id: userId,
    })
    const res = await axios.post('/api/user/event/mapping', body, config)
  } catch (err) {
    dispatch({ type: USER_ERROR })
  }
}

export const removeUserFromEvent = (eventId, userId) => async (dispatch) => {
  try {
    const res = await axios.delete(
      '/api/user/event/mapping/' + eventId + '/' + userId,
      config
    )
  } catch (err) {
    dispatch({ type: USER_ERROR })
  }
}

export const assignContractorToPhotographer =
  (eventId, contractorId, photographerId) => async (dispatch) => {
    try {
      const body = JSON.stringify({
        event_id: eventId,
        user_id: contractorId,
        assigned_photographer_id: photographerId,
      })
      const res = await axios.post('/api/user/event/tagging', body, config)
    } catch (err) {
      dispatch({ type: USER_ERROR })
    }
  }

export const removeContractorFromPhotographer =
  (eventId, contractorId, photographerId) => async (dispatch) => {
    try {
      const res = await axios.delete(
        '/api/user/event/tagging',
        {
          params: {
            assigned_photographer_id: photographerId,
            event_id: eventId,
            user_id: contractorId,
          },
        },
        config
      )
    } catch (err) {
      dispatch({ type: USER_ERROR })
    }
  }

export const addContractorAssignment =
  (eventId, contractorId, photographerId) => async (dispatch) => {
    try {
      const body = JSON.stringify({
        event_id: eventId,
        user_id: contractorId,
        assigned_photographer_id: photographerId,
      })
      const res = await axios.post('/api/user/event/tagging', body, config)
    } catch (err) {
      dispatch({ type: USER_ERROR })
    }
  }

export const removeContractorAssignment =
  (eventId, photographerId) => async (dispatch) => {
    try {
      const res = await axios.delete(
        '/api/user/event/tagging',
        {
          params: {
            assigned_photographer_id: photographerId,
            event_id: eventId,
          },
        },
        config
      )
    } catch (err) {
      dispatch({ type: USER_ERROR })
    }
  }

export const modifyContractorAssignment =
  (eventId, contractorId, photographerId) => async (dispatch) => {
    try {
      const body = JSON.stringify({
        event_id: eventId,
        user_id: contractorId,
        assigned_photographer_id: photographerId,
      })
      const res = await axios.put('/api/user/event/tagging', body, config)
    } catch (err) {
      dispatch({ type: USER_ERROR })
    }
  }
