import { SET_UPLOAD_FILE, UPLOAD_ERROR, CLEAR_UPLOAD } from '../actions/types'

const initialState = {
  fileToUpload: null,
  loading: true,
}

export default function (state = initialState, actions) {
  const { type, payload } = actions

  switch (type) {
    case SET_UPLOAD_FILE:
      return { ...state, fileToUpload: payload, loading: false }
    case CLEAR_UPLOAD:
      return { ...state, fileToUpload: null, loading: false }
    case UPLOAD_ERROR:
      return { ...state, error: payload, loading: false }
    default:
      return state
  }
}
