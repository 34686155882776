export const GET_IDENTIFIED_FACES = 'GET_IDENTIFIED_FACES'
export const CLEAR_IDENTIFIED_FACES = 'CLEAR_IDENTIFIED_FACES'
export const FACE_ERROR = 'FACE_ERROR'
export const SEND_SEARCH_IMAGE = 'SEND_SEARCH_IMAGE'
export const SET_SEARCH_IMAGE = 'SET_SEARCH_IMAGE'
export const SET_SEARCH_IMAGE_RESULTS = 'SET_SEARCH_IMAGE_RESULTS'
export const CLEAR_SEARCH_PAGE = 'CLEAR_SEARCH_PAGE'
export const GET_VERIFIED_FACES = 'GET_VERIFIED_FACES'
export const SET_HIDE_FORM_ID = 'SET_HIDE_FORM_ID'
export const SET_SEARCH_IMAGE_NO_MATCHES = 'SET_SEARCH_IMAGE_NO_MATCHES'
export const SET_ASSIGNED_PHOTOGRAPHERS = 'SET_ASSIGNED_PHOTOGRAPHERS'
export const SET_ASSIGNED_PHOTOGRAPHS = 'SET_ASSIGNED_PHOTOGRAPHS'
export const CLEAR_ASSIGNED_PHOTOGRAPHERS = 'CLEAR_ASSIGNED_PHOTOGRAPHERS'
export const CLEAR_ASSIGNED_PHOTOGRAPHS = 'CLEAR_ASSIGNED_PHOTOGRAPHS'
export const SET_TAGGING_FILTER = 'TAGGING_FILTER'
export const SET_BIB_RESULTS = 'SET_BIB_RESULTS'
export const SET_LOCATION_TIME_RESULTS = 'SET_LOCATION_TIME_RESULTS'
export const CLEAR_BIB_RESULTS = 'CLEAR_BIB_RESULTS'
export const SET_TAGGING_STATISTICS = 'SET_TAGGING_STATISTICS'
export const SET_SEARCH_RESULTS = 'SET_SEARCH_RESULTS'
export const CLEAR_SEARCH_RESULTS = 'CLEAR_SEARCH_RESULTS'
export const UPDATE_SERACH_RESULTS = 'UPDATE_SERACH_RESULTS'
export const SET_LOADING = 'SET_LOADING'
