import React from 'react'
import { Button, Card, Icon } from 'semantic-ui-react'
import { Link } from 'react-router-dom'

const ParticipantPaymentModuleCard = () => {
  return (
    <Card>
      <Card.Content>
        <Card.Header>Participant Payments</Card.Header>
        <Card.Meta>Configure Payment Modules</Card.Meta>
        <Icon.Group size='large'>
          <Icon name='users' />
        </Icon.Group>
        <Card.Description>
          Helps admin configure payment modules
        </Card.Description>
      </Card.Content>
      <Card.Content extra>
        <div className='ui two buttons'>
          <Button basic color='green'>
            <Link
              to={`/payments/participant/configure`}
              className='btn btn-light'>
              Configure Payment Modules
            </Link>
          </Button>
        </div>
      </Card.Content>
    </Card>
  )
}

export default ParticipantPaymentModuleCard
