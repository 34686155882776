import React from "react"
import { Table } from "semantic-ui-react"

const ReadOnlyRow = ({ contact, handleEditClick, handleDeleteClick }) => {
  return (
    <Table.Row>
      <Table.Cell>{contact.name}</Table.Cell>
      <Table.Cell>{contact.bib_number}</Table.Cell>
      <Table.Cell>{contact.gender}</Table.Cell>
      <Table.Cell>{contact.age}</Table.Cell>
      <Table.Cell>{contact.contact_number}</Table.Cell>
      <Table.Cell>{contact.email_id}</Table.Cell>
      <Table.Cell>{contact.date_of_birth}</Table.Cell>
      <Table.Cell>{contact.age_group}</Table.Cell>
      <Table.Cell>
        <button
          className="ui primary basic button"
          type="button"
          onClick={(event) => handleEditClick(event, contact)}
        >
          Edit
        </button>
        <button
          className="ui negative basic button"
          type="button"
          onClick={() => handleDeleteClick(contact.id)}
        >
          Delete
        </button>
      </Table.Cell>
    </Table.Row>
  )
}

export default ReadOnlyRow
