import React, { Fragment } from "react"
import { useSelector } from "react-redux"
import { Card } from "semantic-ui-react"
import SeederCard from "./cards/SeederCard"
import ParticipantCard from "./cards/ParticipantCard"

const ParticipantScreen = () => {
  const { event } = useSelector((state) => state.eventReducer)

  return (
    <Fragment>
      <h1 className="large text-primary">
        Participant Home Page - {event.name}
      </h1>
      <Fragment>
        <Card.Group>
          <SeederCard {...event} />
          <ParticipantCard {...event} />
        </Card.Group>
      </Fragment>
    </Fragment>
  )
}

export default ParticipantScreen
