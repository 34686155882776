import React from 'react'
import { Button, Card, Icon } from 'semantic-ui-react'
import { Link } from 'react-router-dom'

const ProfileCard = () => {
  return (
    <Card>
      <Card.Content>
        <Card.Header>Profile</Card.Header>
        <Card.Meta>Profile Management</Card.Meta>
        <Icon.Group size='large'>
          <Icon size='large' name='circle outline' />
          <Icon name='user' />
        </Icon.Group>
        <Card.Description>Configure User Profile</Card.Description>
      </Card.Content>
      <Card.Content extra>
        <div className='ui two buttons'>
          <Button basic color='green'>
            <Link to='/edit-profile' className='btn btn-light'>
              Edit Profile
            </Link>
          </Button>
        </div>
      </Card.Content>
    </Card>
  )
}

export default ProfileCard

// <div className='dash-buttons'>
//   <Link to='/edit-profile' className='btn btn-light'>
//     <i className='fas fa-user-circle text-primary'></i> Edit Profile
//   </Link>
//   {/* <Link to="/add-experience" className="btn btn-light">
//     <i className="fab fa-black-tie text-primary"></i> Add Experience
//   </Link>
//   <Link to="/add-education" className="btn btn-light">
//     <i className="fas fa-graduation-cap text-primary"></i> Add Education
//   </Link> */}
// </div>
