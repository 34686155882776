import axios from 'axios'

export function getAPI(url, header) {
  return new Promise((resolve) => {
    resolve(axios.get(url, header))
  })
}

export function postAPI(url, body, header) {
  return new Promise((resolve) => {
    resolve(axios.post(url, body, header))
  })
}

export function putAPI(url, body, header) {
  return new Promise((resolve) => {
    resolve(axios.put(url, body, header))
  })
}

export function deleteAPI(url, header) {
  return new Promise((resolve) => {
    resolve(axios.delete(url, header))
  })
}
