import React from "react"
import { useSelector } from "react-redux"
import { Draggable, Droppable } from "react-beautiful-dnd"
import Box from "@mui/material/Box"
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import { FilterConditions } from "./FilterConditions"

export const BrandingFilter = ({ column, columnId }) => {
  const { brandingFilterOptions } = useSelector((state) => state.eventReducer)

  return (
    <>
      <Droppable droppableId={columnId} key={columnId}>
        {(provided, snapshot) => {
          return (
            <div>
              <div>
                <div className="column">
                  <div
                    className="ui segment"
                    style={{
                      width: "675px",
                      height: "120px",
                      background: snapshot.isDraggingOver
                        ? "lightblue"
                        : "lightgrey",
                    }}
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    <div
                      style={{
                        display: "flex",
                      }}
                    >
                      {column.items.map((item, index) => {
                        return (
                          <div
                            style={{
                              display: "flex",
                            }}
                            key={index}
                          >
                            <div>
                              <Draggable
                                key={item.id}
                                draggableId={item.id}
                                index={index}
                              >
                                {(provided, snapshot) => {
                                  return (
                                    <Card
                                      sx={{ width: "100%" }}
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      <Box
                                        sx={{
                                          display: "flex",
                                          flexDirection: "row",
                                        }}
                                      >
                                        <CardContent sx={{ flex: "1 0 auto" }}>
                                          <FilterConditions
                                            filter={item.content}
                                            options={
                                              brandingFilterOptions[
                                                item.content
                                              ]
                                            }
                                            columnId={columnId}
                                          />
                                        </CardContent>
                                      </Box>
                                    </Card>
                                  )
                                }}
                              </Draggable>
                            </div>
                            <div>&nbsp;</div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </div>
              </div>
              {provided.placeholder}
            </div>
          )
        }}
      </Droppable>
    </>
  )
}
