import React, { useState, useEffect } from "react"

import classnames from "classnames"
import { CardGroup } from "semantic-ui-react"
import { usePagination } from "./usePagination"
import "./PaginationCards.module.scss"
import "./Pagination.scss"
import Spinner from "../Spinner"
import ImageListCard from "./ImageListCard"

export const DOTS = "..."

function ImageListPaginator({
  loading,
  paginatedData,
  pageSize,
  pageHeader,
  totalCount,
}) {
  const [page, setPage] = useState(0)
  const [images, setImages] = useState(null)
  const [lastpage, setLastpage] = useState(null)
  const currentPage = page
  const siblingCount = 1

  const paginationRange = usePagination({
    totalCount,
    pageSize,
    siblingCount,
    currentPage,
  })

  useEffect(() => {
    if (!paginationRange) return
    setLastpage(paginationRange[paginationRange.length - 1])
  }, [paginationRange])

  useEffect(() => {
    if (paginatedData) {
      setImages(paginatedData[page])
    }
  }, [loading, page, paginatedData])

  const handlePage = (index) => {
    setPage(index)
  }

  const nextPage = () => {
    if (!paginatedData) return
    if (page === paginatedData.length - 1) {
      setPage(0)
    } else {
      setPage(page + 1)
    }
  }

  const prevPage = () => {
    if (page === 0) {
      if (!paginatedData) return
      setPage(paginatedData.length - 1)
    } else {
      setPage(page - 1)
    }
  }

  return (
    <main>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <div className="section-title">
            <h3>{pageHeader}</h3>
            <h4>{"Total Searches: " + totalCount}</h4>
            <div className="underline"></div>
          </div>
          <section className="followers">
            <div className="pagination-bar">
              <ul
                className={classnames("pagination-container", {
                  ["pagination-bar"]: "pagination-bar",
                })}
              >
                <li
                  className={classnames("pagination-item", {
                    disabled: currentPage === 1,
                  })}
                  onClick={() => prevPage()}
                >
                  <div className="arrow left" />
                </li>
                {paginationRange ? (
                  paginationRange.map((pageNumber, index) => {
                    if (pageNumber === DOTS) {
                      return (
                        <li className="pagination-item dots" key={index}>
                          &#8230;
                        </li>
                      )
                    }

                    return (
                      <li
                        className={classnames("pagination-item", {
                          selected: pageNumber === currentPage,
                        })}
                        onClick={() => handlePage(pageNumber)}
                        key={index}
                      >
                        {pageNumber}
                      </li>
                    )
                  })
                ) : (
                  <></>
                )}
                <li
                  className={classnames("pagination-item", {
                    disabled: currentPage === lastpage,
                  })}
                  onClick={() => nextPage()}
                >
                  <div className="arrow right" />
                </li>
              </ul>
            </div>
            <br></br>
            <br></br>
            <div>
              <CardGroup>
                {images ? <ImageListCard {...images} /> : <></>}
              </CardGroup>
            </div>
          </section>
        </>
      )}
    </main>
  )
}

export default ImageListPaginator
