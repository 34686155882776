import React, { useEffect, Fragment } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Spinner from '../../shared/components/Spinner'
import EventCard from './cards/EventCard'
import { getEventById } from '../actions/eventActions'

const EventScreen = ({ match }) => {
  const { id } = match.params
  const { event, loading } = useSelector((state) => state.eventReducer)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getEventById(id))
  }, [dispatch])

  return loading && event == null ? (
    <Spinner />
  ) : (
    <Fragment>
      <h1 className='large text-primary'>Event Management - {event.name}</h1>
      <Fragment>
        <EventCard {...event} />
      </Fragment>
    </Fragment>
  )
}

export default EventScreen
