import React, { Fragment } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Card } from 'semantic-ui-react'
import PhotographerPaymentCard from './cards/PhotographerPaymentCard'
import ParticipantPaymentModuleCard from './cards/PaymentModuleCard'

const PaymentScreen = () => {
  const { loading } = useSelector((state) => state.paymentReducer)
  const dispatch = useDispatch()
  return (
    <Fragment>
      <h1 className='large text-primary'>Payment Configurations</h1>
      <Fragment>
        <Card.Group>
          <ParticipantPaymentModuleCard />
          <PhotographerPaymentCard />
        </Card.Group>
      </Fragment>
    </Fragment>
  )
}

export default PaymentScreen
