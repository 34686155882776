import React from 'react'

import AddCircleIcon from '@material-ui/icons/AddCircle'
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle'
import TextField from '@material-ui/core/TextField'

import './CreateEditParticipantPayment.css'

const ParticipantPaymentFields = ({
  data,
  handleOnChange,
  addRow,
  removeRow,
  finalStepCount,
  finalStepPrice,
  onChange,
  id,
}) => {
  return (
    <div className='payment-container'>
      {data.map((items, i1) => (
        <div key={i1} className='payment-content'>
          <div className='payment-content-row'>
            {items.map((item, i2) =>
              'text' in item ? (
                <div key={i2}>
                  Step {item.text} {'   '}
                </div>
              ) : (
                <>
                  {id ? (
                    <TextField
                      key={i2}
                      label={item.label}
                      value={item.value}
                      onChange={(e) => handleOnChange(e, i1, i2)}
                      variant='outlined'
                      name={item.name}
                      readOnly='readonly'
                    />
                  ) : (
                    <TextField
                      key={i2}
                      label={item.label}
                      value={item.value}
                      onChange={(e) => handleOnChange(e, i1, i2)}
                      variant='outlined'
                      name={item.name}
                    />
                  )}
                </>
              )
            )}
          </div>
          {id ? (
            <></>
          ) : (
            <div>
              <AddCircleIcon onClick={addRow} />
              {data.length > 1 && (
                <RemoveCircleIcon onClick={() => removeRow(i1)} />
              )}
            </div>
          )}
        </div>
      ))}
      <div className='payment-container'>OR</div>
      <br></br>
      <div className='payment-container'>
        <div className='payment-content'>
          <div className='payment-content-row'>
            <div>Flat Price</div>
            {id ? (
              <>
                <TextField
                  label={`Price for ${finalStepCount} Photographs and above`}
                  value={finalStepCount}
                  onChange={onChange}
                  variant='outlined'
                  name='finalStepCount'
                  readOnly='readonly'
                />
                <TextField
                  label='Price'
                  value={finalStepPrice}
                  onChange={onChange}
                  variant='outlined'
                  name='finalStepPrice'
                  readOnly='readonly'
                />
              </>
            ) : (
              <>
                <TextField
                  label={`Price for ${finalStepCount} Photographs and above`}
                  value={finalStepCount}
                  onChange={onChange}
                  variant='outlined'
                  name='finalStepCount'
                />
                <TextField
                  label='Price'
                  value={finalStepPrice}
                  onChange={onChange}
                  variant='outlined'
                  name='finalStepPrice'
                />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ParticipantPaymentFields
