import { postAPI } from './apiClient'
import { jsonHeader } from './headers'
import { API_LOGGER } from './endPoints'

// Send logs to backend server
export const transportLogs = (message) => async () => {
  try {
    const response = await postAPI(API_LOGGER, message, jsonHeader)
    if (response) {
      if (response.status !== 200) {
        console.log('Log Transportation Failed at Server Side')
      }
    }
  } catch (err) {
    console.log('Log Transportation Failed')
  }
}
