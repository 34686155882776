import axios from 'axios'
import {
  GET_PARTICIPANT_PAYMENTS,
  GET_PARTICIPANT_PAYMENT_MODULES,
  PAYMENT_ERROR,
} from './paymentTypes'
import { setAlert } from '../../shared/actions/alert'

const config = {
  headers: { 'Content-Type': 'application/json' },
}

export const saveParticipantPaymentModule =
  (formData, history, flatTotal, stepCalculations) => async (dispatch) => {
    try {
      let optionsBody
      if (flatTotal !== null) {
        flatTotal = JSON.stringify([flatTotal.toString() + '*n'])
        optionsBody = { options: flatTotal }
      } else {
        stepCalculations = JSON.stringify(stepCalculations)
        stepCalculations = stepCalculations
          .replace(/ /g, '')
          .replace('more', 'n')
        optionsBody = { options: stepCalculations }
      }
      await axios
        .post(
          '/api/events/payment/participant',
          JSON.stringify(formData),
          config
        )
        .then(async (res) => {
          optionsBody['paymentModel'] = res.data
          await axios.post(
            '/api/events/payment/participant/options',
            JSON.stringify(optionsBody),
            config
          )
          dispatch(setAlert('Payment Module Created', 'success'))
          history.push('/payments/participant/configure')
        })
    } catch (err) {
      dispatch({
        type: PAYMENT_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status },
      })
    }
  }

export const getParticipantPayment = () => async (dispatch) => {
  try {
    await axios
      .get('/api/events/payment/participant', config)
      .then(async (res) => {
        dispatch({ type: GET_PARTICIPANT_PAYMENTS, payload: res.data })
      })
  } catch (err) {
    dispatch({
      type: PAYMENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    })
  }
}

export const getParticipantPaymentModules = () => async (dispatch) => {
  try {
    await axios
      .get('/api/events/payment/participant/modules', config)
      .then(async (res) => {
        dispatch({ type: GET_PARTICIPANT_PAYMENT_MODULES, payload: res.data })
      })
  } catch (err) {
    dispatch({
      type: PAYMENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    })
  }
}
