const paginate = (images, pageSize) => {
  const pages = Math.ceil(images.length / pageSize)

  const newImages = Array.from({ length: pages }, (_, index) => {
    const start = index * pageSize
    return images.slice(start, start + pageSize)
  })
  return newImages
}

export default paginate
