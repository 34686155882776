export const authJsonHeader = (getState) => {
  const {
    userLogin: { userInfo },
  } = getState()
  return {
    headers: {
      Authorization: `Bearer ${userInfo.token}`,
      'Content-Type': 'application/json',
    },
  }
}

export const authHeader = (getState) => {
  const {
    userLogin: { userInfo },
  } = getState()

  return {
    headers: {
      Authorization: `Bearer ${userInfo.token}`,
    },
  }
}

export const jsonHeader = () => {
  return { headers: { 'Content-Type': 'application/json' } }
}
