import {
  SET_MULTI_SELECT_INPUT_VALUE,
  SET_MULTI_SELECT_VALUE,
  MULTI_SELECT_ERROR,
  CLEAR_MULTI_SELECT_VALUE,
} from '../actions/multiSelectTypes'

const initialState = {
  multiSelectInputValue: '',
  multiSelectValue: [],
  multiSelectItems: [],
}

export default function (state = initialState, actions) {
  const { type, payload } = actions
  switch (type) {
    case SET_MULTI_SELECT_VALUE:
      return {
        ...state,
        loading: false,
        multiSelectValue: [payload, ...state.multiSelectValue],
        multiSelectItems: [payload.value, ...state.multiSelectItems],
      }
    case SET_MULTI_SELECT_INPUT_VALUE:
      return { ...state, loading: false, multiSelectInputValue: payload }
    case CLEAR_MULTI_SELECT_VALUE:
      return {
        ...state,
        loading: false,
        multiSelectInputValue: '',
        multiSelectValue: [],
        multiSelectItems: [],
      }
    case MULTI_SELECT_ERROR:
      return { ...state, loading: false, error: payload }
    default:
      return state
  }
}
