import {
  ADD_PARTICIPANT,
  PARTICIPANT_ERROR,
  SET_LOADING,
  SET_PARTICIPANTS,
  UPDATE_PARTICIPANT,
} from "../actions/participantTypes"

const initialState = {
  loading: false,
  participants: [],
}

export default function (state = initialState, actions) {
  const { type, payload } = actions

  switch (type) {
    case SET_PARTICIPANTS:
      return { ...state, loading: false, participants: payload }
    case ADD_PARTICIPANT:
      return {
        ...state,
        loading: false,
        participants: state.participants.concat(payload),
      }
    case UPDATE_PARTICIPANT:
      let result = state.participants.find(
        (participant) => participant.id !== payload.id
      )
      return {
        ...state,
        loading: false,
        participants: result.concat(payload),
      }
    case SET_LOADING:
      return { ...state, loading: payload }
    case PARTICIPANT_ERROR:
      return { ...state, error: payload, loading: false }
    default:
      return state
  }
}
