import {
  SET_ASSIGNED_EVENT_CONTRACTORS,
  SET_ASSIGNED_EVENT_PHOTOGRAPHERS,
  SET_CONTRACTORS,
  SET_PHOTOGRAPHERS,
  USER_ERROR,
} from '../actions/types'

const initialState = {
  assignedEventPhotographers: null,
  assignedEventContractors: null,
  photographers: null,
  contractors: null,
}

export default function (state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case USER_ERROR:
      return { ...state, error: payload, loading: false }
    case SET_PHOTOGRAPHERS:
      return {
        ...state,
        photographers: payload,
        loading: false,
      }
    case SET_CONTRACTORS:
      return {
        ...state,
        contractors: payload,
        loading: false,
      }
    case SET_ASSIGNED_EVENT_PHOTOGRAPHERS:
      return {
        ...state,
        assignedEventPhotographers: payload,
        loading: false,
      }

    case SET_ASSIGNED_EVENT_CONTRACTORS:
      return {
        ...state,
        assignedEventContractors: payload,
        loading: false,
      }
    default:
      return state
  }
}
