import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Icon } from "semantic-ui-react"
import { Button, Header, Modal } from "semantic-ui-react"
import { Dropdown } from "semantic-ui-react"
import Typography from "@mui/material/Typography"
import { createBrandingCategory } from "../../actions/eventActions"

export const FilterConditions = ({ filter, options, columnId }) => {
  const { event, brandingCategories } = useSelector(
    (state) => state.eventReducer
  )
  const dispatch = useDispatch()
  const [open, setOpen] = React.useState(false)
  const [selectedItems, setSelectedItems] = useState([])
  const [multiSelectOptions, setMultiSelectOptions] = useState(null)

  useEffect(() => {
    if (options && brandingCategories) {
      let values = []
      options.map((option) => {
        values.push({ key: option, text: option, value: option })
      })
      setMultiSelectOptions(values)
      Object.entries(brandingCategories).map(([key, value], index) => {
        if (key === filter) {
          setSelectedItems(value)
        }
      })
    }
  }, [options, brandingCategories, setSelectedItems])

  const onChange = (event, data) => {
    setSelectedItems(data.value)
  }

  const onSave = () => {
    setOpen(false)
    dispatch(createBrandingCategory(event.id, filter, selectedItems))
  }

  return (
    <>
      <div style={{ display: "flex" }}>
        <div>
          <Typography
            variant="subtitle1"
            color="text.secondary"
            component="div"
          >
            {filter}
          </Typography>
        </div>
        {columnId === "to" ? (
          <>
            <div>&nbsp;</div>
            <div>
              {multiSelectOptions ? (
                <>
                  <Modal
                    onClose={() => setOpen(false)}
                    onOpen={() => setOpen(true)}
                    open={open}
                    trigger={<Icon name="edit" size="small" />}
                  >
                    <Modal.Header>
                      Select applicable filtering conditions
                    </Modal.Header>
                    <Modal.Content>
                      <Modal.Description>
                        <Header>Choose filtering options</Header>
                        {selectedItems.length > 0 ? (
                          <Dropdown
                            placeholder={filter}
                            fluid
                            multiple
                            search
                            selection
                            defaultValue={selectedItems}
                            options={multiSelectOptions}
                            onChange={onChange}
                          />
                        ) : (
                          <Dropdown
                            placeholder={filter}
                            fluid
                            multiple
                            search
                            selection
                            options={multiSelectOptions}
                            onChange={onChange}
                          />
                        )}
                      </Modal.Description>
                    </Modal.Content>
                    <Modal.Actions>
                      <Button color="black" onClick={() => setOpen(false)}>
                        Cancel
                      </Button>
                      <Button
                        content="Save"
                        labelPosition="right"
                        icon="checkmark"
                        onClick={() => onSave()}
                        positive
                      />
                    </Modal.Actions>
                  </Modal>
                </>
              ) : (
                <></>
              )}
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
      {columnId === "to" ? (
        <>
          <div
            style={{
              textAlign: "center",
            }}
          >
            <div
              style={{
                display: "inline-block",
              }}
            >
              {selectedItems.length > 0 ? (
                <Icon name="checkmark" color="green" size="small" />
              ) : (
                <Icon name="close" color="red" size="small" />
              )}
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  )
}
