import React, { Fragment } from 'react'
import { Card } from 'semantic-ui-react'
import ActivateUsersCard from './ActivateUserCard'
import AssignEventsCard from './AssignEventCard'
import AssignPhotographersCard from './AssignPhotographerCard'
import ProfilesCard from './ProfilesCard'

const UserManagementScreen = () => {
  return (
    <Fragment>
      <h1 className='large text-primary'>User Management</h1>
      <Fragment>
        <Card.Group>
          <ProfilesCard />
          <ActivateUsersCard />
          <AssignEventsCard />
          <AssignPhotographersCard />
        </Card.Group>
      </Fragment>
    </Fragment>
  )
}

export default UserManagementScreen
