import React, { Fragment, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import {
  Tab,
  Message,
  Card,
  CardGroup,
  Button,
  Checkbox,
  FormGroup,
} from "semantic-ui-react"
import Spinner from "../../shared/components/Spinner"
import UploadPreviewPlaceHolder from "../../shared/components/UploadPreviewPlaceHolder"
import { saveVerifiedImages, sendSearchImage } from "../actions/faceActions"
import { FormControlLabel, TextField } from "@mui/material"

function SearchFaces() {
  const { event } = useSelector((state) => state.eventReducer)
  const { fileToUpload } = useSelector((state) => state.uploadReducer)
  const { searchResults, noMathches, loading } = useSelector(
    (state) => state.faceReducer
  )
  const [formFields, setFormFields] = useState([])
  const [bibNumber, setBibNumber] = useState("")
  const [displayConverted, setDisplayConverted] = useState(false)
  const dispatch = useDispatch()

  const handleInputChange = (event) => {
    const values = [...formFields]
    const field = event.target.id
    const objIndex = values.findIndex((obj) => obj.id === field)

    if (objIndex === -1) {
      values.push({
        id: field,
      })
      setFormFields(values)
    } else {
      let updatedValues = values.filter((item) => item.id !== field)
      setFormFields(updatedValues)
    }
  }

  const handleSearch = (e) => {
    e.preventDefault()
    dispatch(sendSearchImage(fileToUpload, event, displayConverted, bibNumber))
  }

  // const onSubmit = (e) => {
  //   e.preventDefault()
  //   //console.log(bibNumber, formFields)
  //   dispatch(saveVerifiedImages(bibNumber, event.id, formFields))
  // }

  const handleDisplayConverted = (event) => {
    setDisplayConverted(event.target.checked)
  }

  return (
    <>
      <Fragment>
        <h2 className="medium text-primary">Search Images - {event.name}</h2>
        <Tab.Pane attached={false}>
          <Message>Upload image to search for matches</Message>
          <form className="upload-steps" onSubmit={handleSearch}>
            <div>
              <UploadPreviewPlaceHolder />
              <FormGroup>
                {event.is_dynamic_branding_enabled ? (
                  <FormControlLabel
                    control={
                      <TextField
                        id="outlined-basic"
                        label="Enter BIB number"
                        variant="outlined"
                        onChange={(e) => setBibNumber(e.target.value)}
                        value={bibNumber}
                        type="text"
                        name="bibNumber"
                      />
                    }
                    label="Enter BIB number to apply dynamic branding"
                  />
                ) : (
                  <></>
                )}

                <FormControlLabel
                  control={
                    <Checkbox
                      id="displayConverted"
                      name="displayConverted"
                      checked={displayConverted}
                      onChange={(e) => handleDisplayConverted(e)}
                    ></Checkbox>
                  }
                  label="Display Unbranded Image"
                />
              </FormGroup>
            </div>
          </form>
        </Tab.Pane>
        {loading ? (
          <Spinner />
        ) : (
          <Tab.Pane attached={false}>
            {searchResults && searchResults.length > 0 ? (
              searchResults.map((item, index) => (
                <form className="form" key={index}>
                  <Message>Search Results</Message>
                  <CardGroup key={index}>
                    {/* <Card key={'00'}>
                    <Card.Content>
                      <img src={item.sourceImage} />
                      <br></br>
                      <br></br>
                      <div
                        className='form-group'
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}></div>
                    </Card.Content>
                  </Card> */}
                    {item.matches.length > 0 ? (
                      item.matches.map((match) => (
                        <Card key={match.id}>
                          <Card.Content>
                            <img src={match.image} />
                            <br></br>
                            <br></br>
                            <div
                              className="form-group"
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              {/* <Checkbox
                              variant="primary"
                              id={match.id}
                              onChange={(event) => handleInputChange(event)}
                            ></Checkbox> */}
                            </div>
                          </Card.Content>
                        </Card>
                      ))
                    ) : (
                      <></>
                    )}
                  </CardGroup>
                  {/* {item.matches.length > 0 ? (
                  <div
                    className="form-group"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <input
                      type="text"
                      placeholder="BIB NUMBER"
                      name="BIB NUMBER"
                      onChange={(e) => setBibNumber(e.target.value)}
                    />
                    <Button variant="primary">Submit</Button>
                  </div>
                ) : (
                  <></>
                )} */}
                </form>
              ))
            ) : (
              <>{noMathches ? <>No Matches Found</> : <></>}</>
            )}
          </Tab.Pane>
        )}
      </Fragment>
    </>
  )
}
export default SearchFaces
