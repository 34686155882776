import React, { Fragment, useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import Spinner from "../../shared/components/Spinner"
import UploadFile from "../../shared/components/UploadFile"
import { getBrandingImages, sendBrandingImage } from "../actions/eventActions"

import "./GenericBrandingImages.css"

const GenericBrandingImages = ({ history }) => {
  const { event, loading, brandingImages } = useSelector(
    (state) => state.eventReducer
  )
  const [portraitImage, setPortraitImage] = useState(null)
  const [landscapeImage, setLandscapeImage] = useState(null)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getBrandingImages(event.id))
  }, [dispatch, event.id])

  useEffect(() => {
    if (brandingImages) {
      const valid_branding_images = brandingImages.filter(function (entry) {
        return entry.rule_name != null
      })

      const branding_images = valid_branding_images.filter(function (entry) {
        return entry.rule_name === "STATIC"
      })

      if (branding_images.length > 0) {
        setPortraitImage(branding_images[0]["portrait_image"])
        setLandscapeImage(branding_images[0]["landscape_image"])
      }
    }
  }, [brandingImages])

  const uploadImages = (e) => {
    e.preventDefault()
    let file
    let brandingType
    if (e.target[0].files.length > 0) {
      file = e.target[0].files[0]
      brandingType = "portrait"
    } else {
      file = e.target[2].files[0]
      brandingType = "landscape"
    }
    dispatch(
      sendBrandingImage(file, event.id, event.name, brandingType, "STATIC")
    )
  }

  return (
    <Fragment>
      {loading ? (
        <Spinner />
      ) : (
        <Fragment>
          <h1 className="large text-primary">Branding Images - {event.name}</h1>
          <div>
            Preferred width and height
            <p>
              <b>Portrait: </b>1800 Height * 1200 Width
            </p>
            <p>
              <b>Landscape: </b>1200 Height * 1800 Width
            </p>
          </div>
          <br></br>
          <div>
            <Fragment>
              <form className="upload-steps" onSubmit={uploadImages}>
                <div className="branding-container">
                  <div id="portrait" className="portrait">
                    <UploadFile
                      id={"portrait"}
                      header={"Upload Portrait Branding Image"}
                      cloudImage={portraitImage}
                    />
                  </div>
                  <div id="landscape" className="landscape">
                    <UploadFile
                      id={"landscape"}
                      header={"Upload Lanscape Branding Image"}
                      cloudImage={landscapeImage}
                    />
                  </div>
                </div>
              </form>
            </Fragment>
            <Fragment>
              <div></div>
            </Fragment>
          </div>
        </Fragment>
      )}
    </Fragment>
  )
}

export default GenericBrandingImages
