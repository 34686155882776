import React, { Fragment, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from "semantic-ui-react"
import { getTaggingStats } from "../../faces/actions/faceActions"
import Spinner from "../../shared/components/Spinner"

const Statistics = () => {
  const { event } = useSelector((state) => state.eventReducer)
  const {
    loading,
    photographerStats,
    contractorStats,
    totalStats,
    paymentHistory,
  } = useSelector((state) => state.faceReducer)
  const auth = useSelector((state) => state.auth)
  // const [isPhotographer, setIsPhotographer] = useState(false)
  const { role, user } = auth
  const dispatch = useDispatch()

  useEffect(() => {
    if (event && role) {
      if (role === "ADMIN" || role == "EVENT_COORDINATOR") {
        dispatch(getTaggingStats(event.id, null, false))
      } else {
        if (role === "PHOTOGRAPHER") {
          dispatch(getTaggingStats(event.id, user.id, true))
        } else {
          dispatch(getTaggingStats(event.id, user.id, false))
        }
      }
    }
  }, [dispatch, event, role])

  return (
    <Fragment>
      <h1 className="large text-primary">Statistics - {event.name}</h1>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <h2 className="medium text-primary">Photographer Statistics</h2>
          <Fragment>
            <Table style={{ tableLayout: "fixed" }}>
              <TableHeader>
                <TableRow>
                  <TableHeaderCell
                    colspan="2"
                    style={{ border: "1px solid rgba(0, 0, 0, 0.9)" }}
                  ></TableHeaderCell>
                  <TableHeaderCell
                    colspan="10"
                    style={{ border: "1px solid rgba(0, 0, 0, 0.9)" }}
                  >
                    Images
                  </TableHeaderCell>
                  <TableHeaderCell
                    colspan="6"
                    style={{ border: "1px solid rgba(0, 0, 0, 0.9)" }}
                  >
                    Cloudfront Images
                  </TableHeaderCell>
                  <TableHeaderCell
                    colspan="3"
                    style={{ border: "1px solid rgba(0, 0, 0, 0.9)" }}
                  >
                    Downloads
                  </TableHeaderCell>
                </TableRow>
                <TableRow>
                  {/* <TableHeaderCell>Event</TableHeaderCell> */}
                  {/* <TableHeaderCell>User</TableHeaderCell> */}
                  <TableHeaderCell>Photographer</TableHeaderCell>
                  <TableHeaderCell></TableHeaderCell>
                  <TableHeaderCell>Total</TableHeaderCell>
                  <TableHeaderCell>Tagged</TableHeaderCell>
                  <TableHeaderCell>Untagged</TableHeaderCell>
                  <TableHeaderCell>Invalid</TableHeaderCell>
                  <TableHeaderCell>Verified</TableHeaderCell>
                  <TableHeaderCell>Original</TableHeaderCell>
                  <TableHeaderCell>Stripped</TableHeaderCell>
                  <TableHeaderCell>Tnail</TableHeaderCell>
                  <TableHeaderCell>Branded</TableHeaderCell>
                  <TableHeaderCell>Unbranded</TableHeaderCell>
                  <TableHeaderCell>Original</TableHeaderCell>
                  <TableHeaderCell>Stripped</TableHeaderCell>
                  <TableHeaderCell>Tnail</TableHeaderCell>
                  <TableHeaderCell>Branded</TableHeaderCell>
                  <TableHeaderCell>Unbranded</TableHeaderCell>
                  <TableHeaderCell>Missing</TableHeaderCell>
                  <TableHeaderCell>Total</TableHeaderCell>
                  <TableHeaderCell>Images</TableHeaderCell>
                  <TableHeaderCell>Value</TableHeaderCell>
                </TableRow>
              </TableHeader>
              <TableBody>
                {photographerStats ? (
                  photographerStats.length > 0 ? (
                    photographerStats.map((stat, index) => (
                      <TableRow key={index}>
                        {/* <TableCell>{event.name}</TableCell>
                        <TableCell>{user.name}</TableCell> */}
                        <TableCell>{stat["user"]}</TableCell>
                        <TableCell></TableCell>
                        <TableCell>{stat["total"]}</TableCell>
                        <TableCell>{stat["tagged"]}</TableCell>
                        <TableCell>{stat["untagged"]}</TableCell>
                        <TableCell>{stat["invalid"]}</TableCell>
                        <TableCell>{stat["verified"]}</TableCell>
                        <TableCell>{stat["originalImages"]}</TableCell>
                        <TableCell>{stat["strippedImages"]}</TableCell>
                        <TableCell>{stat["tnailImages"]}</TableCell>
                        <TableCell>{stat["brandedImages"]}</TableCell>
                        <TableCell>{stat["pendingImageBranding"]}</TableCell>
                        <TableCell>{stat["cdnOriginalImages"]}</TableCell>
                        <TableCell>{stat["cdnStrippedImages"]}</TableCell>
                        <TableCell>{stat["cdnTnailImages"]}</TableCell>
                        <TableCell>{stat["cdnBrandedImages"]}</TableCell>
                        <TableCell>{stat["cdnPendingImageBranding"]}</TableCell>
                        <TableCell>{stat["missingCloudUrls"]}</TableCell>
                        <TableCell>{stat["downloadCount"]}</TableCell>
                        <TableCell>{stat["imageDownloads"]}</TableCell>
                        <TableCell>{stat["imageValue"]}</TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell>
                        <>No Details found...</>
                      </TableCell>
                    </TableRow>
                  )
                ) : (
                  <></>
                )}
              </TableBody>
            </Table>
          </Fragment>
          <h2 className="medium text-primary">Contractor Statistics</h2>
          <Fragment>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHeaderCell
                    colspan="2"
                    style={{ border: "1px solid rgba(0, 0, 0, 0.9)" }}
                  ></TableHeaderCell>
                  <TableHeaderCell
                    colspan="10"
                    style={{ border: "1px solid rgba(0, 0, 0, 0.9)" }}
                  >
                    Images
                  </TableHeaderCell>
                  <TableHeaderCell
                    colspan="6"
                    style={{ border: "1px solid rgba(0, 0, 0, 0.9)" }}
                  >
                    Cloudfront Images
                  </TableHeaderCell>
                  <TableHeaderCell
                    colspan="3"
                    style={{ border: "1px solid rgba(0, 0, 0, 0.9)" }}
                  >
                    Downloads
                  </TableHeaderCell>
                </TableRow>
                <TableRow>
                  {/* <TableHeaderCell>Event</TableHeaderCell>
                  <TableHeaderCell>User</TableHeaderCell> */}
                  <TableHeaderCell>Photographer</TableHeaderCell>
                  <TableHeaderCell></TableHeaderCell>
                  <TableHeaderCell>Total</TableHeaderCell>
                  <TableHeaderCell>Tagged</TableHeaderCell>
                  <TableHeaderCell>Untagged</TableHeaderCell>
                  <TableHeaderCell>Invalid</TableHeaderCell>
                  <TableHeaderCell>Verified</TableHeaderCell>
                  <TableHeaderCell>Original</TableHeaderCell>
                  <TableHeaderCell>Stripped</TableHeaderCell>
                  <TableHeaderCell>Tnail</TableHeaderCell>
                  <TableHeaderCell>Branded</TableHeaderCell>
                  <TableHeaderCell>Unbranded</TableHeaderCell>
                  <TableHeaderCell>Original</TableHeaderCell>
                  <TableHeaderCell>Stripped</TableHeaderCell>
                  <TableHeaderCell>Tnail</TableHeaderCell>
                  <TableHeaderCell>Branded</TableHeaderCell>
                  <TableHeaderCell>Unbranded</TableHeaderCell>
                  <TableHeaderCell>Missing</TableHeaderCell>
                  <TableHeaderCell>Total</TableHeaderCell>
                  <TableHeaderCell>Images</TableHeaderCell>
                  <TableHeaderCell>Value</TableHeaderCell>
                </TableRow>
              </TableHeader>
              <TableBody>
                {contractorStats ? (
                  contractorStats.length > 0 ? (
                    contractorStats.map((stat, index) => (
                      <TableRow key={index}>
                        {/* <TableCell>{event.name}</TableCell>
                        <TableCell>{user.name}</TableCell> */}
                        <TableCell>{stat["user"]}</TableCell>
                        <TableCell></TableCell>
                        <TableCell>{stat["total"]}</TableCell>
                        <TableCell>{stat["tagged"]}</TableCell>
                        <TableCell>{stat["untagged"]}</TableCell>
                        <TableCell>{stat["invalid"]}</TableCell>
                        <TableCell>{stat["verified"]}</TableCell>
                        <TableCell>{stat["originalImages"]}</TableCell>
                        <TableCell>{stat["strippedImages"]}</TableCell>
                        <TableCell>{stat["tnailImages"]}</TableCell>
                        <TableCell>{stat["brandedImages"]}</TableCell>
                        <TableCell>{stat["pendingImageBranding"]}</TableCell>
                        <TableCell>{stat["cdnOriginalImages"]}</TableCell>
                        <TableCell>{stat["cdnStrippedImages"]}</TableCell>
                        <TableCell>{stat["cdnTnailImages"]}</TableCell>
                        <TableCell>{stat["cdnBrandedImages"]}</TableCell>
                        <TableCell>{stat["cdnPendingImageBranding"]}</TableCell>
                        <TableCell>{stat["missingCloudUrls"]}</TableCell>
                        <TableCell>{stat["downloadCount"]}</TableCell>
                        <TableCell>{stat["imageDownloads"]}</TableCell>
                        <TableCell>{stat["imageValue"]}</TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell>
                        <>No Details found...</>
                      </TableCell>
                    </TableRow>
                  )
                ) : (
                  <></>
                )}
              </TableBody>
            </Table>
          </Fragment>
          <h2 className="medium text-primary">Total Statistics</h2>
          <Fragment>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHeaderCell
                    colspan="2"
                    style={{ border: "1px solid rgba(0, 0, 0, 0.9)" }}
                  ></TableHeaderCell>
                  <TableHeaderCell
                    colspan="10"
                    style={{ border: "1px solid rgba(0, 0, 0, 0.9)" }}
                  >
                    Images
                  </TableHeaderCell>
                  <TableHeaderCell
                    colspan="6"
                    style={{ border: "1px solid rgba(0, 0, 0, 0.9)" }}
                  >
                    Cloudfront Images
                  </TableHeaderCell>
                  <TableHeaderCell
                    colspan="3"
                    style={{ border: "1px solid rgba(0, 0, 0, 0.9)" }}
                  >
                    Downloads
                  </TableHeaderCell>
                </TableRow>
                <TableRow>
                  {/* <TableHeaderCell>Event</TableHeaderCell>
                  <TableHeaderCell>User</TableHeaderCell> */}
                  <TableHeaderCell>Photographer</TableHeaderCell>
                  <TableHeaderCell></TableHeaderCell>
                  <TableHeaderCell>Total</TableHeaderCell>
                  <TableHeaderCell>Tagged</TableHeaderCell>
                  <TableHeaderCell>Untagged</TableHeaderCell>
                  <TableHeaderCell>Invalid</TableHeaderCell>
                  <TableHeaderCell>Verified</TableHeaderCell>
                  <TableHeaderCell>Original</TableHeaderCell>
                  <TableHeaderCell>Stripped</TableHeaderCell>
                  <TableHeaderCell>Tnail</TableHeaderCell>
                  <TableHeaderCell>Branded</TableHeaderCell>
                  <TableHeaderCell>Unbranded</TableHeaderCell>
                  <TableHeaderCell>Original</TableHeaderCell>
                  <TableHeaderCell>Stripped</TableHeaderCell>
                  <TableHeaderCell>Tnail</TableHeaderCell>
                  <TableHeaderCell>Branded</TableHeaderCell>
                  <TableHeaderCell>Unbranded</TableHeaderCell>
                  <TableHeaderCell>Missing</TableHeaderCell>
                  <TableHeaderCell>Total</TableHeaderCell>
                  <TableHeaderCell>Images</TableHeaderCell>
                  <TableHeaderCell>Value</TableHeaderCell>
                </TableRow>
              </TableHeader>
              <TableBody>
                {totalStats ? (
                  totalStats.length > 0 ? (
                    totalStats.map((stat, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <b>TOTAL</b>
                        </TableCell>
                        {/* <TableCell></TableCell>
                        <TableCell></TableCell> */}
                        <TableCell></TableCell>
                        <TableCell>{stat["total"]}</TableCell>
                        <TableCell>{stat["tagged"]}</TableCell>
                        <TableCell>{stat["untagged"]}</TableCell>
                        <TableCell>{stat["invalid"]}</TableCell>
                        <TableCell>{stat["verified"]}</TableCell>
                        <TableCell>{stat["originalImages"]}</TableCell>
                        <TableCell>{stat["strippedImages"]}</TableCell>
                        <TableCell>{stat["tnailImages"]}</TableCell>
                        <TableCell>{stat["brandedImages"]}</TableCell>
                        <TableCell>{stat["pendingImageBranding"]}</TableCell>
                        <TableCell>{stat["cdnOriginalImages"]}</TableCell>
                        <TableCell>{stat["cdnStrippedImages"]}</TableCell>
                        <TableCell>{stat["cdnTnailImages"]}</TableCell>
                        <TableCell>{stat["cdnBrandedImages"]}</TableCell>
                        <TableCell>{stat["cdnPendingImageBranding"]}</TableCell>
                        <TableCell>{stat["missingCloudUrls"]}</TableCell>
                        <TableCell>{stat["downloadCount"]}</TableCell>
                        <TableCell>{stat["imageDownloads"]}</TableCell>
                        <TableCell>{stat["imageValue"]}</TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell>
                        <>No Details found...</>
                      </TableCell>
                    </TableRow>
                  )
                ) : (
                  <></>
                )}
              </TableBody>
            </Table>
          </Fragment>
          <h2 className="medium text-primary">Payment History</h2>
          <Fragment>
            <Table>
              {paymentHistory ? (
                paymentHistory.length > 0 ? (
                  <>
                    <TableHeader>
                      <TableRow>
                        <TableHeaderCell>Payment Slab</TableHeaderCell>
                        <TableHeaderCell>Images</TableHeaderCell>
                        <TableHeaderCell>Unit Price</TableHeaderCell>
                        <TableHeaderCell>Total Price</TableHeaderCell>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {paymentHistory.map((payment, index) => (
                        <>
                          {Object.keys(payment).map((history, i) => (
                            <TableRow key={index + i}>
                              {history === "TOTAL" ? (
                                <>
                                  <TableCell>
                                    <b>{payment[history]["slab"]}</b>
                                  </TableCell>
                                  <TableCell>
                                    <b>{payment[history]["count"]}</b>
                                  </TableCell>
                                  <TableCell>
                                    <b>{payment[history]["unitPrice"]}</b>
                                  </TableCell>
                                  <TableCell>
                                    <b>{payment[history]["price"]}</b>
                                  </TableCell>
                                </>
                              ) : (
                                <>
                                  <TableCell>
                                    {payment[history]["slab"]}
                                  </TableCell>
                                  <TableCell>
                                    {payment[history]["count"]}
                                  </TableCell>
                                  <TableCell>
                                    {payment[history]["unitPrice"]}
                                  </TableCell>
                                  <TableCell>
                                    {payment[history]["price"]}
                                  </TableCell>
                                </>
                              )}
                            </TableRow>
                          ))}
                        </>
                      ))}
                    </TableBody>
                  </>
                ) : (
                  <></>
                )
              ) : (
                <></>
              )}
            </Table>
          </Fragment>
        </>
      )}
    </Fragment>
  )
}

export default Statistics
