import React from 'react'
import { Button, Label, Segment, Header, Icon } from 'semantic-ui-react'

function UploadPlaceHolder() {
  return (
    <Segment placeholder>
      <Header icon>
        <Icon name='file image outline' />
        <Label>
          Select the Root Folder to upload&nbsp;&nbsp;&nbsp;&nbsp;
          <input id='files' type='file' directory='' webkitdirectory='' />
        </Label>
      </Header>
      <Button primary>Upload</Button>
    </Segment>
  )
}
export default UploadPlaceHolder
