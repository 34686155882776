import React, { Fragment } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Link } from "react-router-dom"
import Spinner from "../../shared/components/Spinner"
import UploadCsv from "../../shared/components/UploadCsv"
import { setAlert } from "../../shared/actions/alert"
import { createParticipants } from "../actions/participantActions"
import { SET_LOADING } from "../actions/participantTypes"
import { isEmpty } from "../../../utils/objectUtils"
import * as XLSX from "xlsx"

const SeedParticipants = () => {
  const { event } = useSelector((state) => state.eventReducer)
  const { loading } = useSelector((state) => state.participantReducer)
  const dispatch = useDispatch()
  const mandatoryFields = ["Name", "Bib", "Gender"]
  //const optionalFields = ["Age", "AG", "Mobile No", "Email", "DOB"]

  const configs = {
    id: "seed_participants",
    header: "Upload participant information file in csv format",
  }

  const convertToJson = (csv) => {
    var lines = csv.split("\n")
    var result = []
    var headers = lines[0].split(",")

    for (var i = 1; i < lines.length; i++) {
      var obj = {}
      var currentline = lines[i].split(",")

      for (var j = 0; j < headers.length; j++) {
        obj[headers[j]] = currentline[j]
      }
      result.push(obj)
    }
    return result
  }

  const processFile = (e) => {
    e.preventDefault()
    var f = e.target[0].files[0]
    var reader = new FileReader()
    reader.onload = function (e) {
      var data = e.target.result

      let readedData = XLSX.read(data, { type: "binary" })
      const wsname = readedData.SheetNames[0]
      const ws = readedData.Sheets[wsname]

      /* Convert array to json*/
      const columns = XLSX.utils.sheet_to_json(ws, { header: 1 })[0]
      const excelData = XLSX.utils.sheet_to_csv(ws, { header: 1 })
      const parsedData = convertToJson(excelData)

      //console.log(parsedData)

      let errors = []
      mandatoryFields.map((field) => {
        if (!columns.includes(field)) {
          errors.push(field)
        }
      })
      if (errors.length > 0) {
        dispatch(
          setAlert(
            "Mandatory columns missing " + errors + " / File corrupted ",
            "danger"
          )
        )
        return
      }
      //const parsed_data = results.data
      var executions = new Promise((resolve) => {
        parsedData.map((data) => {
          let participant = { event_id: event.id }
          let performance = {}
          let laptime = {}
          Object.keys(data).forEach(function (key) {
            // if (
            //   mandatoryFields.includes(key.toLowerCase()) || //
            //   optionalFields.includes(key.toLowerCase())
            // ) {
            if (key.toUpperCase() === "GENDER") {
              participant["gender"] = data[key].toUpperCase()
            } else if (key.toUpperCase() === "NAME") {
              participant["name"] = data[key]
            } else if (key.toUpperCase() === "MOBILE NO") {
              participant["contact_number"] = data[key]
            } else if (key.toUpperCase() === "EMAIL") {
              participant["email_id"] = data[key]
            } else if (key.toUpperCase() === "AG") {
              participant["age_group"] = data[key]
                .replaceAll(/\s/g, "")
                .toUpperCase()
            } else if (key.toUpperCase() === "AGE") {
              participant["age"] = data[key]
            } else if (key.toUpperCase() === "DOB") {
              participant["date_of_birth"] = data[key]
            } else if (key.toUpperCase() === "BIB") {
              participant["bib_number"] = data[key]
            } else if (key.toUpperCase() === "GUNTIME") {
              performance["gun_time"] = data[key]
            } else if (key.toUpperCase() === "CHIPTIME") {
              performance["chip_time"] = data[key]
            } else if (key.toUpperCase() === "START") {
              performance["start_time"] = data[key]
            } else if (key.toUpperCase() === "FINISH") {
              performance["finish_time"] = data[key]
            } else if (key.toUpperCase() === "OVERALL RANK") {
              performance["over_all_rank"] = data[key]
            } else if (key.toUpperCase() === "GENDER RANK") {
              performance["gender_rank"] = data[key]
            } else {
              laptime[key] = data[key]
            }
          })
          if (!isEmpty(performance) && !isEmpty(laptime)) {
            performance["lap_timings"] = JSON.stringify(laptime)
          }
          resolve(dispatch(createParticipants(participant, performance)))
        })
      })
      executions.then(() => {
        dispatch(setAlert("Participants uploaded", "success"))
        dispatch({ type: SET_LOADING, payload: false })
      })
    }
    reader.readAsBinaryString(f)

    // Papa.parse(e.target[0].files[0], {
    //   header: true,
    //   skipEmptyLines: true,
    //   complete: function (results) {
    //     const rowsArray = []
    //     const valuesArray = []
    //     // Iterating data to get column name and their values
    //     results.data.map((d) => {
    //       rowsArray.push(Object.keys(d))
    //       valuesArray.push(Object.values(d))
    //     })
    //     const columns = rowsArray[0]
    //     let errors = []
    //     mandatoryFields.map((field) => {
    //       if (!columns.includes(field)) {
    //         errors.push(field)
    //       }
    //     })
    //     if (errors.length > 0) {
    //       dispatch(
    //         setAlert(
    //           "Mandatory columns missing " + errors + " / File corrupted ",
    //           "danger"
    //         )
    //       )
    //       return
    //     }
    //     const parsed_data = results.data
    //     var executions = new Promise((resolve) => {
    //       parsed_data.map((data) => {
    //         let participant = { event_id: event.id }
    //         let performance = {}
    //         let laptime = {}
    //         Object.keys(data).forEach(function (key) {
    //           // if (
    //           //   mandatoryFields.includes(key.toLowerCase()) || //
    //           //   optionalFields.includes(key.toLowerCase())
    //           // ) {
    //           if (key.toUpperCase() === "GENDER") {
    //             participant["gender"] = data[key].toUpperCase()
    //           } else if (key.toUpperCase() === "NAME") {
    //             participant["name"] = data[key]
    //           } else if (key.toUpperCase() === "MOBILE NO") {
    //             participant["contact_number"] = data[key]
    //           } else if (key.toUpperCase() === "EMAIL") {
    //             participant["email_id"] = data[key]
    //           } else if (key.toUpperCase() === "AG") {
    //             participant["age_group"] = data[key]
    //               .replaceAll(/\s/g, "")
    //               .toUpperCase()
    //           } else if (key.toUpperCase() === "AGE") {
    //             participant["age"] = data[key]
    //           } else if (key.toUpperCase() === "DOB") {
    //             participant["date_of_birth"] = data[key]
    //           } else if (key.toUpperCase() === "BIB") {
    //             participant["bib_number"] = data[key]
    //           } else if (key.toUpperCase() === "GUNTIME") {
    //             performance["gun_time"] = data[key]
    //           } else if (key.toUpperCase() === "CHIPTIME") {
    //             performance["chip_time"] = data[key]
    //           } else if (key.toUpperCase() === "START") {
    //             performance["start_time"] = data[key]
    //           } else if (key.toUpperCase() === "FINISH") {
    //             performance["finish_time"] = data[key]
    //           } else if (key.toUpperCase() === "OVERALL RANK") {
    //             performance["over_all_rank"] = data[key]
    //           } else if (key.toUpperCase() === "GENDER RANK") {
    //             performance["gender_rank"] = data[key]
    //           } else {
    //             laptime[key] = data[key]
    //           }
    //         })
    //         if (!isEmpty(performance) && !isEmpty(laptime)) {
    //           performance["lap_timings"] = JSON.stringify(laptime)
    //         }
    //         resolve(dispatch(createParticipants(participant, performance)))
    //       })
    //     })
    //     executions.then(() => {
    //       dispatch(setAlert("Participants uploaded", "success"))
    //       dispatch({ type: SET_LOADING, payload: false })
    //     })
    //   },
    // })
  }

  return (
    <Fragment>
      {loading ? (
        <Spinner />
      ) : (
        <Fragment>
          <h1 className="large text-primary">
            Seed Participant Information - {event.name}
          </h1>
          <br></br>
          <div>
            <Fragment>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div>Download bulk uploading / updating template:</div>
                <div style={{ width: "1220px" }}></div>
                <div>
                  <Link
                    to="/files/participant_data_format.xlsx"
                    target="_blank"
                    download
                  >
                    <i className="big cloud download icon"></i>
                  </Link>
                </div>
              </div>
            </Fragment>
            <Fragment>
              <form className="upload-steps" onSubmit={processFile}>
                <div className="seed-participant-container">
                  <div
                    id="participant-container"
                    className="participant-container"
                  >
                    <UploadCsv {...configs} />
                  </div>
                </div>
              </form>
            </Fragment>
          </div>
        </Fragment>
      )}
    </Fragment>
  )
}

export default SeedParticipants
