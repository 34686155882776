import React, { Fragment, useState, useEffect } from "react"
import { RNS3 } from "react-native-aws3"
import { useSelector, useDispatch } from "react-redux"
import { Tab, Message } from "semantic-ui-react"
import { setAlert } from "../../shared/actions/alert"
import Select from "react-select"
import { generateRandomBytes } from "../../../utils/generateRandom"
import UploadPlaceHolder from "../../shared/components/UploadPlaceHolder"
import UploadStatus from "./UploadStatus"
import {
  clearEventPhotoPoints,
  clearUploadedLocalImages,
  clearUserEventMappings,
  getEventPhotoPoints,
  getExecutionEnvironment,
  getImageUploadUserEventMappings,
  getUploadedLocalImages,
  incrementIgnoredUploadCount,
  incrementUploadCount,
  resetIgnoredUploadCount,
  resetUploadCount,
  insertImage,
  updateUploadedLocalImageStorage,
} from "../actions/eventActions"
import { transportLogs } from "../../../api/logger"
import { DASHBOARD_ROUTE } from "../../../routes/routers"
import DialogModel from "../../shared/components/Modal"
import { ModelContext } from "../../shared/context/modelContext"
import { ProgressBar } from "react-bootstrap"
import { round } from "../../../utils/intergerOperations"
import Spinner from "../../shared/components/Spinner"

function UploadImages({ history }) {
  const config = {
    bucket: process.env.REACT_APP_BUCKET_NAME,
    keyPrefix: "",
    region: process.env.REACT_APP_REGION,
    accessKey: process.env.REACT_APP_ACCESS_ID,
    secretKey: process.env.REACT_APP_ACCESS_KEY,
    successActionStatus: 201,
    method: "PUT/POST", // default is POST
  }
  const {
    event,
    userEventMap,
    uploadingCount,
    ignoredCount,
    uploadedLocalFiles,
    executionEnv,
    photoPoints,
  } = useSelector((state) => state.eventReducer)
  const [formData, setFormData] = useState({
    photographer: "",
    photoPoint: "",
  })
  const auth = useSelector((state) => state.auth)
  const { user, role } = auth
  const [centredModal, setCentredModal] = useState(false)

  const [photographerList, setPhotographerList] = useState([])
  const [photoPointList, setPhotoPointList] = useState([])
  const [isClearable, setIsClearable] = useState(true)
  const [isSearchable, setIsSearchable] = useState(true)
  const [isDisabled, setIsDisabled] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isRtl, setIsRtl] = useState(false)
  const [totalCount, setTotalCount] = useState(0)
  const { photographer, photoPoint } = formData
  //const [processedCount, setProcessedCount] = useState(0)
  let keyPrefixBase
  let envName
  const toggleShow = () => {
    setCentredModal(!centredModal)
  }
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(clearUploadedLocalImages())
    dispatch(clearEventPhotoPoints())
    dispatch(getExecutionEnvironment())
    setPhotographerList([])
    dispatch(clearUserEventMappings())
    dispatch(getEventPhotoPoints(event.id))
  }, [dispatch, setPhotographerList])

  useEffect(() => {
    if (event && user && role) {
      if (role == "ADMIN" || "EVENT_COORDINATOR") {
        dispatch(getImageUploadUserEventMappings(event.id, null))
      } else {
        dispatch(getImageUploadUserEventMappings(event.id, user.id))
      }
    }
  }, [dispatch, event, user, role])

  useEffect(() => {
    if (event && user) {
      dispatch(getUploadedLocalImages(event.id, user.id))
    }
  }, [dispatch, event, user])

  useEffect(() => {
    let val = []
    if (userEventMap) {
      userEventMap.map((userEvent) => {
        if (userEvent["user"]["role_id"] != 5) {
          val.push({
            value: userEvent["user"]["id"],
            label: userEvent["user"]["name"],
          })
        }
      })
      setPhotographerList(val)
    }
  }, [userEventMap])

  useEffect(() => {
    let val = []
    if (photoPoints) {
      photoPoints.map((photoPoint) => {
        val.push({
          value: photoPoint,
          label: photoPoint,
        })
      })
      setPhotoPointList(val)
    }
  }, [photoPoints])

  const onChange = (e) => {
    setFormData({ ...formData, photographer: e })
  }

  const onPhotoPointChange = (e) => {
    setFormData({ ...formData, photoPoint: e })
  }

  const uploadImages = (e) => {
    e.preventDefault()
    dispatch(resetUploadCount())
    dispatch(resetIgnoredUploadCount())
    if (event === "") {
      dispatch(
        setAlert("Event details missing, Redirecting to dashboard", "danger")
      )
      history.push(DASHBOARD_ROUTE)
      return
    }
    if (photographer === "") {
      dispatch(
        setAlert(
          "Please choose the photographer before uploading images",
          "danger"
        )
      )
      return
    }
    if (user) {
      keyPrefixBase =
        process.env.REACT_APP_DIR_NAME +
        event.name +
        "/" +
        user.name.trim() +
        "/" +
        photographer.label +
        "/"
    }
    if (executionEnv) {
      let env = executionEnv[0]
      if (env["env"] === "DEV") {
        envName = "D"
      } else if (env["env"] === "DEMO") {
        envName = "S"
      } else if (env["env"] === "PROD") {
        envName = "P"
      } else {
        envName = process.env.REACT_APP_EXECUTION_ENV
      }
    } else {
      envName = process.env.REACT_APP_EXECUTION_ENV
    }
    let fileArray = document.getElementById("files").files
    if (fileArray) {
      setTotalCount(fileArray.length)
    }
    dispatch(setAlert("Uploading Images", "primary"))
    uploadManager(fileArray)
  }

  async function uploadManager(fileArray) {
    var start = new Date()
    let parallelUploads = 10
    let uploadOffset = 0
    let isUploadFinished = false

    for (let i = 0; i < fileArray.length; i++) {
      var loopstart = new Date()
      let promiseBuffer = []
      for (let j = 0; j < parallelUploads; j++) {
        promiseBuffer.push(handleUpload(fileArray[uploadOffset]))
        uploadOffset++
        if (uploadOffset >= fileArray.length) {
          isUploadFinished = true
          break
        }
      }
      await Promise.all(promiseBuffer)
      //dispatch(transportLogs('Promise Broke....'))
      var time = new Date() - loopstart
      dispatch(transportLogs(`Batch finished - took ${time / 1000} seconds`))
      promiseBuffer.splice(0, promiseBuffer.length)
      if (isUploadFinished) {
        break
      }
    }
    var time = new Date() - start
    dispatch(setAlert("UPLOADING FINISHED!", "dark"))
    dispatch(transportLogs(`Uploading finished - took ${time / 1000} seconds`))
    setCentredModal(true)
  }

  const handleUpload = (file) => {
    return new Promise((resolve) => {
      try {
        /* If File naming convention is changed, corresponding changes should be updated on lambda. 
        Otherwise convertion, updaing db and all other process would get impacted.*/
        const isUploaded = uploadedLocalFiles.findIndex(
          (item) => item === file.webkitRelativePath
        )

        if (isUploaded !== -1) {
          //console.log('Already uploaded ' + file.webkitRelativePath)
          dispatch(incrementIgnoredUploadCount())
          resolve("Already uploaded")
        } else {
          const relativeFolder = file.webkitRelativePath.slice(
            0,
            file.webkitRelativePath.lastIndexOf("/") + 1
          )
          const image_size_mega_bytes = round(file.size / Math.pow(1024, 2))
          const image_id =
            event.id +
            "_" +
            generateRandomBytes(24) +
            "_" +
            image_size_mega_bytes
          config.keyPrefix =
            keyPrefixBase + relativeFolder + image_id + "_" + envName + "_"
          dispatch(transportLogs("Uploading... " + file.webkitRelativePath))
          //console.log('Uploading... ' + file.webkitRelativePath)

          RNS3.put(file, config).then((response) => {
            let data = {
              success: false,
              cloudFile: response.body.postResponse["key"],
              localFile: file.webkitRelativePath,
              event: event.id,
              user: user.id,
              photographer: photographer.value,
              imageId: image_id,
              photoPoint: photoPoint.value,
            }

            if (response.status !== 201) {
              dispatch(
                transportLogs("Uploading Failed " + file.webkitRelativePath)
              )
              dispatch(setAlert(file.webkitRelativePath + " ERROR!", "danger"))
            } else {
              dispatch(
                transportLogs("Uploading Success " + file.webkitRelativePath)
              )
              dispatch(
                updateUploadedLocalImageStorage(
                  uploadedLocalFiles,
                  file.webkitRelativePath
                )
              )
              dispatch(incrementUploadCount())
              data.success = true
            }
            resolve(dispatch(insertImage(data)))
          })
        }
      } catch (error) {
        if (error) {
          //console.log('Error uploading...')
          dispatch(
            setAlert(" ERROR Uploading Files; Please contact admin", "danger")
          )
        }
        resolve("ERROR")
      }
    })
  }

  return (
    <ModelContext.Provider value={[centredModal, setCentredModal, history]}>
      <Fragment>
        <h2 className="medium text-primary">Upload Images - {event.name}</h2>
        <Tab.Pane attached={false}>
          <Message>
            For new set of images always try to create a new folder. If
            uploading fails, you may try clicking the upload button again or
            refresh the page and select the same folder once again which will
            upload the pending images. Incase you reupload the same folder,
            please do not re-arrange existing file's path. Newly added images
            would be uploaded though. Photographer's name is mandatory to upload
            images
          </Message>
          <form className="upload-steps" onSubmit={uploadImages}>
            {uploadedLocalFiles &&
            userEventMap &&
            photographerList.length > 0 ? (
              <>
                <div>
                  Photographer's name *
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    isDisabled={isDisabled}
                    isLoading={isLoading}
                    isClearable={isClearable}
                    isRtl={isRtl}
                    isSearchable={isSearchable}
                    name="photographer"
                    id="photographer"
                    options={photographerList}
                    onChange={(event) => onChange(event)}
                  />
                </div>
                <br />
                {photoPoints && photoPoints.length > 0 ? (
                  <>
                    <div>
                      Photographer's Location *
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        isDisabled={isDisabled}
                        isLoading={isLoading}
                        isClearable={isClearable}
                        isRtl={isRtl}
                        isSearchable={isSearchable}
                        name="photoPoint"
                        id="photoPoint"
                        options={photoPointList}
                        onChange={(event) => onPhotoPointChange(event)}
                      />
                    </div>
                    <br />
                  </>
                ) : (
                  <></>
                )}

                <div>
                  <UploadPlaceHolder />
                </div>
              </>
            ) : (
              <Spinner />
            )}
          </form>
        </Tab.Pane>
        <Tab.Pane attached={false}>
          {totalCount > 0 && uploadingCount > 0 ? (
            <div>
              <h3> Uploading Progress: </h3>
              <ProgressBar
                variant="info"
                now={`${Math.round((uploadingCount / totalCount) * 100)}`}
                label={`${uploadingCount} / ${totalCount} `}
              />
            </div>
          ) : (
            <></>
          )}
          {totalCount > 0 && ignoredCount > 0 ? (
            <div>
              <h3> Ignored Previously Uploaded Images: </h3>
              <ProgressBar
                variant="info"
                now={`${Math.round((ignoredCount / totalCount) * 100)}`}
                label={`${ignoredCount} `}
              />
            </div>
          ) : (
            <></>
          )}
          <div>
            <UploadStatus />
          </div>
        </Tab.Pane>
      </Fragment>
      {setCentredModal ? <DialogModel /> : <></>}
    </ModelContext.Provider>
  )
}
export default UploadImages
