import {
  GET_IDENTIFIED_FACES,
  FACE_ERROR,
  SET_SEARCH_IMAGE_RESULTS,
  CLEAR_SEARCH_PAGE,
  GET_VERIFIED_FACES,
  CLEAR_IDENTIFIED_FACES,
  SET_HIDE_FORM_ID,
  SET_SEARCH_IMAGE,
  SET_SEARCH_IMAGE_NO_MATCHES,
  SET_ASSIGNED_PHOTOGRAPHERS,
  SET_ASSIGNED_PHOTOGRAPHS,
  CLEAR_ASSIGNED_PHOTOGRAPHERS,
  SET_TAGGING_FILTER,
  CLEAR_ASSIGNED_PHOTOGRAPHS,
  SET_BIB_RESULTS,
  CLEAR_BIB_RESULTS,
  SET_TAGGING_STATISTICS,
  SET_SEARCH_RESULTS,
  CLEAR_SEARCH_RESULTS,
  UPDATE_SERACH_RESULTS,
  SET_LOADING,
  SET_LOCATION_TIME_RESULTS,
} from "../actions/faceTypes"

const initialState = {
  loading: false,
  error: {},
  identifiedFaceList: null,
  searchResults: null,
  verifiedFaces: null,
  hideFormId: null,
  noMathches: false,
  assignedPhotographers: null,
  assignedPhotographs: null,
  taggingFilter: null,
  bibResults: null,
  photographerStats: null,
  contractorStats: null,
  totalStats: null,
  paymentHistory: null,
  faceSearchResults: null,
  locationTimeImageResults: null,
}

export default function (state = initialState, actions) {
  const { type, payload } = actions

  switch (type) {
    case SET_SEARCH_IMAGE:
      return { ...state, loading: true }
    case GET_IDENTIFIED_FACES:
      return { ...state, identifiedFaceList: payload, loading: false }
    case FACE_ERROR:
      return { ...state, error: payload, loading: false }
    case SET_SEARCH_IMAGE_RESULTS:
      return { ...state, searchResults: payload, loading: false }
    case SET_SEARCH_IMAGE_NO_MATCHES:
      return { ...state, noMathches: payload }
    case CLEAR_SEARCH_PAGE:
      return { ...state, searchResults: null, loading: false }
    case GET_VERIFIED_FACES:
      return { ...state, verifiedFaces: payload, loading: false }
    case CLEAR_IDENTIFIED_FACES:
      return { ...state, identifiedFaceList: null, loading: false }
    case SET_HIDE_FORM_ID:
      return { ...state, hideFormId: payload, loading: false }
    case SET_ASSIGNED_PHOTOGRAPHERS:
      return { ...state, assignedPhotographers: payload, loading: false }
    case SET_ASSIGNED_PHOTOGRAPHS:
      return { ...state, assignedPhotographs: payload, loading: false }
    case CLEAR_ASSIGNED_PHOTOGRAPHERS:
      return { ...state, assignedPhotographers: null, loading: false }
    case CLEAR_ASSIGNED_PHOTOGRAPHS:
      return { ...state, assignedPhotographs: null, loading: false }
    case SET_TAGGING_FILTER:
      return { ...state, taggingFilter: payload, loading: false }
    case SET_BIB_RESULTS:
      return { ...state, bibResults: payload, loading: false }
    case CLEAR_BIB_RESULTS:
      return { ...state, bibResults: null, loading: false }
    case SET_TAGGING_STATISTICS:
      return {
        ...state,
        photographerStats: payload["photographerStats"],
        contractorStats: payload["contractorStats"],
        paymentHistory: payload["history"],
        totalStats: payload["totalStats"],
        loading: false,
      }
    case SET_SEARCH_RESULTS:
      if (payload.length == 0) {
        return { ...state, faceSearchResults: null, loading: false }
      }
      return { ...state, faceSearchResults: payload, loading: false }
    case CLEAR_SEARCH_RESULTS:
      return { ...state, faceSearchResults: null, loading: false }
    case UPDATE_SERACH_RESULTS:
      if (payload in state.faceSearchResults) {
        delete state.faceSearchResults[payload]
      }
      return { ...state }
    case SET_LOCATION_TIME_RESULTS:
      return { ...state, locationTimeImageResults: payload, loading: false }
    case SET_LOADING:
      return { ...state, loading: true }
    default:
      return state
  }
}
