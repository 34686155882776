import React, { useEffect, Fragment, useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { useSelector, useDispatch } from 'react-redux'
import Select from 'react-select'
import { Segment, Tab } from 'semantic-ui-react'
import { getEvents } from '../../../events/actions/eventActions'
import Spinner from '../../../shared/components/Spinner'
import {
  assignUserToEvent,
  clearAssignedEventContractors,
  clearAssignedEventPhotographers,
  getAssignedEventContractors,
  getAssignedEventPhotographers,
  getEventContractors,
  getEventPhotographers,
  removeUserFromEvent,
} from '../../actions/user'

const PHOTOGRAPHER_LIST = 'Photographer List'
const EVENT_PHOTOGRAPHERS = 'Event Photographers'
const EVENT_CONTRACTORS = 'Event Contractors'
const CONTRACTOR_LIST = 'Contractor List'

function UserEventManagement() {
  const { events } = useSelector((state) => state.eventReducer)
  const {
    photographers,
    contractors,
    assignedEventPhotographers,
    assignedEventContractors,
    loading,
  } = useSelector((state) => state.userReducer)
  const [elements, setElements] = useState({
    'Photographer List': {},
    'Event Photographers': {},
    'Event Contractors': {},
    'Contractor List': {},
  })

  const [photographerList, setPhotographerList] = useState(null)
  const [contractorList, setContractorList] = useState(null)
  const [assignedPhotographerList, setAssignedPhotographerList] = useState(null)
  const [assignedContractorList, setAssignedContractorList] = useState(null)
  const [eventId, setEventId] = useState(null)
  const [eventDropdown, setEventDropdown] = useState([])
  const [columnHeight, setColumnHeight] = useState(1)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(clearAssignedEventContractors())
    dispatch(clearAssignedEventPhotographers())
    dispatch(getEvents())
  }, [dispatch])

  useEffect(() => {
    dispatch(getEventPhotographers())
    dispatch(getEventContractors())
  }, [dispatch])

  useEffect(() => {
    let photographerList = []
    if (photographers) {
      photographers.map((photographer) =>
        photographerList.push({
          id: `${photographer.id}`,
          //prefix: PHOTOGRAPHER_LIST,
          name: photographer.name,
          email: photographer.email,
          //content: `item ${photographer.name}`,
        })
      )
      elements[PHOTOGRAPHER_LIST] = {
        name: PHOTOGRAPHER_LIST,
        items: photographerList,
      }
      setElements(elements)
      setPhotographerList(photographerList)
      if (photographerList.length > columnHeight) {
        setColumnHeight(photographerList.length)
      }
    }
  }, [photographers])

  useEffect(() => {
    let contractorList = []
    if (contractors) {
      contractors.map((contractor) =>
        contractorList.push({
          id: `${contractor.id}`,
          //prefix: CONTRACTOR_LIST,
          name: contractor.name,
          email: contractor.email,
          //content: `item ${contractor.name}`,
        })
      )
      elements[CONTRACTOR_LIST] = {
        name: CONTRACTOR_LIST,
        items: contractorList,
      }
      setElements(elements)
      setContractorList(contractorList)
      if (contractorList.length > columnHeight) {
        setColumnHeight(contractorList.length)
      }
    }
  }, [contractors])

  useEffect(() => {
    let val = []
    if (events) {
      events.map((event) => {
        val.push({
          value: event['id'],
          label: event['name'],
        })
      })
      setEventDropdown(val)
    }
  }, [events])

  useEffect(() => {
    if (eventId) {
      dispatch(getAssignedEventPhotographers(eventId))
      dispatch(getAssignedEventContractors(eventId))
    }
  }, [dispatch, eventId])

  useEffect(() => {
    let photographerList = []
    if (assignedEventPhotographers) {
      assignedEventPhotographers.map((photographer) =>
        photographerList.push({
          id: `${photographer.user.id}`,
          //prefix: EVENT_PHOTOGRAPHERS,
          name: photographer.user.name,
          email: photographer.user.email,
          //content: `item ${photographer.user.name}`,
        })
      )
      elements[EVENT_PHOTOGRAPHERS] = {
        name: EVENT_PHOTOGRAPHERS,
        items: photographerList,
      }
      setElements(elements)
      setAssignedPhotographerList(photographerList)
      if (photographerList.length > columnHeight) {
        setColumnHeight(photographerList.length)
      }
    }
  }, [assignedEventPhotographers])

  useEffect(() => {
    let contractorList = []
    if (assignedEventContractors) {
      assignedEventContractors.map((contractor) =>
        contractorList.push({
          id: `${contractor.user.id}`,
          //prefix: EVENT_CONTRACTORS,
          name: contractor.user.name,
          email: contractor.user.email,
          //content: `item ${contractor.user.name}`,
        })
      )
      elements[EVENT_CONTRACTORS] = {
        name: EVENT_CONTRACTORS,
        items: contractorList,
      }
      setElements(elements)
      setAssignedContractorList(contractorList)
      if (contractorList.length > columnHeight) {
        setColumnHeight(contractorList.length)
      }
    }
  }, [assignedEventContractors])

  const onDragEnd = (result, columns, setColumns) => {
    if (!result.destination) {
      console.log('Drag and Drop elements to the right sections')
      return
    }
    const { source, destination } = result
    if (
      result.source.droppableId === PHOTOGRAPHER_LIST &&
      result.destination.droppableId !== EVENT_PHOTOGRAPHERS
    ) {
      console.log(
        PHOTOGRAPHER_LIST +
          ' items can only be dropped to ' +
          EVENT_PHOTOGRAPHERS
      )
      return
    }
    if (
      result.source.droppableId === CONTRACTOR_LIST &&
      result.destination.droppableId !== EVENT_CONTRACTORS
    ) {
      console.log(
        CONTRACTOR_LIST + ' items can only be dropped to ' + EVENT_CONTRACTORS
      )
      return
    }
    let removedElement
    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId]
      const destColumn = columns[destination.droppableId]
      const sourceItems = [...sourceColumn.items]
      const destItems = [...destColumn.items]
      const [removed] = sourceItems.splice(source.index, 1)
      destItems.splice(destination.index, 0, removed)
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          items: sourceItems,
        },
        [destination.droppableId]: {
          ...destColumn,
          items: destItems,
        },
      })
      removedElement = removed
    } else {
      const column = columns[source.droppableId]
      const copiedItems = [...column.items]
      const [removed] = copiedItems.splice(source.index, 1)
      copiedItems.splice(destination.index, 0, removed)
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...column,
          items: copiedItems,
        },
      })
      removedElement = removed
    }
    if (result.destination.droppableId === EVENT_CONTRACTORS) {
      dispatch(assignUserToEvent(eventId, removedElement.id))
      // console.log(
      //   'Assign Contractor to event ' +
      //     removedElement.id +
      //     ' ' +
      //     removedElement.name +
      //     ' ' +
      //     removedElement.email
      // )
    } else if (result.destination.droppableId === EVENT_PHOTOGRAPHERS) {
      dispatch(assignUserToEvent(eventId, removedElement.id))
      // console.log(
      //   'Assign Photographer to event' +
      //     removedElement.id +
      //     ' ' +
      //     removedElement.name +
      //     ' ' +
      //     removedElement.email
      // )
    } else if (result.destination.droppableId === PHOTOGRAPHER_LIST) {
      dispatch(removeUserFromEvent(eventId, removedElement.id))
      // console.log(
      //   'Remove Photographer from event' +
      //     removedElement.id +
      //     ' ' +
      //     removedElement.name +
      //     ' ' +
      //     removedElement.email
      // )
    } else if (result.destination.droppableId === CONTRACTOR_LIST) {
      dispatch(removeUserFromEvent(eventId, removedElement.id))
      // console.log(
      //   'Remove Contractor from event' +
      //     removedElement.id +
      //     ' ' +
      //     removedElement.name +
      //     ' ' +
      //     removedElement.email
      // )
    }
  }

  const onChange = (e) => {
    setEventId(e.value)
  }

  return (
    <div>
      <h1 className='large text-primary'>Assign Users to Events</h1>
      <Fragment>
        {eventDropdown.length === 0 ? (
          <Spinner />
        ) : (
          <div>
            Select Event
            <Select
              className='basic-single'
              classNamePrefix='select'
              isDisabled={false}
              isLoading={false}
              isClearable={true}
              isRtl={false}
              isSearchable={true}
              name='events'
              id='events'
              options={eventDropdown}
              onChange={(event) => onChange(event)}
            />
          </div>
        )}

        <br />
        <div
          style={{ display: 'flex', justifyContent: 'center', height: '100%' }}>
          {photographerList &&
          contractorList &&
          assignedPhotographerList &&
          assignedContractorList ? (
            <DragDropContext
              onDragEnd={(result) => onDragEnd(result, elements, setElements)}>
              {Object.entries(elements).map(([columnId, column], index) => {
                return (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                    key={columnId}>
                    <h2>{column.name}</h2>
                    <div style={{ margin: 8 }}>
                      <Droppable droppableId={columnId} key={columnId}>
                        {(provided, snapshot) => {
                          return (
                            <div
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                              style={{
                                background: snapshot.isDraggingOver
                                  ? 'lightblue'
                                  : 'lightgrey',
                                padding: 4,
                                width: 250,
                                minHeight: columnHeight * 85,
                              }}>
                              {column.items.map((item, index) => {
                                return (
                                  <Draggable
                                    key={item.id}
                                    draggableId={item.id}
                                    index={index}>
                                    {(provided, snapshot) => {
                                      return (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          style={{
                                            userSelect: 'none',
                                            padding: 16,
                                            margin: '0 0 8px 0',
                                            minHeight: '50px',
                                            backgroundColor: snapshot.isDragging
                                              ? '#263B4A'
                                              : '#456C86',
                                            color: 'white',
                                            ...provided.draggableProps.style,
                                          }}>
                                          <div>{item.name}</div>
                                          <div>{item.email}</div>
                                          {/* <div>{item.id}</div> */}
                                        </div>
                                      )
                                    }}
                                  </Draggable>
                                )
                              })}
                              {provided.placeholder}
                            </div>
                          )
                        }}
                      </Droppable>
                    </div>
                  </div>
                )
              })}
            </DragDropContext>
          ) : (
            <Tab.Pane>
              <h3> Configure Photographers and Contractors for events</h3>
              <br></br>
              <Segment>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
              </Segment>
            </Tab.Pane>
          )}
        </div>
      </Fragment>
    </div>
  )
}

export default UserEventManagement
