import React, { useState, Fragment, useEffect } from "react"
import { withRouter, Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { createEditEvent, setBIBFilterByEventId } from "../actions/eventActions"
import Select from "react-select"
import { TagsInput } from "react-tag-input-component"
import "./CreateEditEvent.module.css"

//import CloneEventFields from './CloneEventFields'

const CreateEditEvent = ({ history, match }) => {
  const { id } = match.params
  const isAddMode = !id
  const { eventTypes, events, bibFilter, loading } = useSelector(
    (state) => state.eventReducer
  )
  const { participantPaymentModules } = useSelector(
    (state) => state.paymentReducer
  )
  //const { multiSelectItems } = useSelector((state) => state.multiSelectReducer)
  const dispatch = useDispatch()
  const [bibId, setBibId] = useState(null)
  const [paymentDropdown, setPaymentDropdown] = useState([])
  //const [eventDropdown, setEventDropdown] = useState([])

  const [photoPoints, setPhotoPoints] = useState([])

  const [formData, setFormData] = useState({
    name: "",
    location: "",
    type: "",
    is_active: false,
    is_sales: false,
    from_date: "",
    to_date: "",
    organizer: "",
    sponsors: "",
    fr1: "",
    fr2: "",
    fr3: "",
    payment: "",
    is_sales_combined: false,
    is_date_filter_enabled: false,
    is_dynamic_branding_enabled: false,
    face_recognition_model: "DEEPFACE",
  })

  const [bibData, setBibData] = useState({
    bib_start_range: null,
    bib_end_range: null,
    bib_length: null,
    bib_prefix: null,
    bib_suffix: null,
    is_bib_present: false,
    filter_numbers: false,
  })

  // const [stepData, setStepData] = useState([
  //   [
  //     {
  //       label: 'Select Event',
  //       field: 'EventCount',
  //       value: '',
  //       name: '00',
  //     },
  //   ],
  // ])

  const {
    name,
    location,
    type,
    is_active,
    is_sales,
    from_date,
    to_date,
    organizer,
    sponsors,
    fr1,
    fr2,
    fr3,
    payment,
    is_sales_combined,
    is_date_filter_enabled,
    is_dynamic_branding_enabled,
  } = formData

  const {
    bib_start_range,
    bib_end_range,
    bib_length,
    bib_prefix,
    bib_suffix,
    is_bib_present,
    filter_numbers,
  } = bibData

  useEffect(() => {
    if (!isAddMode) {
      setBibId(null)
      let event = events.find((item) => item.id == id)
      const confidence = JSON.parse(event["face_recognition"])
      event["fr1"] = JSON.stringify(confidence[0])
      event["fr2"] = JSON.stringify(confidence[1])
      event["fr3"] = JSON.stringify(confidence[2])
      let photo_points = []
      event["photo_points"].split(",").map((point) => {
        if (point.length > 0) {
          photo_points.push(point)
        }
      })
      setPhotoPoints(photo_points)
      if (event["payment"] === null) {
        event["payment"] = ""
      } else {
        if (participantPaymentModules) {
          participantPaymentModules.map((paymentModule) => {
            if (
              event["payment"] === paymentModule["id"] ||
              event["payment_id"] === paymentModule["id"]
            ) {
              event["payment"] = {
                value: paymentModule["id"],
                label: paymentModule["name"],
              }
              delete event["payment_id"]
            }
          })
        }
      }
      setFormData({ ...event })
      dispatch(setBIBFilterByEventId(id))
    }
  }, [])

  useEffect(() => {
    let payModules = []
    if (participantPaymentModules) {
      participantPaymentModules.map((paymentModule) => {
        payModules.push({
          value: paymentModule["id"],
          label: paymentModule["name"],
        })
      })
      setPaymentDropdown(payModules)
    }
  }, [])

  useEffect(() => {
    if (bibFilter && !isAddMode) {
      setBibData({ ...bibFilter })
      if (bibFilter["id"] != undefined) {
        setBibId(bibFilter["id"])
      } else {
        setBibId(null)
      }
    }
  }, [bibFilter, setBibId])

  // useEffect(() => {
  //   let val = []
  //   if (events) {
  //     events.map((event) => {
  //       val.push({
  //         value: event['id'],
  //         label: event['name'],
  //       })
  //     })
  //     setEventDropdown(val)
  //   }
  // }, [events])

  // const addStepRow = () => {
  //   setStepData([
  //     ...stepData,
  //     [
  //       {
  //         label: 'Select Event',
  //         field: 'EventCount',
  //         value: '',
  //         name: `${stepData.length}0`,
  //       },
  //     ],
  //   ])
  // }

  // const removeStepRow = (index) => {
  //   const _data = [...stepData]
  //   _data.splice(index, 1)
  //   setStepData(_data)
  // }

  // const handleStepOnChange = (e, row, col) => {
  //   const newData = stepData.map((d, i) => {
  //     if (i === row) {
  //       if (e === null) {
  //         d[col].value = ''
  //         d[col].label = ''
  //       } else {
  //         d[col].value = e.value
  //         d[col].label = e.label
  //       }
  //     }
  //     return d
  //   })
  //   setStepData(newData)
  // }

  const onChange = (e) => {
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value
    setFormData({ ...formData, [e.target.name]: value })
  }

  const onBibChange = (e) => {
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value
    setBibData({ ...bibData, [e.target.name]: value })
  }

  const onSelectChange = (e) => {
    setFormData({ ...formData, ["payment"]: e.value })
  }

  // const getAssociatedEvents = () => {
  //   let event_ids = []
  //   stepData.map((data) => {
  //     if (Number.isInteger(data[0]['value'])) {
  //       event_ids.push(data[0].value)
  //     }
  //   })
  //   return event_ids
  // }

  const onSubmit = (e) => {
    e.preventDefault()
    formData["photo_points"] = photoPoints.toString()
    delete formData.es_index
    dispatch(createEditEvent(formData, bibData, history, id, bibId))
    //dispatch(setEventToEventReference(id, getAssociatedEvents()))
  }

  return (
    <Fragment>
      <h1 className="large text-primary">
        {isAddMode ? "Create Event" : "Edit Event"}
      </h1>
      <p className="lead">
        <i className="fas fa-user"></i>
        {isAddMode
          ? "Fill the form to create an event"
          : "Modify the event with relevent data"}
      </p>
      <p>
        Events created once cannot be deleted; however, it can be de-activated
        or modified. Events with the same name and locations are permitted as
        far as the start date and end dates are different.
      </p>
      <small>* Mandatory fields </small>
      <br></br>
      <br></br>
      <form className="form" onSubmit={onSubmit}>
        <fieldset>
          <legend>
            <h2>Event Details</h2>
          </legend>
          <div className="form-group">
            <select name="type" value={type} onChange={onChange}>
              <option value="0">Select Event Type</option>
              {eventTypes ? (
                eventTypes.map((eventType, i) => (
                  <option value={eventType["value"]} key={i}>
                    {eventType["label"]}
                  </option>
                ))
              ) : (
                <></>
              )}
            </select>
            <small className="form-text">* Choose the type of event</small>
          </div>

          <div className="form-group">
            <input
              type="text"
              placeholder="Event Name"
              name="name"
              value={name}
              onChange={onChange}
            />
            <small className="form-text">* Name of the event</small>
          </div>

          <div className="form-group">
            <input
              type="text"
              placeholder="Location"
              name="location"
              value={location}
              onChange={onChange}
            />
            <small className="form-text">
              * City / Place at which event happens
            </small>
          </div>

          <div className="form-group">
            <div className="photo-point-slider">
              <div className="bib-block">
                <TagsInput
                  name="photoPoints"
                  value={photoPoints}
                  onChange={setPhotoPoints}
                  placeholder="Photography Points"
                  className="slide active"
                />
                <small className="form-text">
                  * Photography points - Locations at which photographers are
                  deployed; Type the photgrapher locations and press enter.
                </small>
              </div>
            </div>
          </div>

          <div className="form-group">
            <input
              type="text"
              placeholder="Organizer"
              name="organizer"
              value={organizer}
              onChange={onChange}
            />
            <small className="form-text">Name of the Organizer</small>
          </div>

          <div className="form-group">
            <input
              type="text"
              placeholder="Sponsors"
              name="sponsors"
              value={sponsors}
              onChange={onChange}
            />
            <small className="form-text">
              Name of the Sponsers (Comma Seperated Values)
            </small>
          </div>

          <div className="form-group">
            <input
              id="eventStatus"
              type="checkbox"
              checked={is_active}
              placeholder="* Event status"
              name="is_active"
              onChange={onChange}
            />
            <label htmlFor="eventStatus"> Is the event active?</label>
            <small className="form-text">
              Checkbox must be checked for activating the event
            </small>
          </div>
          <em></em>
          <div className="form-group">
            <input
              id="salesStatus"
              type="checkbox"
              checked={is_sales}
              placeholder="* Sales status"
              name="is_sales"
              onChange={onChange}
            />
            <label htmlFor="salesStatus"> Is it a sales event ?</label>
            <small className="form-text">
              Checkbox must be checked for all sales events
            </small>
          </div>

          <div className="form-group">
            <input
              id="salesCombinedStatus"
              type="checkbox"
              checked={is_sales_combined}
              placeholder="* Combined Sales status"
              name="is_sales_combined"
              onChange={onChange}
            />
            <label htmlFor="salesCombinedStatus">
              {" "}
              Is it a sales combined pre-paid event?
            </label>
            <small className="form-text">
              Checkbox must be checked for sales combined with pre-paid events
            </small>
          </div>

          <div className="form-group">
            <input
              id="isDynamicBrandingEnabled"
              type="checkbox"
              checked={is_dynamic_branding_enabled}
              placeholder="* Combined Sales status"
              name="is_dynamic_branding_enabled"
              onChange={onChange}
            />
            <label htmlFor="isDynamicBrandingEnabled">
              {" "}
              Is dyanamic branding present for the event?
            </label>
            <small className="form-text">
              Checkbox must be checked to enable categorized dynamic branding
              options
            </small>
          </div>

          <div className="form-group">
            <input
              type="date"
              placeholder="* Start date"
              name="from_date"
              value={from_date}
              onChange={onChange}
            />
            <small className="form-text">* Start Date</small>
          </div>

          <div className="form-group">
            <input
              type="date"
              placeholder="* End date"
              name="to_date"
              value={to_date}
              onChange={onChange}
            />
            <small className="form-text">* End Date</small>
          </div>
        </fieldset>
        <br></br>
        <br></br>

        {is_sales || is_sales_combined ? (
          <>
            <fieldset>
              <legend>
                <h2>Choose Participant Payment Module</h2>
              </legend>
              <div className="form-group">
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  isDisabled={false}
                  isLoading={false}
                  isClearable={true}
                  isRtl={false}
                  isSearchable={true}
                  name="payment"
                  id="payment"
                  options={paymentDropdown}
                  onChange={(event) => onSelectChange(event)}
                  defaultValue={payment}
                />
                <small className="form-text">
                  * Choose Participant Payment Module
                </small>
              </div>
            </fieldset>
            <br></br>
            <br></br>
          </>
        ) : (
          <></>
        )}

        <fieldset>
          <legend>
            <h2>Face recognition tolerance configuration</h2>
          </legend>
          <div className="form-group">
            <select name="fr1" value={fr1} onChange={onChange} required>
              <option value="0">
                Select face recognition primary tolerance
              </option>
              <option value="0.6">0.6</option>
              <option value="0.65">0.65</option>
              <option value="0.68">0.68</option>
              <option value="0.685">0.685</option>
              <option value="0.7">0.7</option>
              <option value="0.75">0.75</option>
            </select>
            <small className="form-text">
              * Choose face recognition primary tolerance - [Default 0.685]
            </small>
          </div>

          <div className="form-group">
            <select name="fr2" value={fr2} onChange={onChange} required>
              <option value="0">
                Select face recognition secondary tolerance
              </option>
              <option value="0.6">0.6</option>
              <option value="0.65">0.65</option>
              <option value="0.68">0.68</option>
              <option value="0.685">0.685</option>
              <option value="0.7">0.7</option>
              <option value="0.75">0.75</option>
            </select>
            <small className="form-text">
              * Choose face recognition secondary tolerance - [Default 0.685]
            </small>
          </div>

          <div className="form-group">
            <select name="fr3" value={fr3} onChange={onChange} required>
              <option value="0">
                Select face recognition tertiary tolerance
              </option>
              <option value="0.6">0.6</option>
              <option value="0.65">0.65</option>
              <option value="0.68">0.68</option>
              <option value="0.685">0.685</option>
              <option value="0.7">0.7</option>
              <option value="0.75">0.75</option>
            </select>
            <small className="form-text">
              * Choose face recognition tertiary tolerance - [Default 0.685]
            </small>
          </div>
        </fieldset>
        <br></br>
        <br></br>
        {/* <fieldset>
          <legend>
            <h2>Clone Existing Images</h2>
          </legend>
          <em> Optional fields to copy images from existing events </em>
          <CloneEventFields
            data={stepData}
            addRow={addStepRow}
            removeRow={removeStepRow}
            onChange={handleStepOnChange}
            dropdownValues={eventDropdown}
            id={id}
          />
        </fieldset>
        <br></br>
        <br></br> */}
        <fieldset>
          <legend>
            <h2>BIB Filtering</h2>
          </legend>
          <div className="form-group">
            <input
              id="bibStatus"
              type="checkbox"
              checked={is_bib_present}
              placeholder="* Is BIB number present for participants"
              name="is_bib_present"
              onChange={onBibChange}
            />
            <label htmlFor="bibStatus"> Is BIB number present ?</label>
            <small className="form-text">
              Is BIB number present for participants. BIB numbers will be
              extracted only if the checkbox is checked. BIB numbers will be
              filtered based on the values provided. All fields are optional.
            </small>
          </div>

          <div className="form-group">
            <input
              id="filter_numbers"
              type="checkbox"
              checked={filter_numbers}
              placeholder=" Should all the numbers present in the image filtered irrespective of Prefixes and Suffixes?"
              name="filter_numbers"
              onChange={onBibChange}
            />
            <label htmlFor="filter_numbers">
              {" "}
              Filter all text with digits ?
            </label>
            <small className="form-text">
              Should all the numbers present in the image filtered irrespective
              of Prefixes and Suffixes? Bib start range and end range should not
              be configured if selected true.
            </small>
          </div>

          <div className="form-group">
            <input
              type="text"
              placeholder="BIB Start Range"
              name="bib_start_range"
              value={bib_start_range}
              onChange={onBibChange}
            />
            <small className="form-text">BIB number start range</small>
          </div>

          <div className="form-group">
            <input
              type="text"
              placeholder="BIB End Range"
              name="bib_end_range"
              value={bib_end_range}
              onChange={onBibChange}
            />
            <small className="form-text">BIB number end range</small>
          </div>

          <div className="form-group">
            <input
              type="text"
              placeholder="BIB Length"
              name="bib_length"
              value={bib_length}
              onChange={onBibChange}
            />
            <small className="form-text">BIB number length</small>
          </div>

          <div className="form-group">
            <input
              type="text"
              placeholder="BIB Number Prefix"
              name="bib_prefix"
              value={bib_prefix}
              onChange={onBibChange}
            />
            <small className="form-text">
              BIB number prefix characters. Multiple values can be comma
              seperated
            </small>
          </div>

          <div className="form-group">
            <input
              type="text"
              placeholder="BIB Number Suffix"
              name="bib_suffix"
              value={bib_suffix}
              onChange={onBibChange}
            />
            <small className="form-text">
              BIB number suffix characters. Multiple values can be comma
              seperated
            </small>
          </div>
        </fieldset>
        <br></br>
        <br></br>
        <fieldset>
          <legend>
            <h2>Date Filtering </h2>
          </legend>
          <div className="form-group">
            <input
              id="dateFilter"
              type="checkbox"
              checked={is_date_filter_enabled}
              placeholder="* Is date filter enabled for participants?"
              name="is_date_filter_enabled"
              onChange={onChange}
            />
            <label htmlFor="dateFilter">
              {" "}
              Is date filter enabled for participants?
            </label>
            <small className="form-text">
              If enabled, participants can filter images based on the date on
              which it is captured.
            </small>
          </div>
        </fieldset>
        <input type="submit" className="btn btn-primary my-1" />
        <Link to="/events" className="btn btn-light">
          Go Back
        </Link>
      </form>
    </Fragment>
  )
}

export default withRouter(CreateEditEvent)
