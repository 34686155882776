import React from "react"
import { Card } from "semantic-ui-react"
import { LazyLoadImage } from "react-lazy-load-image-component"

const ImageCard = (item) => {
  const { id, image } = item
  return (
    <Card id={id}>
      <Card.Content>
        <LazyLoadImage src={image} />
      </Card.Content>
    </Card>
  )
}

export default ImageCard
