export const DASHBOARD_ROUTE = "/dashboard"
export const CREATE_EVENT = "/create-event"
export const EVENTS = "/events"
export const PAYMENTS = "/payments"
export const EVENT = "/event/:id"
export const EDIT_PROFILE = "/edit-profile"
export const CREATE_PROFILE = "/create-profile"
export const PROFILES = "/profiles"
export const PROFILE = "/profile/:id"
export const REGISTER = "/register"
export const LOGIN = "/login"
export const LOGOUT = "/logout"
export const UPLOAD_IMAGES = "/event/:id/upload-images"
export const VIEW_IMAGES = "/event/:id/images"
export const VERIFIED_IMAGES = "/event/:id/verified-images"
export const VERIFY_CUSTOMER_SEARCHES = "/event/:id/search-results"
export const ASSIGN_USERS = "/event/:id/assign-users"
export const ENCODE_IMAGES = "/event/:id/encode-images"
export const IDENTIFIED_IMAGES = "/event/:id/identified-images"
export const BRANDING_HOME = "/event/:id/branding-home"
export const GENERIC_BRANDING_IMAGES = "/event/:id/generic-branding-images"
export const DYNAMIC_BRANDING_IMAGES =
  "/event/:id/classification-branding-images"
export const APPLY_BRANDING_IMAGES = "/event/:id/apply-branding"
export const PARTICIPANT_HOME_PAGE = "/event/:id/participant-home"
export const SEED_PARTICIPANTS = "/event/:id/seed-participants"
export const CREATE_EDIT_PARTICIPANTS = "/event/:id/create-edit-participants"
export const SEARCH_FACES = "/event/:id/search-images"
export const SEARCH_IMAGES_LOCATION_DATE =
  "/event/:id/search-images-location-date"
export const MANAGE_EVENTS = "/event/:id/manage"
export const TAG_IMAGES = "/event/:id/tagging"
export const TAG_FACES = "/event/:id/tagging/tag-faces"
export const VERIFY_TAG_FACES = "/event/:id/tagging/verify-tag-faces"
export const TAGGING_STATISTICS = "/event/:id/tagging/statistics"
export const STATISTICS = "/event/:id/stats"
export const SEARCH_TAGGED_FACES = "/event/:id/tagging/search-tagged-faces"
export const USER_MANAGEMENT = "/user-management"
export const MANAGE_USERS = "/user-management/manage-users"
export const ASSIGN_PHOTOGRAPHERS = "/user-management/assign-photographers"
export const ASSIGN_USER_EVENTS = "/user-management/assign-event-users"
export const PAYMENTS_CONFIGURE_PARTICIPANTS = "/payments/participant/configure"
export const PAYMENTS_CREATE_PARTICIPANT_PAYMENT =
  "/payments/participant/create"
export const PAYMENTS_VIEW_PARTICIPANT_PAYMENT =
  "/payments/participant/:id/view"
