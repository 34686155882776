import React, { Fragment, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import Spinner from '../../shared/components/Spinner'

import {
  getNonBrandedImages,
  applyBrandingOnImage,
  clearNonBrandingImages,
} from '../actions/eventActions'

import { ProgressBar } from 'react-bootstrap'
import './GenericBrandingImages.css'
import { Button, Header, Icon, Segment } from 'semantic-ui-react'
import { setAlert } from '../../shared/actions/alert'

const ApplyBranding = ({ history }) => {
  const dispatch = useDispatch()
  const {
    event,
    nonBrandedImages,
    failedBrandingCount,
    brandingCount,
    loading,
  } = useSelector((state) => state.eventReducer)

  const [totalCount, setTotalCount] = useState(null)

  useEffect(() => {
    dispatch(clearNonBrandingImages())
    dispatch(getNonBrandedImages(event.id))
  }, [dispatch, event.id])

  useEffect(() => {
    if (nonBrandedImages && totalCount === null) {
      setTotalCount(nonBrandedImages.length)
    }
  }, [nonBrandedImages, totalCount, setTotalCount])

  const applyCloudBranding = async (e) => {
    dispatch(setAlert('Applying Branding. Please wait...', 'primary'))
    e.preventDefault()
    for (let i = 0; i < nonBrandedImages.length; i++) {
      const promiseBuffer = [
        dispatch(applyBrandingOnImage(nonBrandedImages[i])),
      ]
      await Promise.all(promiseBuffer)
    }
  }

  return (
    <Fragment>
      {loading ? (
        <Spinner />
      ) : (
        <Fragment>
          <h1 className='large text-primary'>Apply Branding - {event.name}</h1>
          <br></br>
          <br></br>
          <h3 className='medium text-primary'>
            Non Branded Images - {totalCount}
          </h3>
          <br></br>
          <em>
            Branding will take 5 to 10 minutes to take effect. Please wait for
            10 minutes for the same to update on all modules
          </em>
          <div>
            <Fragment>
              <form className='upload-steps' onSubmit={applyCloudBranding}>
                {brandingCount > 0 ? (
                  <div>
                    <h3> Branding Progress: </h3>
                    <ProgressBar
                      variant='info'
                      now={`${Math.round((brandingCount / totalCount) * 100)}`}
                      label={`${brandingCount} / ${totalCount} `}
                    />
                  </div>
                ) : (
                  <></>
                )}
                {failedBrandingCount > 0 ? (
                  <div>
                    <h3> Failed Brandings:</h3>
                    <ProgressBar
                      variant='info'
                      now={`${Math.round(
                        (failedBrandingCount / totalCount) * 100
                      )}`}
                      label={`${failedBrandingCount} / ${totalCount} `}
                    />
                  </div>
                ) : (
                  <></>
                )}
                <div className='branding-container'>
                  <Segment placeholder>
                    <Header icon>
                      <Icon name='file image outline' />
                    </Header>
                    <Button primary>Apply Branding</Button>
                  </Segment>
                </div>
              </form>
            </Fragment>
            <Fragment>
              <div></div>
            </Fragment>
          </div>
        </Fragment>
      )}
    </Fragment>
  )
}

export default ApplyBranding
