import React from "react"
import { Card } from "semantic-ui-react"
import { LazyLoadImage } from "react-lazy-load-image-component"

const ImageListCard = (images) => {
  return (
    <>
      {Object.keys(images).map((key, index) => (
        <>
          <Card
            id={images[key]["customer_id"]}
            color="grey"
            style={{ width: 495, height: 710 }}
          >
            <Card.Content style={{ backgroundColor: "rgb(224 223 219)" }}>
              <Card.Header>Participant Image</Card.Header>
              <br></br>
              <LazyLoadImage
                src={images[key]["customer_image"]}
                style={{ maxWidth: "100%", height: "auto", display: "block" }}
              />
            </Card.Content>
          </Card>
          {images[key]["matches"].map((item) => (
            <Card
              id={item.image_id}
              color="grey"
              style={{ width: 495, height: 710 }}
            >
              <Card.Content>
                <LazyLoadImage
                  src={item.url}
                  style={{ maxWidth: "100%", height: "auto", display: "block" }}
                />
              </Card.Content>
            </Card>
          ))}
        </>
      ))}
    </>
  )
}

export default ImageListCard
