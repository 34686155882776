import React, { Fragment, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  // Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from 'semantic-ui-react'
import Table from 'react-bootstrap/Table'
// import { MDBTable, MDBTableHead, MDBTableBody } from 'mdb-react-ui-kit'
import Select from 'react-select'
import Spinner from '../../shared/components/Spinner'
import { getUsers } from '../../user/actions/profile'
import {
  clearAssignUserEvents,
  getUserEventMappings,
  getUserPhotographerTaggings,
  setUserEventMappings,
  setUserEventTagMappings,
} from '../actions/eventActions'

// import './AssignUsers.css'
//import SearchTable from '../../shared/components/SearchTable'

const AssignUsers = ({ history }) => {
  const { users } = useSelector((state) => state.profile)
  const { event, loading, userEventMap, tagging } = useSelector(
    (state) => state.eventReducer
  )
  const [formFields, setFormFields] = useState([])
  const [eventUserFields, setEventUserFields] = useState([])
  const [taggingFields, setTaggingFields] = useState([])
  const [taggingOptions, setTaggingOptions] = useState([])
  const [taggingflag, setTaggingFlag] = useState(false)
  const dispatch = useDispatch()

  // const searchTableHead = {
  //   name: 'Name',
  //   email: 'Email',
  //   role: 'Role',
  //   isActive: 'Is Active',
  //   assignEvent: 'Assign Event',
  //   assignPhotographer: 'Assign Images of a Photographer for Tagging',
  // }

  useEffect(() => {
    dispatch(clearAssignUserEvents())
    setFormFields([])
    setEventUserFields([])
    setTaggingFields([])
    setTaggingOptions([])
    setTaggingFlag(false)
  }, [
    dispatch,
    setFormFields,
    setEventUserFields,
    setTaggingFields,
    setTaggingFlag,
  ])

  useEffect(() => {
    dispatch(getUsers())
  }, [dispatch])

  useEffect(() => {
    dispatch(getUserPhotographerTaggings(event.id))
  }, [dispatch, event])

  useEffect(() => {
    dispatch(getUserEventMappings(event.id))
  }, [dispatch, event.id])

  useEffect(() => {
    const values = []
    const tags = []
    users.map((user) => {
      values.push({
        id: user.id,
        name: user.name,
        email: user.email,
        role:
          user.role_id == 1
            ? 'ADMIN'
            : user.role_id == 2
            ? 'PHOTOGRAPHER'
            : user.role_id == 3
            ? 'LEAD_PHOTOGRAPHER'
            : user.role_id == 4
            ? 'EVENT_COORDINATOR'
            : 'CONTRACTOR',
        is_enabled: user.is_enabled,
        is_assigned: false,
        user: user.id,
        event: event.id,
      })
      tags.push({ value: user.id, label: user.name })
    })
    setFormFields(values)
    setTaggingOptions(tags)
  }, [users])

  useEffect(() => {
    const formValues = [...formFields]
    if (userEventMap && formValues.length > 0) {
      userEventMap.map((userEvent) => {
        const eventUserIndex = formValues.findIndex(
          (obj) => obj.id === userEvent.user.id
        )
        if (eventUserIndex >= 0) {
          if (formValues[eventUserIndex]) {
            formValues[eventUserIndex].is_assigned = true
          }
        }
      })
      setFormFields(formValues)
    }
  }, [userEventMap])

  useEffect(() => {
    const formValues = [...formFields]
    if (tagging) {
      tagging.map((tag) => {
        const tagIndex = formValues.findIndex((obj) => obj.id === tag.user)
        if (tagIndex >= 0) {
          const userIndex = users.findIndex(
            (obj) => obj.id === tag.assigned_photographer
          )
          if (formValues[tagIndex].selectedUsers) {
            let val = formValues[tagIndex].selectedUsers
            val.push({
              value: users[userIndex].id,
              label: users[userIndex].name,
            })
            formValues[tagIndex].selectedUsers = val
          } else {
            formValues[tagIndex].selectedUsers = [
              { value: users[userIndex].id, label: users[userIndex].name },
            ]
            formValues[tagIndex].taggingId = tag.id
          }
        }
      })
      setFormFields(formValues)
      setTaggingFields(formValues)
      setTaggingFlag(true)
    }
  }, [tagging, users])

  const handleInputChange = (id, e, taggingId) => {
    const values = [...formFields]
    let value
    let field
    if ('target' in e) {
      value = e.target.type === 'checkbox' ? e.target.checked : e.target.value
      field = e.target.name
      const objIndex = values.findIndex((obj) => obj.id === id)
      values[objIndex][field] = value
      setFormFields(values)
      const eventUservalues = [...eventUserFields]
      const eventUserIndex = eventUservalues.findIndex((obj) => obj.id === id)
      if (eventUserIndex == -1) {
        eventUservalues.push({
          id: id,
          is_assigned: value,
        })
      } else {
        eventUservalues[eventUserIndex][field] = value
      }
      setEventUserFields(eventUservalues)
    } else {
      value = e
      field = 'selectedUsers'
      const taggingValues = [...taggingFields]
      const taggingIndex = taggingValues.findIndex((obj) => obj.user === id)
      if (taggingIndex == -1) {
        taggingValues.push({
          id: taggingId,
          user: id,
          event: event.id,
          selectedUsers: value,
        })
      } else {
        taggingValues[taggingIndex][field] = value
      }
      setTaggingFields(taggingValues)
    }
  }

  const onSubmit = (e) => {
    e.preventDefault()
    dispatch(setUserEventMappings(event.id, eventUserFields, userEventMap))
    dispatch(setUserEventTagMappings(event.id, taggingFields, tagging))
    history.push('/dashboard')
  }

  return (
    <Fragment>
      {loading ? (
        <Spinner />
      ) : (
        <Fragment>
          <h1 className='large text-primary'>Assign Users - {event.name}</h1>
          <em>
            Issue Identified with cache. Please do ctrl + shift + R and revisit
            the page if any of the fields are not loading
          </em>
          {/* <div>
            <b>Location:</b>
            {event.location}
          </div>
          <div>
            <b>Event Type:</b> {event.type}
          </div>
          <div>
            <b>Start Date:</b> {event.from_date}
          </div>
          <div>
            <b>End Date: </b> {event.to_date}
          </div>
          <div>
            <b>Organizer</b> {event.organizer}
          </div>
          <div>
            <b>Sponsors:</b>
            {event.sponsors}
          </div> */}
          <br></br>
          <p className='lead'>
            <i className='fas fa-user'></i>Users available
          </p>
          <div className='profiles'>
            <form className='form' onSubmit={onSubmit}>
              {/* <SearchTable></SearchTable> */}
              {/* <table class='table table-fixed'> */}
              {/* <Table> */}
              <Table responsive>
                <thead>
                  <TableRow>
                    <TableHeaderCell>Name</TableHeaderCell>
                    <TableHeaderCell>Email</TableHeaderCell>
                    <TableHeaderCell>Role</TableHeaderCell>
                    <TableHeaderCell>Is Active</TableHeaderCell>
                    <TableHeaderCell>Assign Event</TableHeaderCell>
                    <TableHeaderCell>
                      Assign Images of a Photographer for Tagging
                    </TableHeaderCell>
                  </TableRow>
                </thead>
                <tbody>
                  {formFields.length > 0 && taggingflag == true ? (
                    formFields.map((user, index) => (
                      <TableRow key={index}>
                        <TableCell
                          onChange={(event) => handleInputChange(index, event)}>
                          {user.name}
                        </TableCell>
                        <TableCell
                          onChange={(event) => handleInputChange(index, event)}>
                          {user.email}
                        </TableCell>
                        <TableCell>
                          <div className='form-group'>
                            <input
                              id={user.id}
                              type='text'
                              name='role'
                              value={user.role}
                              disabled
                            />
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className='form-group'>
                            <input
                              id={user.id}
                              type='checkbox'
                              checked={user.is_enabled}
                              name='is_enabled'
                              disabled
                            />
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className='form-group'>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              role='switch'
                              id={user.id}
                              checked={user.is_assigned}
                              name='is_assigned'
                              onChange={(event) =>
                                handleInputChange(user.id, event)
                              }
                            />
                          </div>
                        </TableCell>
                        <TableCell key={index}>
                          <Select
                            key={index}
                            defaultValue={user.selectedUsers}
                            isMulti
                            name='selectedUsers'
                            options={taggingOptions}
                            className='basic-multi-select'
                            classNamePrefix='select'
                            onChange={(event) =>
                              handleInputChange(user.id, event, user.taggingId)
                            }
                          />
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell>
                        <>No profiles found...</>
                      </TableCell>
                    </TableRow>
                  )}
                </tbody>
              </Table>
              {/* </Table> */}
              {/* </table> */}
              <em>
                <br></br>
              </em>
              <em>
                <br></br>
              </em>
              <em>
                <br></br>
              </em>
              <em>
                <br></br>
              </em>
              <em>
                <br></br>
              </em>
              <em>
                <br></br>
              </em>
              <em>
                <br></br>
              </em>
              <em>
                <br></br>
              </em>
              <em>
                <br></br>
              </em>
              <em>
                <br></br>
              </em>
              <em>
                <br></br>
              </em>
              <em>
                <br></br>
              </em>
              <input
                type='submit'
                className='btn btn-primary my-1'
                value='Save Changes'
              />
            </form>
            <em>
              Admin and Event Co-ordinators will have access to all
              Photographer's images
            </em>
          </div>
        </Fragment>
      )}
    </Fragment>
  )
}

export default AssignUsers
