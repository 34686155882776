import React from "react"
import { Button, Card, Icon } from "semantic-ui-react"
import { Link } from "react-router-dom"

const ViewImagesCard = (event) => {
  return (
    <Card>
      <Card.Content>
        <Card.Header>View Images</Card.Header>
        <Card.Meta>View Uploaded Images</Card.Meta>
        <Icon.Group size="large">
          <Icon name="users" />
        </Icon.Group>
        <Card.Description>Verify uploaded images</Card.Description>
      </Card.Content>
      <Card.Content extra>
        <div className="ui two buttons">
          <Button basic color="green">
            <Link to={`/event/${event.id}/images`} className="btn btn-light">
              View Images
            </Link>
          </Button>
        </div>
      </Card.Content>
    </Card>
  )
}

export default ViewImagesCard
