// program to generate random strings
import crypto from 'crypto'
// declare all characters
const characters =
  'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

const generateRandomString = (length) => {
  let result = ''
  const charactersLength = characters.length
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }

  return result
}

const generateRandomBytes = (length) => {
  return crypto.randomBytes(length).toString('hex')
}
export { generateRandomString, generateRandomBytes }
