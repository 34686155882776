import React, { Fragment, useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Tab, Card, CardGroup, Button, Form } from 'semantic-ui-react'
import InfiniteScroll from 'react-infinite-scroller'
import Spinner from '../../shared/components/Spinner'
import { setAlert } from '../../shared/actions/alert'
import {
  getIdentifiedFaces,
  saveIdentifiedImages,
} from '../actions/faceActions'

function IdentifiedFaces() {
  const { event } = useSelector((state) => state.eventReducer)
  const [formFields, setFormFields] = useState([])
  const [bibNumber, setBibNumber] = useState('')
  //const [hasMore, setHasMore] = useState(true)
  const { identifiedFaceList, hideFormId, loading } = useSelector(
    (state) => state.faceReducer
  )

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getIdentifiedFaces(event.id))
  }, [dispatch, event.id])

  useEffect(() => {
    if (hideFormId) {
      let element = document.forms[hideFormId]
      element.setAttribute('hidden', '')
    }
  }, [hideFormId])

  const onChange = (e) => {
    setBibNumber({ ...bibNumber, [e.target.id]: e.target.value })
  }

  const handleInputChange = (event, index) => {
    const values = [...formFields]
    const field = event.target.id
    try {
      if (index in values) {
        let value = values[index]
        const objIndex = value.findIndex((obj) => obj.id === field)
        if (objIndex == -1) {
          value.push({
            id: field,
          })
          values[index] = value
          setFormFields(values)
        } else {
          let value = values[index]
          let updatedValues = value.filter((item) => item.id !== field)
          values[index] = updatedValues
          setFormFields(values)
        }
      } else {
        let value = [
          {
            id: field,
          },
        ]
        values[index] = value
        setFormFields(values)
      }
    } catch (err) {
      dispatch(setAlert('Try Again', 'danger'))
    }
  }

  const onSubmit = (e) => {
    e.preventDefault()
    let index = e.target.id
    if (index in formFields && index in bibNumber) {
      try {
        let bib = bibNumber[index]
        let fields = formFields[index]
        let imageIds = []
        for (var key in fields) {
          imageIds.push(fields[key].id)
        }
        dispatch(saveIdentifiedImages(bib, event.id, imageIds, index))
      } catch (err) {
        dispatch(setAlert('Saving identified images failed', 'danger'))
      }
    } else {
      dispatch(setAlert('Choose valid inputs', 'danger'))
    }
  }

  // const loadMore = () => {
  //   if (records === data.length) {
  //     setHasMore(false)
  //   } else {
  //     setTimeout(() => {
  //       setrecords(records + itemsPerPage)
  //     }, 2000)
  //   }
  // }

  // <InfiniteScroll
  //       pageStart={0}
  //       loadMore={loadMore}
  //       hasMore={hasMore}
  //       useWindow={false}></InfiniteScroll>

  return (
    <Fragment>
      <>
        <h2 className='medium text-primary'>
          Identified Images - {event.name}
        </h2>
        {loading ? (
          <Spinner />
        ) : identifiedFaceList ? (
          Object.keys(identifiedFaceList.matches).map((item, index) => (
            <Form className='form' onSubmit={onSubmit} id={index} key={index}>
              <Tab.Pane attached={false} key={index}>
                <CardGroup key={index}>
                  <Card>
                    <Card.Content>
                      <img src={identifiedFaceList.cloudUrl + item} />
                      <div
                        className='form-group'
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}>
                        <input
                          type='Checkbox'
                          id={identifiedFaceList.imageIds[item]}
                          onChange={(event) =>
                            handleInputChange(event, index)
                          }></input>
                      </div>
                    </Card.Content>
                  </Card>
                  {identifiedFaceList.matches[item].length > 0 ? (
                    identifiedFaceList.matches[item].map(
                      (match, childIndex) => (
                        <Card key={childIndex}>
                          <Card.Content>
                            <img
                              src={identifiedFaceList.cloudUrl + match}
                              // alt='1'
                            />
                            <div
                              className='form-group'
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}>
                              <input
                                type='Checkbox'
                                id={identifiedFaceList.imageIds[match]}
                                onChange={(event) =>
                                  handleInputChange(event, index)
                                }></input>
                            </div>
                          </Card.Content>
                        </Card>
                      )
                    )
                  ) : (
                    <></>
                  )}
                  {identifiedFaceList.matches[item].length > 0 ? (
                    <div
                      className='form-group'
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}>
                      <input
                        id={index}
                        type='text'
                        placeholder='BIB NUMBER'
                        name='BIB NUMBER'
                        onChange={onChange}
                      />
                      <Button variant='primary' id={index}>
                        Submit
                      </Button>
                    </div>
                  ) : (
                    <></>
                  )}
                </CardGroup>
              </Tab.Pane>
            </Form>
          ))
        ) : (
          <>No MATCHES FOUND</>
        )}
      </>
    </Fragment>
  )
}
export default IdentifiedFaces
