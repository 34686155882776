import React from 'react'
import { Button, Card, Icon } from 'semantic-ui-react'
import { Link } from 'react-router-dom'

const ImageLocationSearchCard = (event) => {
  return (
    <Card>
      <Card.Content>
        <Card.Header>Image Search - Date & Location</Card.Header>
        <Card.Meta>
          Search images based on the photo location and date taken
        </Card.Meta>
        <Icon.Group size='large'>
          <Icon name='users' />
        </Icon.Group>
        <Card.Description>
          Helps admin find matching images based on the photo location and date
          taken
        </Card.Description>
      </Card.Content>
      <Card.Content extra>
        <div className='ui two buttons'>
          <Button basic color='green'>
            <Link
              to={`/event/${event.id}/search-images-location-date`}
              className='btn btn-light'>
              Search Images
            </Link>
          </Button>
        </div>
      </Card.Content>
    </Card>
  )
}

export default ImageLocationSearchCard
