import React from 'react'
import { Button, Card, Icon } from 'semantic-ui-react'
import { Link } from 'react-router-dom'

const AssignEventsCard = () => {
  return (
    <Card>
      <Card.Content>
        <Card.Header>Assign Events</Card.Header>
        <Card.Meta>User Management</Card.Meta>
        <Icon.Group size='large'>
          <Icon name='users' />
        </Icon.Group>
        <Card.Description>Assign Events to Users</Card.Description>
      </Card.Content>
      <Card.Content extra>
        <div className='ui two buttons'>
          <Button basic color='green'>
            <Link
              to='/user-management/assign-event-users'
              className='btn btn-light'>
              Assign Events
            </Link>
          </Button>
        </div>
      </Card.Content>
    </Card>
  )
}

export default AssignEventsCard
