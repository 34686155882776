import {
  GET_PARTICIPANT_PAYMENTS,
  GET_PARTICIPANT_PAYMENT_MODULES,
  SET_PARTICIPANT_PAYMENT,
} from '../actions/paymentTypes'

const initialState = {
  loading: true,
  participantPayments: null,
  participantPaymentModules: null,
}

export default function (state = initialState, actions) {
  const { type, payload } = actions

  switch (type) {
    case GET_PARTICIPANT_PAYMENTS:
      return { ...state, participantPayments: payload, loading: false }
    // case SET_PARTICIPANT_PAYMENT:
    //   return { ...state, participantPaymentModule: payload, loading: false }
    case GET_PARTICIPANT_PAYMENT_MODULES:
      return { ...state, participantPaymentModules: payload, loading: false }
    default:
      return state
  }
}
