import { combineReducers } from "redux"
import alert from "./views/shared/reducers/alert"
import auth from "./views/user/reducers/auth"
import profile from "./views/user/reducers/profile"
import eventReducer from "./views/events/reducers/eventReducer"
import faceReducer from "./views/faces/reducers/faceReducer"
import uploadReducer from "./views/shared/reducers/uploadReducer"
import userReducer from "./views/user/reducers/user"
import paymentReducer from "./views/payments/reducers/paymentReducer"
import multiSelectReducer from "./views/shared/reducers/multiSelectReducer"
import participantReducer from "./views/participants/reducers/participantReducer"

export default combineReducers({
  alert,
  auth,
  profile,
  eventReducer,
  faceReducer,
  userReducer,
  uploadReducer,
  paymentReducer,
  multiSelectReducer,
  participantReducer,
})
