import React, { Fragment, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from 'semantic-ui-react'
import Spinner from '../../../shared/components/Spinner'
import { clearUsers, getUsers, updateUsers } from '../../actions/profile'

const ManageUsers = ({ history }) => {
  const { users, loading } = useSelector((state) => state.profile)
  const [formFields, setFormFields] = useState([])
  const dispatch = useDispatch()

  useEffect(() => {
    setFormFields([])
    dispatch(clearUsers())
    dispatch(getUsers())
  }, [dispatch, setFormFields])

  useEffect(() => {
    const values = []
    users.map((user) =>
      values.push({
        id: user.id,
        name: user.name,
        email: user.email,
        is_enabled: user.is_enabled,
        role: user.role_id,
      })
    )
    setFormFields(values)
  }, [users])

  const handleInputChange = (id, event) => {
    const values = [...formFields]
    const value =
      event.target.type === 'checkbox'
        ? event.target.checked
        : event.target.value
    const field = event.target.name
    const objIndex = values.findIndex((obj) => obj.id == id)
    values[objIndex][field] = value
    setFormFields(values)
  }

  const onSubmit = (e) => {
    e.preventDefault()
    dispatch(updateUsers(formFields))
    history.push('/dashboard')
  }

  return (
    <Fragment>
      {formFields.length === 0 ? (
        <Spinner />
      ) : (
        <Fragment>
          <h1 className='large text-primary'>Users</h1>
          <p className='lead'>
            <i className='fab fa-connectdevelop'></i>User details
          </p>
          <div className='profiles'>
            <form className='form' onSubmit={onSubmit}>
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHeaderCell>Sl No</TableHeaderCell>
                    <TableHeaderCell>Name</TableHeaderCell>
                    <TableHeaderCell>Email</TableHeaderCell>
                    <TableHeaderCell>Is Active</TableHeaderCell>
                    <TableHeaderCell>User Role</TableHeaderCell>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {formFields.length > 0 ? (
                    formFields.map((user, index) => (
                      <TableRow key={index}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell
                          onChange={(event) => handleInputChange(index, event)}>
                          {user.name}
                        </TableCell>
                        <TableCell
                          onChange={(event) => handleInputChange(index, event)}>
                          {user.email}
                        </TableCell>
                        <TableCell>
                          <div className='form-group'>
                            <input
                              id={user.id}
                              type='checkbox'
                              checked={user.is_enabled}
                              name='is_enabled'
                              onChange={(event) =>
                                handleInputChange(user.id, event)
                              }
                            />
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className='form-group'>
                            <select
                              className='ui dropdown'
                              name='role'
                              id={user.id}
                              onChange={(event) =>
                                handleInputChange(user.id, event)
                              }
                              value={user.role}>
                              <option value='0'>Please Select</option>
                              <option value='1'>ADMIN</option>
                              <option value='2'>PHOTOGRAPHER</option>
                              <option value='3'>LEAD PHOTOGRAPHER</option>
                              <option value='4'>EVENT COORDINATOR</option>
                              <option value='5'>CONTRACTOR</option>
                            </select>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell>
                        <>No profiles found...</>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              <input
                type='submit'
                className='btn btn-primary my-1'
                value='Save Changes'
              />
            </form>
            {/* {profiles.length > 0 ? (
              profiles.map((profile) => (
                <ProfileItem key={profile.id} profile={profile} />
              ))
            ) : (
              <h4>No profiles found...</h4>
            )} */}
          </div>
        </Fragment>
      )}
    </Fragment>
  )
}

export default ManageUsers
