import React from "react"
import { Button, Card, Icon } from "semantic-ui-react"
import { Link } from "react-router-dom"

const GenericBrandingCard = (event) => {
  return (
    <Card>
      <Card.Content>
        <Card.Header>Generic Branding</Card.Header>
        <Card.Meta>Configure Generic Branding Images</Card.Meta>
        <Icon.Group size="large">
          <Icon name="users" />
        </Icon.Group>
        <Card.Description>
          Configure Portrait and Landscape Generic Branding Images. Configured
          branding would be applied to all images while uploading itself.
        </Card.Description>
      </Card.Content>
      <Card.Content extra>
        <div className="ui two buttons">
          {event.is_dynamic_branding_enabled ? (
            <Button basic color="grey" disabled>
              <Link
                to={`/event/${event.id}/generic-branding-images`}
                className="btn btn-light"
              >
                Configure
              </Link>
            </Button>
          ) : (
            <Button basic color="green">
              <Link
                to={`/event/${event.id}/generic-branding-images`}
                className="btn btn-light"
              >
                Configure
              </Link>
            </Button>
          )}
        </div>
      </Card.Content>
    </Card>
  )
}

export default GenericBrandingCard
