import React, { Fragment, useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Tab, Message, Button } from "semantic-ui-react"
import Spinner from "../../shared/components/Spinner"
import { LazyLoadImage } from "react-lazy-load-image-component"

import { searchBibImages } from "../actions/faceActions"
import Box from "@mui/material/Box"
import TextField from "@mui/material/TextField"
import {
  BsFillArrowRightSquareFill,
  BsFillArrowLeftSquareFill,
} from "react-icons/bs"
import "./TagFaces.css"

function SearchBibs() {
  const { event } = useSelector((state) => state.eventReducer)

  const { bibResults, loading } = useSelector((state) => state.faceReducer)
  const [formData, setFormData] = useState({
    bibNumber: "",
  })
  const [current, setCurrent] = useState(0)
  const [length, setLength] = useState(0)

  const { bibNumber } = formData
  const dispatch = useDispatch()

  useEffect(() => {
    if (bibResults) {
      if (bibResults[0]["matches"] === undefined) {
        setLength(1)
      } else {
        setLength(bibResults[0]["matches"].length)
      }
    }
  }, [setLength, bibResults])

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const onSubmit = (e) => {
    e.preventDefault()
    dispatch(searchBibImages(bibNumber, event.id))
  }

  const nextSlide = () => {
    setCurrent(current === length - 1 ? 0 : current + 1)
  }

  const prevSlide = () => {
    setCurrent(current === 0 ? length - 1 : current - 1)
  }

  return (
    <div>
      <Fragment>
        <h2 className="medium text-primary">Search Images - {event.name}</h2>
        <Tab.Pane attached={false}>
          <Message>Enter Bib number to search Images</Message>
          <form className="upload-steps" onSubmit={onSubmit}>
            <div style={{ display: "flex" }}>
              <div>
                <Box
                  component="form"
                  sx={{
                    "& > :not(style)": { m: 1, width: "77ch", bottom: "10px" },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    id="outlined-basic"
                    label="Enter BIB number"
                    variant="outlined"
                    onChange={onChange}
                    value={bibNumber}
                    type="text"
                    name="bibNumber"
                  />
                </Box>
              </div>
              <div style={{ height: "50px" }}>
                <Button
                  variant="outlined"
                  style={{ height: "45px", width: "120px" }}
                >
                  Search
                </Button>
              </div>
            </div>
          </form>
        </Tab.Pane>
        <Tab.Pane attached={false}>
          <Message>Search Results</Message>
          {loading ? (
            <Spinner />
          ) : (
            <div>
              <div className="slider">
                {bibResults && bibResults.length > 0 ? (
                  <>
                    <BsFillArrowLeftSquareFill
                      style={{
                        background: "black",
                        color: "white",
                        borderRadius: "10px",
                      }}
                      className="left-arrow"
                      onClick={prevSlide}
                      tabIndex="1"
                    />
                    <BsFillArrowRightSquareFill
                      style={{
                        background: "black",
                        color: "white",
                        borderRadius: "10px",
                      }}
                      className="right-arrow"
                      onClick={nextSlide}
                      tabIndex="4"
                    />
                    {bibResults.map((bibResultMatch) =>
                      bibResultMatch.message != undefined ? (
                        <>
                          <Message>{bibResultMatch.message}</Message>
                        </>
                      ) : (
                        <>
                          {bibResultMatch.matches.length > 0 ? (
                            bibResultMatch.matches.map((bibResult, index) => (
                              <div
                                className={
                                  index === current ? "slide active" : "slide"
                                }
                                key={index}
                                id={index}
                              >
                                {index === current && (
                                  <LazyLoadImage
                                    key={index}
                                    id={bibResult.id}
                                    src={bibResult.image}
                                    alt={bibResult.id}
                                    className={
                                      bibResult.type === "LANDSCAPE"
                                        ? "form-group landscape-image"
                                        : bibResult.type === "PORTRAIT"
                                        ? "form-group portrait-image"
                                        : "form-group landscape-image"
                                    }
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  />
                                )}
                              </div>
                            ))
                          ) : (
                            <div>No Images Found</div>
                          )}
                        </>
                      )
                    )}
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          )}
        </Tab.Pane>
      </Fragment>
    </div>
  )
}
export default SearchBibs
