import React from 'react'
import { Button, Card, Icon } from 'semantic-ui-react'
import { Link } from 'react-router-dom'

const ProfilesCard = () => {
  return (
    <Card>
      <Card.Content>
        <Card.Header>User Profiles</Card.Header>
        <Card.Meta>Profile Details</Card.Meta>
        <Icon.Group size='large'>
          <Icon name='users' />
        </Icon.Group>
        <Card.Description>Lists user profiles</Card.Description>
      </Card.Content>
      <Card.Content extra>
        <div className='ui two buttons'>
          <Button basic color='green'>
            <Link to='/profiles' className='btn btn-light'>
              View Profiles
            </Link>
          </Button>
        </div>
      </Card.Content>
    </Card>
  )
}

export default ProfilesCard
