import axios from "axios"
import { setAlert } from "../../shared/actions/alert"
import {
  SET_PARTICIPANTS,
  PARTICIPANT_ERROR,
  SET_LOADING,
} from "./participantTypes"
import { isEmpty } from "../../../utils/objectUtils"

const configHeader = {
  headers: { "Content-Type": "application/json" },
}

export const getAllEventParticipants = (event_id) => async (dispatch) => {
  await axios
    .get(
      "/api/participants",
      {
        params: {
          event_id: event_id,
        },
      },
      configHeader
    )
    .then((response) => {
      dispatch({ type: SET_PARTICIPANTS, payload: response.data })
    })
}

//Create Participants
export const createParticipants = (participant, performance) => {
  return async (dispatch) => {
    return new Promise(async (resolve) => {
      try {
        dispatch({ type: SET_LOADING, payload: true })
        await axios
          .get(
            "/api/participants",
            {
              params: {
                bib_number: participant.bib_number,
                event_id: participant.event_id,
              },
            },
            configHeader
          )
          .then((response) => {
            if (response.data.length === 0) {
              axios
                .post("/api/participants", participant, configHeader)
                .then((response) => {
                  if (response.status === 201) {
                    if (!isEmpty(performance)) {
                      performance["participant_id"] = response.data.id
                      axios
                        .post(
                          "/api/participants/performance",
                          performance,
                          configHeader
                        )
                        .then(async (response) => {
                          if (response.status === 201) {
                            return resolve(true)
                          } else {
                            dispatch(
                              setAlert(
                                "Participant performance update failed",
                                "danger"
                              )
                            )
                            return resolve(false)
                          }
                        })
                    } else {
                      return resolve(true)
                    }
                  } else {
                    dispatch(setAlert("Participant update failed", "danger"))
                    return resolve(false)
                  }
                })
            } else {
              return resolve(
                dispatch(updateParticipants(participant, performance))
              )
            }
          })
      } catch (err) {
        dispatch({
          type: PARTICIPANT_ERROR,
          payload: {
            msg: err.response.statusText,
            status: err.response.status,
          },
        })
        return resolve(false)
      }
    })
  }
}

//Create Participants
export const updateParticipants = (participant, performance) => {
  return async (dispatch) => {
    return new Promise(async (resolve) => {
      try {
        dispatch({ type: SET_LOADING, payload: true })

        await axios
          .put("/api/participants", participant, configHeader)
          .then(async (response) => {
            if (response.status === 200) {
              if (!isEmpty(performance)) {
                performance["participant_id"] = response.data[0].id
                axios
                  .get(
                    "/api/participants/performance",
                    {
                      params: {
                        participant_id: response.data[0].id,
                      },
                    },
                    configHeader
                  )
                  .then((response) => {
                    if (response.data.length === 0) {
                      axios
                        .post(
                          "/api/participants/performance",
                          performance,
                          configHeader
                        )
                        .then(async (response) => {
                          if (response.status === 200) {
                            return resolve(true)
                          } else {
                            dispatch(
                              setAlert(
                                "Participant performance update failed",
                                "danger"
                              )
                            )
                            return resolve(false)
                          }
                        })
                    } else {
                      axios
                        .put(
                          "/api/participants/performance",
                          performance,
                          configHeader
                        )
                        .then(async (response) => {
                          if (response.status === 200) {
                            return resolve(true)
                          } else {
                            dispatch(
                              setAlert(
                                "Participant performance update failed",
                                "danger"
                              )
                            )
                            return resolve(false)
                          }
                        })
                    }
                  })
              } else {
                return resolve(true)
              }
            } else {
              dispatch(setAlert("Participant update failed", "danger"))
              return resolve(false)
            }
          })
      } catch (err) {
        dispatch({
          type: PARTICIPANT_ERROR,
          payload: {
            msg: err.response.statusText,
            status: err.response.status,
          },
        })
        return resolve(false)
      }
    })
  }
}

export const deleteParticipant = (id, event_id) => async (dispatch) => {
  try {
    await axios
      .delete(
        "/api/participants",
        { params: { participant_id: id } },
        configHeader
      )
      .then((res) => {
        dispatch(getAllEventParticipants(event_id))
      })
  } catch (err) {
    dispatch({
      type: PARTICIPANT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    })
  }
}

export const updateParticipant =
  (participant, event_id) => async (dispatch) => {
    try {
      await axios
        .put("/api/participants", participant, configHeader)
        .then((response) => {
          if (response.status === 200) {
            //dispatch({ type: UPDATE_PARTICIPANT, payload: participant })
            dispatch(getAllEventParticipants(event_id))
          } else {
            dispatch(setAlert("Participant insertion failed", "danger"))
          }
        })
    } catch (err) {
      dispatch({
        type: PARTICIPANT_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status },
      })
    }
  }

export const createParticipant =
  (participant, event_id) => async (dispatch) => {
    try {
      await axios
        .post("/api/participants", participant, configHeader)
        .then(async (response) => {
          if (response.status === 201) {
            //dispatch({ type: ADD_PARTICIPANT, payload: participant })
            dispatch(getAllEventParticipants(event_id))
          } else {
            dispatch(setAlert("Participant updation failed", "danger"))
          }
        })
    } catch (err) {
      dispatch({
        type: PARTICIPANT_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status },
      })
    }
  }
