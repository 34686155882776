import React from "react"
import { Button, Card, Icon } from "semantic-ui-react"
import { Link } from "react-router-dom"

const ParticipantCard = (event) => {
  return (
    <Card>
      <Card.Content>
        <Card.Header>Manage Participants</Card.Header>
        <Card.Meta>Manage Participants</Card.Meta>
        <Icon.Group size="large">
          <Icon name="users" />
        </Icon.Group>
        <Card.Description>
          Create / Update participant information
        </Card.Description>
      </Card.Content>
      <Card.Content extra>
        <div className="ui two buttons">
          <Button basic color="green">
            <Link
              to={`/event/${event.id}/create-edit-participants`}
              className="btn btn-light"
            >
              Manage Participants
            </Link>
          </Button>
        </div>
      </Card.Content>
    </Card>
  )
}

export default ParticipantCard
