import React from "react"
import { useDispatch } from "react-redux"
import { Button, Card, Icon } from "semantic-ui-react"
import { Link } from "react-router-dom"
import { cleanTaggedFaces } from "../../actions/faceActions"

const SearchTaggedImagesCard = (event) => {
  const dispatch = useDispatch()

  const handleClick = () => {
    dispatch(cleanTaggedFaces())
  }

  return (
    <Card>
      <Card.Content>
        <Card.Header>BIB Search</Card.Header>
        <Card.Meta>Search Images with bib numbers</Card.Meta>
        <Icon.Group size="large">
          <Icon name="users" />
        </Icon.Group>
        <Card.Description>Search Images with bib numbers </Card.Description>
      </Card.Content>
      <Card.Content extra>
        <div className="ui two buttons">
          <Button basic color="green" onClick={handleClick}>
            <Link
              to={`/event/${event.id}/tagging/search-tagged-faces`}
              className="btn btn-light"
            >
              Search Tagged Images
            </Link>
          </Button>
        </div>
      </Card.Content>
    </Card>
  )
}

export default SearchTaggedImagesCard
