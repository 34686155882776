import React, { useState } from "react"
import { Button, Label, Card, Container } from "semantic-ui-react"
import { setSearchUploadFile } from "../actions/uploadActions"
import { useSelector, useDispatch } from "react-redux"

function UploadPreviewPlaceHolder() {
  //const [file, setFile] = useState()
  const { fileToUpload } = useSelector((state) => state.uploadReducer)
  const dispatch = useDispatch()

  function handleChange(e) {
    //console.log(e.target.files)
    //setFile(URL.createObjectURL(e.target.files[0]))
    dispatch(setSearchUploadFile(e.target.files[0]))
  }

  return (
    <Container>
      <Label>
        Select Image to Search
        <input id="files" type="file" onChange={handleChange} />
      </Label>
      {fileToUpload ? (
        <Card>
          <img src={URL.createObjectURL(fileToUpload)} />
        </Card>
      ) : (
        <></>
      )}
      <Button primary>Find Matches</Button>
    </Container>
  )
}
export default UploadPreviewPlaceHolder
