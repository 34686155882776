import React from "react";
import { Card } from "semantic-ui-react";
import { useSelector } from "react-redux";
import EventCard from "./EventCard";
import UsersCard from "./UsersCard";
import ProfileCard from "../../../user/components/cards/ProfileCard";
import PaymentCard from "./PaymentCards";

const DashboardCards = () => {
  const auth = useSelector((state) => state.auth);
  const { isAuthenticated, loading, role } = auth;

  let isAdmin = false;
  if (role.length > 0) {
    if (role === "ADMIN") {
      isAdmin = true;
    }
  }

  const adminCards = (
    <Card.Group>
      <ProfileCard />
      <EventCard />
      <UsersCard />
      <PaymentCard />
    </Card.Group>
  );

  const userCards = (
    <Card.Group>
      <ProfileCard />
      <EventCard />
    </Card.Group>
  );

  return (
    <div>
      {!loading && isAuthenticated && (isAdmin ? adminCards : userCards)}
    </div>
  );
};

export default DashboardCards;
