import {
  GET_PROFILE,
  GET_REPOS,
  GET_PROFILES,
  UPDATE_PROFILE,
  PROFILE_ERROR,
  CLEAR_PROFILE,
  GITHUB_ERROR,
  GET_USERS,
  UPDATE_USERS,
  CLEAR_USERS,
} from '../actions/types'

const initialState = {
  profile: null,
  profiles: [],
  repos: [],
  loading: true,
  error: {},
  users: [],
}

export default function (state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case GET_PROFILE:
    case UPDATE_PROFILE:
      return { ...state, profile: payload, loading: false }

    case GET_PROFILES:
      return { ...state, profiles: payload, loading: false }

    case PROFILE_ERROR:
      return { ...state, profile: null, loading: false, error: payload }

    case CLEAR_PROFILE:
      return { ...state, profile: null, loading: false, repos: [] }

    case GET_REPOS:
      return { ...state, loading: false, repos: payload }

    case GITHUB_ERROR:
      return { ...state, loading: false, repos: [] }

    case GET_USERS:
      return { ...state, users: payload, loading: false }

    case UPDATE_USERS:
      state.users.map((element, index) => {
        if (element.id === payload.id) {
          state.users[index] = payload
        }
      })
      return {
        ...state,
        loading: false,
      }
    case CLEAR_USERS:
      return { ...state, users: [], loading: false }

    default:
      return state
  }
}
