import React, { useContext } from 'react'
import { Button } from 'react-bootstrap'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import { DASHBOARD_ROUTE } from '../../../routes/routers'
import { ModelContext } from '../../shared/context/modelContext'

const DialogModel = () => {
  const [centredModal, setCentredModal, history] = useContext(ModelContext)
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

  const toggleShow = () => {
    setCentredModal(!centredModal)
    history.push(DASHBOARD_ROUTE)
  }

  return (
    // <Modal show={centredModal} onHide={setCentredModal}>
    //   <>
    //     <div className='modal-header'>
    //       <h5 className='modal-title'>Configure Verifier Script</h5>
    //       <button
    //         type='button'
    //         className='close'
    //         data-dismiss='modal'
    //         aria-label='Close'
    //         onClick={toggleShow}>
    //         <span aria-hidden='true'>&times;</span>
    //       </button>
    //     </div>
    //     <Modal.Body>Click OK to continue</Modal.Body>
    //     <Modal.Footer>
    //       <Button variant='secondary' onClick={toggleShow}>
    //         OK
    //       </Button>
    //       {/* <Button variant='primary' onClick={onSubmit}>
    //           Save
    //         </Button> */}
    //     </Modal.Footer>
    //   </>
    // </Modal>

    <div>
      <Dialog
        fullScreen={fullScreen}
        open={centredModal}
        onClose={toggleShow}
        aria-labelledby='responsive-dialog-title'>
        <DialogTitle id='responsive-dialog-title'>
          {'Selected files uploaded, Do you wish to continue?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Finished processing all the selected files. Click OK to upload more
            files..
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleShow} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default DialogModel
