import React, { Fragment, useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import Spinner from "../../shared/components/Spinner"
import { cleanSearchResults, getSearchResults } from "../actions/faceActions"
import { Tab } from "semantic-ui-react"
import ImageListPaginator from "../../shared/components/pagination/ImageListPaginator"
import paginate from "../../shared/components/pagination/Paginate"

function SearchedFaceResults() {
  const { event } = useSelector((state) => state.eventReducer)
  const { faceSearchResults, loading } = useSelector(
    (state) => state.faceReducer
  )
  const [imageProps, setImageProps] = useState(null)
  //const [formFields, setFormFields] = useState([])

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(cleanSearchResults())
  }, [dispatch])

  useEffect(() => {
    dispatch(getSearchResults(event.id))
  }, [dispatch, event])

  useEffect(() => {
    if (faceSearchResults) {
      setImageProps({
        loading: loading,
        paginatedData: paginate(faceSearchResults, 1),
        pageSize: 1,
        pageHeader: "Face Recognition Results",
        totalCount: faceSearchResults.length,
      })
    }
  }, [faceSearchResults])

  // const handleInputChange = (event, participantId) => {
  //   const values = [...formFields]
  //   const field = event.target.id
  //   const isChecked = event.target.checked
  //   try {
  //     if (participantId in values) {
  //       let value = values[participantId]
  //       const objIndex = value.findIndex((obj) => obj.id === field)
  //       if (objIndex == -1) {
  //         value.push({
  //           id: field,
  //           value: isChecked,
  //         })
  //         values[participantId] = value
  //         setFormFields(values)
  //       } else {
  //         let value = values[participantId]
  //         let updatedValues = value.filter((item) => item.id !== field)
  //         values[participantId] = updatedValues
  //         setFormFields(values)
  //       }
  //     } else {
  //       let value = [
  //         {
  //           id: field,
  //           value: isChecked,
  //         },
  //       ]
  //       values[participantId] = value
  //       setFormFields(values)
  //     }
  //   } catch (err) {
  //     dispatch(setAlert("Try Again", "danger"))
  //   }
  // }

  // const onSubmit = (e) => {
  //   e.preventDefault()
  //   let participantId = e.target.id
  //   if (participantId in formFields) {
  //     try {
  //       let fields = formFields[participantId]
  //       dispatch(saveSearchResults(participantId, fields, event.id))
  //     } catch (err) {
  //       dispatch(setAlert("Saving Verified Results failed", "danger"))
  //     }
  //   } else {
  //     dispatch(setAlert("Choose valid inputs", "danger"))
  //   }
  // }

  return (
    <Fragment>
      <>
        <h2 className="medium text-primary">
          Participant Search Results - {event.name}
        </h2>
        {loading ? (
          <Spinner />
        ) : imageProps ? (
          <Fragment>
            <Tab.Pane attached={false}>
              <ImageListPaginator {...imageProps} />
            </Tab.Pane>
          </Fragment>
        ) : (
          <>No Search Results Found</>
        )}
      </>
    </Fragment>
  )
}
export default SearchedFaceResults
