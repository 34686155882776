import React, { useState, Fragment, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Message, Tab } from 'semantic-ui-react'
import './CreateEditParticipantPayment.css'
import ParticipantPaymentFields from './ParticipantPaymentFields'
import { TextField } from '@mui/material'
import { saveParticipantPaymentModule } from '../actions/paymentActions'

const CreateEditParticipantPayment = ({ history, match }) => {
  const { loading, participantPayments } = useSelector(
    (state) => state.paymentReducer
  )
  const dispatch = useDispatch()
  const { id } = match.params
  const [formData, setFormData] = useState({
    description: '',
    flatPrice: '',
    finalStepCount: '',
    finalStepPrice: '',
  })

  const { description, flatPrice, finalStepCount, finalStepPrice } = formData
  const [name, setName] = useState('')
  const [stepCalculations, setStepCalculations] = useState(null)
  const [flatTotal, setFlatTotal] = useState(null)
  const [stepDetails, setStepDetails] = useState(null)
  const [flatDetails, setFlatDetails] = useState(null)
  const [individualDetails, setIndividualDetails] = useState(null)

  const [stepData, setStepData] = useState([
    [
      {
        text: 1,
      },
      {
        label: 'Photograph Count',
        field: 'photographCount',
        value: '',
        name: '00',
      },
      {
        label: 'Price',
        field: 'price',
        value: '',
        name: '01',
      },
    ],
  ])

  useEffect(() => {
    if (id && participantPayments) {
      let participantPaymentModule
      participantPayments.map((paymentModule) => {
        Object.keys(paymentModule).map((item, index) => {
          if (item === id) {
            participantPaymentModule = paymentModule[item]
          }
        })
      })
      setName(participantPaymentModule['name'])
      if ('individual' in participantPaymentModule) {
        setFormData({
          description: participantPaymentModule['description'],
          flatPrice: participantPaymentModule['individual']['price'],
          finalStepCount: '',
          finalStepPrice: '',
        })
      } else {
        setFormData({
          description: participantPaymentModule['description'],
          flatPrice: '',
          finalStepCount: participantPaymentModule['flat'][0]['count'],
          finalStepPrice: participantPaymentModule['flat'][0]['price'],
        })
        let stepDataVals = []
        participantPaymentModule['step'].map((participantPayment, index) => {
          stepDataVals.push([
            {
              text: index + 1,
            },
            {
              label: 'Photograph Count',
              field: 'photographCount',
              value: participantPayment['count'],
              name: index + '00',
            },
            {
              label: 'Price',
              field: 'price',
              value: participantPayment['price'],
              name: index + '01',
            },
          ])
        })
        setStepData(stepDataVals)
      }
    }
  }, [id, participantPayments])

  const handleStepOnChange = (e, row, col) => {
    const newData = stepData.map((d, i) => {
      if (i === row) {
        d[col].value = e.target.value
      }

      return d
    })
    setStepData(newData)
  }

  const addStepRow = () => {
    setStepData([
      ...stepData,
      [
        {
          text: stepData.length + 1,
        },
        {
          label: 'Photograph Count',
          field: 'photographCount',
          value: '',
          name: `${stepData.length}0`,
        },
        {
          label: 'Price',
          field: 'price',
          value: '',
          name: `${stepData.length}1`,
        },
      ],
    ])
  }

  const removeStepRow = (index) => {
    const _data = [...stepData]
    _data.splice(index, 1)
    setStepData(_data)
  }

  const onChange = (e) => {
    if (
      (e.target.name === 'flatPrice' && e.target.value === '') ||
      (e.target.name === 'finalStepPrice' && e.target.value === '')
    ) {
      setFlatTotal(null)
      setStepDetails(null)
      setFlatDetails(null)
      setIndividualDetails(null)
      setName('')
    }
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }
  const onSubmit = (e) => {
    dispatch(
      saveParticipantPaymentModule(
        {
          name: name,
          description: description,
          stepPrice: stepDetails,
          flatPrice: flatDetails,
          individualPrice: individualDetails,
        },
        history,
        flatTotal,
        stepCalculations
      )
    )
    e.preventDefault()
  }

  useEffect(() => {
    if (formData && formData['finalStepPrice'].length !== 0) {
      let total = 0
      let prevCalc = ''
      let stepCalc = ''
      let stepList = []
      let stepCalculations = []
      stepData.map((step) => {
        let photographCount
        let price
        step.map((data) => {
          if (data['field'] === 'photographCount') {
            photographCount = data['value']
          }
          if (data['field'] === 'price') {
            price = data['value']
          }
        })
        stepList.push({ photographCount: photographCount, price: price })
        total = total + photographCount * price
        if (prevCalc.length > 0) {
          stepCalc =
            prevCalc +
            photographCount.toString() +
            '*' +
            price.toString() +
            ' = ' +
            total
          stepCalculations.push(stepCalc)
        } else {
          stepCalc =
            stepCalc +
            photographCount.toString() +
            '*' +
            price.toString() +
            ' = ' +
            photographCount * price
          stepCalculations.push(stepCalc)
        }
        prevCalc =
          prevCalc + photographCount.toString() + '*' + price.toString() + ' + '
      })

      stepCalculations.push(
        formData['finalStepCount'].toString() +
          ' + more  =  ' +
          formData['finalStepPrice']
      )

      setStepCalculations(stepCalculations)
      setName(
        'step__' +
          stepCalc.replace(/ /g, '') +
          '__OR__' +
          formData['finalStepCount'].toString() +
          '++' +
          formData['finalStepPrice']
      )
      setStepDetails(stepList)
      setFlatDetails([
        {
          photographCount: formData['finalStepCount'],
          price: formData['finalStepPrice'],
        },
      ])
    } else {
      if (formData['flatPrice'].length !== 0) {
        setFlatTotal(formData['flatPrice'])
        setName('flat_' + formData['flatPrice'])
        setIndividualDetails(formData['flatPrice'])
      }
    }
  }, [formData, stepData])

  return (
    <div>
      <form className='form' onSubmit={onSubmit}>
        <Fragment>
          <h1 className='large text-primary'>
            {id ? (
              <>VIEW PARTICIPANT PAYMENTS</>
            ) : (
              <>CREATE PARTICIPANT PAYMENTS</>
            )}
          </h1>
          <Tab.Pane attached={false}>
            <Message>Payment Module Details</Message>
            <div className='form-group'>
              <input
                type='text'
                placeholder='Payment Module Name'
                name='name'
                value={name}
                onChange={onChange}
                readOnly='readonly'
              />
              <small className='form-text'>
                Name of the module will auto populate based on pricing
              </small>
            </div>

            <div className='form-group'>
              {id ? (
                <input
                  type='text'
                  placeholder='Description'
                  name='description'
                  value={description}
                  onChange={onChange}
                  readOnly='readonly'
                />
              ) : (
                <input
                  type='text'
                  placeholder='Description'
                  name='description'
                  value={description}
                  onChange={onChange}
                />
              )}

              <small className='form-text'>Description of the module</small>
            </div>
          </Tab.Pane>
          <Message>
            Configure either one of Step Pricing / Flat Pricing for a payment
            module. Flat pricing use case : Charge flat 50/- for all images.
            Step pricing use case : Charge 50/- for first 3 images, 40/- for
            next 2 images and flat 300 for 6+ images. Payment Module once
            created cannot be deleted or modified.
          </Message>
          <Tab.Pane attached={false}>
            <Message>Configure Step Pricing</Message>
            <ParticipantPaymentFields
              data={stepData}
              addRow={addStepRow}
              removeRow={removeStepRow}
              handleOnChange={handleStepOnChange}
              finalStepCount={finalStepCount}
              finalStepPrice={finalStepPrice}
              onChange={onChange}
              id={id}
            />
          </Tab.Pane>
          <Tab.Pane attached={false}>
            <Message>Configure Individual Pricing</Message>
            <div className='payment-container'>
              <div className='payment-content'>
                <div className='payment-content-row'>
                  <div>Price for individual photograph</div>
                  {id ? (
                    <TextField
                      label='Price for individual photograph'
                      value={flatPrice}
                      onChange={onChange}
                      variant='outlined'
                      name='flatPrice'
                      readOnly='readonly'
                    />
                  ) : (
                    <TextField
                      label='Price for individual photograph'
                      value={flatPrice}
                      onChange={onChange}
                      variant='outlined'
                      name='flatPrice'
                    />
                  )}
                </div>
              </div>
            </div>
          </Tab.Pane>
          <Tab.Pane attached={false}>
            <Message>Final Pricing</Message>
            <div className='payment-container'>
              <div className='payment-content'>
                <div className='payment-content-row'>
                  <div>
                    <h2>Calculation :</h2>
                  </div>
                  <div>
                    {flatTotal ? (
                      <>
                        <h2>{flatTotal} Per Photograph</h2>
                      </>
                    ) : (
                      <></>
                    )}
                    {stepCalculations ? (
                      <>
                        {stepCalculations.map((calc, i, stepCalculations) => (
                          <>
                            <div>
                              <h2>{calc}</h2>
                            </div>
                            {i + 1 === stepCalculations.length ? (
                              <></>
                            ) : (
                              <>
                                <div>&nbsp;</div>
                                <div>
                                  <h2>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    OR &nbsp;&nbsp;&nbsp;&nbsp;
                                  </h2>
                                </div>
                                <div>&nbsp;</div>
                              </>
                            )}
                          </>
                        ))}
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                {id ? (
                  <></>
                ) : (
                  <input type='submit' className='btn btn-primary my-1' />
                )}
              </div>
            </div>
          </Tab.Pane>
        </Fragment>
      </form>
    </div>
  )
}

export default CreateEditParticipantPayment
