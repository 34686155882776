import React from "react"
import { Button, Card, Icon } from "semantic-ui-react"
import { Link } from "react-router-dom"

const VerifyCustomerSearchCard = (event) => {
  return (
    <Card>
      <Card.Content>
        <Card.Header>Face Search Results</Card.Header>
        <Card.Meta>
          Verify the images identied against customer selfie
        </Card.Meta>
        <Icon.Group size="large">
          <Icon name="users" />
        </Icon.Group>
        <Card.Description>
          Verify the images identied against customer selfie
        </Card.Description>
      </Card.Content>
      <Card.Content extra>
        <div className="ui two buttons">
          <Button basic color="green">
            <Link
              to={`/event/${event.id}/search-results`}
              className="btn btn-light"
            >
              View Search Results
            </Link>
          </Button>
        </div>
      </Card.Content>
    </Card>
  )
}

export default VerifyCustomerSearchCard
