import React, { Fragment, useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Grid, Dropdown, Form } from "semantic-ui-react"
import Spinner from "../../shared/components/Spinner"
import ImagePaginator from "../../shared/components/pagination/ImagePaginator"
import {
  getEventUsers,
  getPhotographerImageUrls,
} from "../actions/eventActions"
import { Tab } from "semantic-ui-react"
import paginate from "../../shared/components/pagination/Paginate"

const ViewImages = () => {
  const { event, loading, eventUsers, photographerImageUrls } = useSelector(
    (state) => state.eventReducer
  )
  const dispatch = useDispatch()
  const [imageProps, setImageProps] = useState(null)
  const [photographers, setPhotographers] = useState(null)
  const [selectedPhotographer, setSeletectedPhotographer] = useState(null)
  const [selectedImageType, setSelectedImageType] = useState(null)
  const [selectedImageTypeValue, setSelectedImageTypeValue] = useState(null)
  const pageSize = 15

  const userRoles = [
    {
      key: 0,
      text: "ORIGINAL IMAGES",
      value: "raw_images",
    },
    {
      key: 1,
      text: "BRANDED IMAGES",
      value: "branded_images",
    },
    {
      key: 2,
      text: "CONVERTED IMAGES",
      value: "stripped_images",
    },
    {
      key: 3,
      text: "THUMBNAILS",
      value: "tnail_images",
    },
  ]

  useEffect(() => {
    dispatch(getEventUsers(event.id, "PHOTOGRAPHER"))
  }, [dispatch])

  useEffect(() => {
    if (eventUsers) {
      let eventUserOptions = []
      eventUserOptions.push({
        key: 100,
        text: "All",
        value: 0,
      })
      eventUsers.map((eventUser, index) => {
        eventUserOptions.push({
          key: index,
          text: eventUser.name,
          value: eventUser.id,
        })
      })
      setPhotographers(eventUserOptions)
    }
  }, [eventUsers])

  useEffect(() => {
    if (!photographerImageUrls) return
    setImageProps({
      loading: loading,
      paginatedData: paginate(photographerImageUrls, pageSize),
      pageSize: pageSize,
      pageHeader: selectedImageTypeValue,
      totalCount: photographerImageUrls.length,
    })
  }, [photographerImageUrls])

  useEffect(() => {
    if (selectedImageType) {
      setImageProps({
        loading: true,
        paginatedData: [],
        pageSize: 0,
        pageHeader: "",
        totalCount: 0,
      })
      dispatch(
        getPhotographerImageUrls(
          event.id,
          selectedImageType,
          selectedPhotographer
        )
      )
    } else {
      setImageProps({
        loading: loading,
        paginatedData: [],
        pageSize: 0,
        pageHeader: "",
        totalCount: 0,
      })
    }
  }, [dispatch, selectedImageType])

  const handleChange = (e, { name, value }) => {
    e.preventDefault()
    if (name === "photographer") {
      setSelectedImageType(null)
      setSeletectedPhotographer(value)
    } else {
      setSelectedImageType(value)
      setSelectedImageTypeValue(e.target.innerText)
    }
  }

  return (
    <Fragment>
      <h1 className="large text-primary">Images - {event.name}</h1>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <h5 className="medium text-primary"></h5>
          <Grid columns="equal">
            <Grid.Column>
              <Form>
                <Form.Field>
                  <label>Select Photographer</label>
                  <Dropdown
                    selection
                    name="photographer"
                    options={photographers}
                    placeholder="Select a Photographer"
                    onChange={handleChange}
                  />
                </Form.Field>
                <Form.Field>
                  <label>Select Image Type</label>
                  <Dropdown
                    selectOnNavigation={false}
                    selection
                    name="image-type"
                    options={userRoles}
                    placeholder="Select the Image Type"
                    onChange={handleChange}
                  />
                </Form.Field>
              </Form>
            </Grid.Column>
          </Grid>
          <Fragment>
            <Tab.Pane attached={false}>
              {imageProps ? <ImagePaginator {...imageProps} /> : <></>}
            </Tab.Pane>
          </Fragment>
        </>
      )}
    </Fragment>
  )
}

export default ViewImages
