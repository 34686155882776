import axios from "axios"
import { RNS3 } from "react-native-aws3"
import { setAlert } from "../../shared/actions/alert"
import { CLEAR_UPLOAD } from "../../shared/actions/types"
import { transportLogs } from "../../../api/logger"

import {
  GET_IDENTIFIED_FACES,
  FACE_ERROR,
  SET_SEARCH_IMAGE_RESULTS,
  CLEAR_SEARCH_PAGE,
  GET_VERIFIED_FACES,
  SET_HIDE_FORM_ID,
  SET_SEARCH_IMAGE,
  SET_SEARCH_IMAGE_NO_MATCHES,
  SET_ASSIGNED_PHOTOGRAPHERS,
  SET_ASSIGNED_PHOTOGRAPHS,
  CLEAR_ASSIGNED_PHOTOGRAPHERS,
  CLEAR_ASSIGNED_PHOTOGRAPHS,
  TAGGING_FILTER,
  SET_TAGGING_FILTER,
  SET_BIB_RESULTS,
  SET_TAGGING_STATISTICS,
  SET_SEARCH_RESULTS,
  CLEAR_SEARCH_RESULTS,
  UPDATE_SERACH_RESULTS,
  SET_LOADING,
  SET_LOCATION_TIME_RESULTS,
} from "./faceTypes"

const configHeader = {
  headers: { "Content-Type": "application/json" },
}

const multiPartHeader = {
  headers: {
    "Content-Type": "multipart/form-data",
  },
}

// Get All Profiles
export const getIdentifiedFaces = (eventId) => async (dispatch) => {
  if (eventId) {
    try {
      await axios
        .get(
          "/api/images/fr/identified-faces",
          {
            params: {
              event_id: eventId,
            },
          },
          configHeader
        )
        .then((response) => {
          dispatch({ type: GET_IDENTIFIED_FACES, payload: response.data })
        })
    } catch (err) {
      dispatch({
        type: FACE_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status },
      })
    }
  }
}
const config = {
  bucket: process.env.REACT_APP_BUCKET_NAME,
  keyPrefix: "",
  region: process.env.REACT_APP_REGION,
  accessKey: process.env.REACT_APP_ACCESS_ID,
  secretKey: process.env.REACT_APP_ACCESS_KEY,
  successActionStatus: 201,
  method: "PUT/POST", // default is POST
}

function handleUpload(file, event) {
  let ketPrefixBase
  return new Promise((resolve) => {
    try {
      ketPrefixBase = process.env.REACT_APP_SEARCH_FACE_DIR_NAME + event + "/"
      config.keyPrefix = ketPrefixBase
      console.log("Uploading... " + file.webkitRelativePath)
      RNS3.put(file, config).then((response) => {
        let data = null
        if (response.status !== 201) {
          console.log("Uploading Failed " + file.webkitRelativePath)
          console.log(file.webkitRelativePath + " ERROR!", "danger")
          data = {
            success: false,
            cloudFile: response.body.postResponse["key"],
            localFile: file.webkitRelativePath,
          }
        } else {
          console.log("Uploading Success " + file.webkitRelativePath)
          data = {
            success: true,
            cloudFile: response.body.postResponse["key"],
            localFile: file.webkitRelativePath,
          }
        }
        resolve(data)
      })
    } catch (error) {
      if (error) {
        //dispatch(transportLogs('Error Uploading... ' + JSON.stringify(error)))
      }
      console.log(error)
      resolve("ERROR")
    }
  })
}

export const getAssignedPhotographers =
  (eventId, userId) => async (dispatch) => {
    if (eventId) {
      try {
        await axios
          .get(
            "/api/images/tagging/users",
            {
              params: {
                event_id: eventId,
                user_id: userId,
              },
            },
            configHeader
          )
          .then((response) => {
            dispatch({
              type: SET_ASSIGNED_PHOTOGRAPHERS,
              payload: response.data.values,
            })
          })
      } catch (err) {
        dispatch({
          type: FACE_ERROR,
          payload: {
            msg: err.response.statusText,
            status: err.response.status,
          },
        })
      }
    }
  }

export const getImagesToTag =
  (eventId, photographerId, taggingFilter) => async (dispatch) => {
    dispatch({ type: SET_LOADING })
    if (eventId) {
      try {
        await axios
          .get(
            "/api/images/tagging",
            {
              params: {
                event_id: eventId,
                photographer_id: photographerId,
                image_filter: taggingFilter,
              },
            },
            configHeader
          )
          .then((response) => {
            dispatch({
              type: SET_ASSIGNED_PHOTOGRAPHS,
              payload: response.data.values,
            })
          })
      } catch (err) {
        dispatch({
          type: FACE_ERROR,
          payload: {
            msg: err.response.statusText,
            status: err.response.status,
          },
        })
      }
    }
  }

export const setImagesTaggingDetails =
  (imageId, bibs, isImageInvalid, eventId) => async (dispatch) => {
    const body = JSON.stringify({
      image_id: imageId,
      event_id: eventId,
      bib_numbers: bibs,
      is_image_invalid: isImageInvalid,
    })
    try {
      await axios
        .post("/api/images/tagging", body, configHeader)
        .then((response) => {})
    } catch (err) {
      dispatch({
        type: FACE_ERROR,
        payload: {
          msg: err.response.statusText,
          status: err.response.status,
        },
      })
    }
  }

export const setImageVerifiedFlag = (imageId) => async (dispatch) => {
  const body = JSON.stringify({
    image_id: imageId,
    is_verified: true,
  })
  try {
    await axios
      .post("/api/images/tagging/verified", body, configHeader)
      .then((response) => {
        console.log(" updated for " + imageId)
      })
  } catch (err) {
    dispatch({
      type: FACE_ERROR,
      payload: {
        msg: err.response.statusText,
        status: err.response.status,
      },
    })
  }
}

// Post User Event Mapping
export const sendSearchImage =
  (file, event, displayConverted, bibNumber) => async (dispatch) => {
    dispatch({ type: SET_SEARCH_IMAGE })
    try {
      let form_data = new FormData()
      form_data.append("file", file)
      form_data.append("event_id", event.id)
      form_data.append("is_display_converted", displayConverted)
      form_data.append("thumbnail", false)
      form_data.append("bib_number", bibNumber)
      await axios
        .post("/api/images/fr/search-faces", form_data, multiPartHeader)
        .then((response) => {
          dispatch({ type: SET_SEARCH_IMAGE_RESULTS, payload: response.data })
        })
      handleUpload(file, event.name).then(async (response) => {
        console.log("Selfie Uploaded...")
      })
    } catch (err) {
      dispatch({ type: SET_SEARCH_IMAGE_RESULTS, payload: null })
      dispatch({ type: SET_SEARCH_IMAGE_NO_MATCHES, payload: true })
      dispatch(setAlert(err.response.data.message, "danger"))
    }
  }
// export const sendSearchImage =
//   (file, event, displayConverted, bibNumber) => async (dispatch) => {
//     dispatch({ type: SET_SEARCH_IMAGE })
//     try {
//       handleUpload(file, event.name).then(async (response) => {
//         await axios
//           .get(
//             "/api/images/fr/search-faces",
//             {
//               params: {
//                 event_id: event.id,
//                 customer_images: response.cloudFile,
//                 is_display_converted: displayConverted,
//                 thumbnail: false,
//                 bib_number: bibNumber,
//               },
//             },
//             configHeader
//           )
//           .then((response) => {
//             dispatch({ type: SET_SEARCH_IMAGE_RESULTS, payload: response.data })
//           })
//       })
//     } catch (err) {
//       dispatch({ type: SET_SEARCH_IMAGE_RESULTS, payload: null })
//       dispatch({ type: SET_SEARCH_IMAGE_NO_MATCHES, payload: true })
//       dispatch(setAlert(err.response.data.message, "danger"))
//     }
//   }

// Post User Event Mapping
export const saveVerifiedImages =
  (bib, event_id, verified_images) => async (dispatch) => {
    try {
      const body = JSON.stringify({
        bibNumber: bib,
        eventId: event_id,
        imageList: verified_images,
      })
      await axios
        .post("/api/images/fr/identified-faces", body, configHeader)
        .then((response) => {
          dispatch(setAlert(bib + " updated for submitted images", "success"))
          dispatch({ type: CLEAR_SEARCH_PAGE, payload: null })
          dispatch({ type: CLEAR_UPLOAD, payload: null })
        })
    } catch (err) {
      dispatch({
        type: FACE_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status },
      })
    }
  }

export const cleanTaggedFaces = () => async (dispatch) => {
  dispatch({ type: CLEAR_ASSIGNED_PHOTOGRAPHERS })
  dispatch({ type: CLEAR_ASSIGNED_PHOTOGRAPHS })
}

export const setTaggingFilter = (FILTER_TYPE) => async (dispatch) => {
  dispatch({ type: SET_TAGGING_FILTER, payload: FILTER_TYPE })
}

export const saveIdentifiedImages =
  (bib, event_id, images, formId) => async (dispatch) => {
    try {
      const body = JSON.stringify({
        bibNumber: bib,
        eventId: event_id,
        imageList: images,
      })
      await axios
        .post("/api/images/fr/identified-faces", body, configHeader)
        .then((response) => {
          //console.log(response)
          dispatch(setAlert(bib + " updated for submitted images", "success"))
          dispatch({ type: SET_HIDE_FORM_ID, payload: formId })
          //dispatch({ type: CLEAR_UPLOAD, payload: null })
          // Clear from the list
        })
    } catch (err) {
      dispatch({
        type: FACE_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status },
      })
    }
  }

export const searchBibImages = (bibNumber, eventId) => async (dispatch) => {
  if (bibNumber) {
    try {
      dispatch({ type: SET_BIB_RESULTS, payload: null })
      dispatch({ type: SET_LOADING, payload: true })

      await axios
        .get(
          "/api/images/tagging/search",
          {
            params: {
              bib: bibNumber,
              event_id: eventId,
              thumbnail: false,
            },
          },
          configHeader
        )
        .then((response) => {
          dispatch({ type: SET_LOADING, payload: false })
          dispatch({ type: SET_BIB_RESULTS, payload: response.data })
        })
    } catch (err) {
      dispatch({ type: SET_LOADING, payload: false })
      dispatch({
        type: FACE_ERROR,
        payload: {
          msg: err.response.statusText,
          status: err.response.status,
        },
      })
    }
  }
}

export const searchImagesOnLocationDate =
  (location, fromDate, toDate, eventId) => async (dispatch) => {
    try {
      await axios
        .get(
          "/api/images/location-date/search",
          {
            params: {
              location: location,
              from_date: fromDate,
              to_date: toDate,
              event_id: eventId,
              thumbnail: false,
            },
          },
          configHeader
        )
        .then((response) => {
          dispatch({ type: SET_LOCATION_TIME_RESULTS, payload: response.data })
        })
    } catch (err) {
      dispatch({
        type: FACE_ERROR,
        payload: {
          msg: err.response.statusText,
          status: err.response.status,
        },
      })
    }
  }

export const getTaggingStats =
  (eventId, userId, is_photographer) => async (dispatch) => {
    if (eventId) {
      try {
        dispatch({ type: SET_SEARCH_IMAGE })
        await axios
          .get(
            "/api/images/tagging/stats",
            {
              params: {
                event_id: eventId,
                user_id: userId,
                is_photographer: is_photographer,
              },
            },
            configHeader
          )
          .then((response) => {
            dispatch({ type: SET_TAGGING_STATISTICS, payload: response.data })
          })
      } catch (err) {
        dispatch({
          type: FACE_ERROR,
          payload: {
            msg: err.response.statusText,
            status: err.response.status,
          },
        })
      }
    }
  }

export const getVerifiedFaces = (eventId) => async (dispatch) => {
  if (eventId) {
    try {
      await axios
        .get(
          "/api/images/fr/verified-faces",
          {
            params: {
              event_id: eventId,
            },
          },
          configHeader
        )
        .then((response) => {
          dispatch({ type: GET_VERIFIED_FACES, payload: response.data })
        })
    } catch (err) {
      dispatch({
        type: FACE_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status },
      })
    }
  }
}

export const getSearchResults = (eventId) => async (dispatch) => {
  if (eventId) {
    try {
      dispatch({ type: SET_LOADING, payload: true })
      await axios
        .get(
          "/api/images/fr/search-results",
          {
            params: {
              event_id: eventId,
            },
          },
          configHeader
        )
        .then((response) => {
          dispatch({ type: SET_SEARCH_RESULTS, payload: response.data })
        })
    } catch (err) {
      dispatch({
        type: FACE_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status },
      })
    }
  }
}

export const cleanSearchResults = () => async (dispatch) => {
  dispatch({ type: CLEAR_SEARCH_RESULTS })
}

export const saveSearchResults =
  (participantId, fields, eventId) => async (dispatch) => {
    try {
      const body = JSON.stringify({
        participant_id: participantId,
        event_id: eventId,
        image_status: fields,
      })
      await axios
        .put("/api/images/fr/search-results", body, configHeader)
        .then((response) => {
          dispatch({
            type: UPDATE_SERACH_RESULTS,
            payload: response.data["participant_id"],
          })
        })
    } catch (err) {
      dispatch({
        type: FACE_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status },
      })
    }
  }
