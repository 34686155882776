import React, { useState, Fragment, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import Spinner from "../../shared/components/Spinner"
import "./GenericBrandingImages.css"
import BrandingCard from "./branding/BrandingCard"
import { RuleConfigurator } from "./branding/RuleConfigurator"
import { AddRules } from "./branding/AddRules"
import {
  BRANDING_IMAGES,
  SET_ALL_BRANDING_CATEGORIES,
  SET_BRANDING_RULES,
  SET_BRANDING_RULE_CATEGORIES,
  SET_RULES_NUMBERS,
} from "../actions/eventTypes"
import {
  createBrandingRuleComponents,
  deleteBrandingRule,
  deleteBrandingRuleCategories,
  getAllBrandingCategories,
  getBrandingImages,
  getBrandingRuleCategories,
  getBrandingRules,
} from "../actions/eventActions"

const findMissing = (num) => {
  const missing = []
  if (num.length > 0) {
    const max = Math.max(...num) // Will find highest number
    const min = 1 //1 Will be the lowest number

    for (let i = min; i <= max; i++) {
      if (!num.includes(i)) {
        // Checking whether i(current value) present in num(argument)
        missing.push(i) // Adding numbers which are not in num(argument) array
      }
    }
  }
  return missing
}

export const initDynamicBranding = (dispatch, event) => {
  dispatch({
    type: SET_BRANDING_RULES,
    payload: null,
  })

  dispatch({
    type: SET_ALL_BRANDING_CATEGORIES,
    payload: null,
  })

  dispatch({
    type: SET_BRANDING_RULE_CATEGORIES,
    payload: null,
  })

  dispatch({
    type: BRANDING_IMAGES,
    payload: null,
  })
  dispatch(getAllBrandingCategories(event.id))
  dispatch(getBrandingRuleCategories(event.id))
  dispatch(getBrandingImages(event.id))
  dispatch(getBrandingRules(event.id))
}

const DynamicBranding = () => {
  const {
    event,
    loading,
    brandingRules,
    brandingImages,
    allBrandingCategories,
    brandingRuleCategories,
  } = useSelector((state) => state.eventReducer)

  const dispatch = useDispatch()
  const [brandingRuleObjects, setBrandingRuleObjects] = useState(null)
  const [ruleNumbers, setRuleNumbers] = useState([])

  useEffect(() => {
    initDynamicBranding(dispatch, event)
  }, [dispatch, event])

  useEffect(() => {
    if (
      brandingRules &&
      allBrandingCategories &&
      brandingRuleCategories &&
      brandingImages
    ) {
      let branding_rules = []
      let rule_numbers = []

      brandingRules.map((rule) => {
        const rule_name = rule.rule_name
        rule_numbers.push(parseInt(rule_name.split(" ")[1]))

        const event_rule_category = brandingRuleCategories.filter(function (
          entry
        ) {
          return entry.rule_name === rule_name
        })

        var rule_category = event_rule_category.map(function (entry) {
          return entry.category
        })

        const valid_branding_images = brandingImages.filter(function (entry) {
          return entry.rule_name != null
        })

        const branding_images = valid_branding_images.filter(function (entry) {
          return entry.rule_name === rule_name
        })

        let value = {}
        value["rule_name"] = rule_name
        value["rule_category"] = rule_category
        if (branding_images.length > 0) {
          value["portrait_image"] = branding_images[0]["portrait_image"]
          value["landscape_image"] = branding_images[0]["landscape_image"]
        } else {
          value["portrait_image"] = ""
          value["landscape_image"] = ""
        }
        branding_rules.push(value)
      })
      setRuleNumbers(rule_numbers)
      setBrandingRuleObjects(branding_rules)
    }
  }, [
    brandingRules,
    allBrandingCategories,
    brandingRuleCategories,
    brandingImages,
    dispatch,
  ])

  const handleAddRule = () => {
    const num = [...ruleNumbers]
    const missing = findMissing(num)
    let cardId
    if (missing.length > 0) {
      cardId = missing[0]
    } else {
      cardId = num.length + 1
    }
    num.push(cardId)
    setRuleNumbers(num)
  }

  const handleRemoveRule = async (ruleNum) => {
    let ruleNumberVals = [...ruleNumbers]
    var index = ruleNumberVals.indexOf(ruleNum)
    if (index !== -1) {
      ruleNumberVals.splice(index, 1)
    }
    const event_rule_category = brandingRuleCategories.filter(function (entry) {
      return entry.rule_name === "Rule " + ruleNum
    })

    var rule_category = event_rule_category.map(function (entry) {
      return entry.category
    })
    var promises = rule_category.map(async (category) => {
      return new Promise((resolve) => {
        resolve(
          dispatch(
            deleteBrandingRuleCategories(event.id, category, "Rule " + ruleNum)
          )
        )
      })
    })
    await Promise.all(promises)
    dispatch(deleteBrandingRule(event.id, "Rule " + ruleNum))
    setRuleNumbers(ruleNumberVals)
    initDynamicBranding(dispatch, event)
  }

  const createBrandingRule = (
    ruleNum,
    categories,
    landscapeFile,
    portraitFile
  ) => {
    dispatch(
      createBrandingRuleComponents(
        "Rule " + ruleNum,
        categories,
        landscapeFile,
        portraitFile,
        event
      )
    ).then((resp) => {
      initDynamicBranding(dispatch, event)
    })
  }

  return (
    <Fragment>
      {loading ? (
        <Spinner />
      ) : (
        <Fragment>
          <h1 className="large text-primary">
            Dynamic Branding - {event.name}
          </h1>
          <br></br>
          <div className="ui segments">
            <div className="ui segment">
              <h4>Configure Dynamic Branding Rule Engine</h4>
            </div>
            <div className="ui segments">
              <div
                className="ui horizontal segments"
                style={{ position: "relative", width: "100%" }}
              >
                <RuleConfigurator />
                <div className="ui segment" style={{ width: "8%" }}>
                  <AddRules handleAddRule={handleAddRule} />
                </div>
              </div>
            </div>
            <div className="ui segment">
              <h4>Build Dynamic Branding Rules</h4>
              <div className="ui link cards">
                {ruleNumbers.length > 0 ? (
                  ruleNumbers.map((ruleNumber) => (
                    <BrandingCard
                      ruleNumber={ruleNumber}
                      brandingRules={brandingRuleObjects}
                      allBrandingCategories={allBrandingCategories}
                      handleRemoveRule={handleRemoveRule}
                      createBrandingRule={createBrandingRule}
                    />
                  ))
                ) : (
                  <div
                    className="card"
                    style={{ width: "1800px", height: "435px" }}
                  ></div>
                )}
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  )
}

export default DynamicBranding
