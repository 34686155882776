import React, { useState, useEffect } from "react"
import { Button, Card, Icon, Message, Tab, Confirm } from "semantic-ui-react"
import { Link } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import {
  deleteEvent,
  getEventTypes,
  getEvents,
  getEventsByUser,
} from "../../actions/eventActions"
import { getParticipantPaymentModules } from "../../../payments/actions/paymentActions"

const EventsCard = () => {
  const { events } = useSelector((state) => state.eventReducer)
  const auth = useSelector((state) => state.auth)
  const { role, user } = auth
  const dispatch = useDispatch()
  const [modelState, setModelState] = useState({ open: false })
  const [selectedEventId, setSelectedEventId] = useState(null)

  useEffect(() => {
    if (user) {
      dispatch(getEventTypes())
      if (role === "ADMIN") {
        dispatch(getEvents())
      } else {
        dispatch(getEventsByUser(user.id))
      }
    } else {
      dispatch(getEvents())
    }
    dispatch(getParticipantPaymentModules())
  }, [dispatch, user])

  const show = (eventId) => {
    setSelectedEventId(eventId)
    setModelState({ open: true })
  }
  const handleConfirm = () => {
    dispatch(deleteEvent(selectedEventId))
    setModelState({ open: false })
  }
  const handleCancel = () => {
    setModelState({ open: false })
  }

  return (
    <div>
      {role === "ADMIN" ? (
        <Tab.Pane attached={false}>
          <Message>Create Events</Message>
          <Card.Group>
            <Card>
              <Card.Content>
                <Card.Header>Create Event</Card.Header>
                <Card.Meta>Create a new event</Card.Meta>
                <Icon.Group size="large">
                  <Icon name="users" />
                </Icon.Group>
                <Card.Description>&nbsp;</Card.Description>
                <Card.Description>&nbsp;</Card.Description>
                <Card.Description>&nbsp;</Card.Description>
                <Card.Description>&nbsp;</Card.Description>
              </Card.Content>
              <Card.Content extra>
                <div className="ui two buttons">
                  <Button basic color="green">
                    <Link to="/create-event" className="btn btn-light">
                      Create Event
                    </Link>
                  </Button>
                </div>
              </Card.Content>
            </Card>
          </Card.Group>
        </Tab.Pane>
      ) : (
        <></>
      )}
      {
        <Tab.Pane attached={false}>
          <Message>Active Events</Message>
          <Confirm
            open={modelState.open}
            content="Do you want to delete the event? It cannot be reversed."
            onCancel={handleCancel}
            onConfirm={handleConfirm}
          />
          <Card.Group>
            {events.length > 0 ? (
              events.map((event) => (
                <Card
                  key={event.id}
                  style={
                    event.es_index === false &&
                    event.face_recognition_model === "DEEPFACE"
                      ? { backgroundColor: "#a4343a" }
                      : {}
                  }
                >
                  <Card.Content>
                    <Card.Header>{event.name}</Card.Header>
                    <Card.Meta>Event Type: {event.type}</Card.Meta>
                    <Icon.Group size="large">
                      <Icon name="users" />
                    </Icon.Group>
                    <Card.Description>
                      <div>Location: {event.location}</div>
                      <div>Start Date: {event.from_date}</div>
                      <div>End Date: {event.to_date}</div>
                      <div>Active: {event.is_active.toString()}</div>
                    </Card.Description>
                  </Card.Content>
                  <Card.Content extra>
                    <div
                      className="ui basic buttons"
                      style={{ textAlign: "center" }}
                    >
                      <div className="ui button" style={{ flex: 1 }}>
                        <Link to={`/event/${event.id}/manage`}>
                          Manage Event
                        </Link>
                      </div>
                      {role === "ADMIN" ? (
                        <>
                          <div className="ui button" style={{ flex: 1 }}>
                            <Link to={`/event/${event.id}`}>Edit Event</Link>
                          </div>

                          <div
                            className="ui button"
                            style={{ flex: 1 }}
                            onClick={(e) => show(event.id)}
                          >
                            Delete Event
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </Card.Content>
                </Card>
              ))
            ) : (
              <div>NO EVENTS FOUND</div>
            )}
          </Card.Group>
        </Tab.Pane>
      }
    </div>
  )
}

export default EventsCard
