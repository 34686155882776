import React from 'react'
import { Button, Card, Icon } from 'semantic-ui-react'
import { Link } from 'react-router-dom'

const EventCard = () => {
  return (
    <Card>
      <Card.Content>
        <Card.Header>Event Management</Card.Header>
        <Card.Meta>Event Management</Card.Meta>
        <Icon.Group size='large'>
          <Icon name='puzzle' />
          <Icon corner name='add' />
        </Icon.Group>
        <Card.Description>Helps admin configure events</Card.Description>
      </Card.Content>
      <Card.Content extra>
        <div className='ui two buttons'>
          <Button basic color='green'>
            <Link to='/events' className='btn btn-light'>
              Manage Events
            </Link>
          </Button>
        </div>
      </Card.Content>
    </Card>
  )
}

export default EventCard
