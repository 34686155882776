import React from "react"
import { Icon } from "semantic-ui-react"

export const AddRules = ({ handleAddRule }) => {
  return (
    <div
      className="column"
      style={{
        textAlign: "center",
      }}
    >
      <div
        style={{
          display: "inline-block",
        }}
      >
        <div>&nbsp;</div>
        <div
          style={{ width: "70px" }}
          onClick={handleAddRule}
          onMouseOver={({ target }) => (target.style.color = "green")}
          onMouseOut={({ target }) => (target.style.color = "black")}
        >
          <Icon name="plus square outline" size="huge" />
        </div>
        <div>
          <div style={{ fontSize: 15 }}>ADD RULE</div>
          <div style={{ fontSize: 15 }}>BUILDER</div>
        </div>
      </div>
      <br></br>
      <div></div>
    </div>
  )
}
