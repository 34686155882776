import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'

export const UserRoute = ({ component: Component, ...rest }) => {
  const auth = useSelector((state) => state.auth)
  const { loading, isAuthenticated } = auth

  return (
    <Route
      {...rest}
      render={(props) =>
        !isAuthenticated && !loading ? (
          <Redirect to='/login' />
        ) : (
          <Component {...props} />
        )
      }
    />
  )
}

export const AdminRoute = ({ component: Component, ...rest }) => {
  const auth = useSelector((state) => state.auth)
  const { loading, isAuthenticated, user, role } = auth

  let isAdmin = false
  if (role.length > 0) {
    if (role == 'ADMIN') {
      isAdmin = true
    }
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        !isAuthenticated && !loading ? (
          <Redirect to='/login' />
        ) : isAdmin ? (
          <Component {...props} />
        ) : (
          <Redirect to='/login' />
        )
      }
    />
  )
}
