import React from 'react'
import { Button, Card, Icon } from 'semantic-ui-react'
import { Link } from 'react-router-dom'

const ImageFaceSearchCard = (event) => {
  return (
    <Card>
      <Card.Content>
        <Card.Header>Face Search</Card.Header>
        <Card.Meta>Search matching images from existing encodings</Card.Meta>
        <Icon.Group size='large'>
          <Icon name='users' />
        </Icon.Group>
        <Card.Description>
          Helps admin find matching images from encoded images
        </Card.Description>
      </Card.Content>
      <Card.Content extra>
        <div className='ui two buttons'>
          <Button basic color='green'>
            <Link
              to={`/event/${event.id}/search-images`}
              className='btn btn-light'>
              Search Images
            </Link>
          </Button>
        </div>
      </Card.Content>
    </Card>
  )
}

export default ImageFaceSearchCard
