import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import FileUpload from "react-material-file-upload"
import Select from "react-select"
import { Button, Icon, Label } from "semantic-ui-react"

import { createBrandingRuleComponents } from "../../actions/eventActions"
import { setAlert } from "../../../shared/actions/alert"

const BrandingCard = ({
  ruleNumber,
  brandingRules,
  allBrandingCategories,
  handleRemoveRule,
  createBrandingRule,
}) => {
  const { loading, event } = useSelector((state) => state.eventReducer)
  const [landscapeFile, setLandscapeFile] = useState([])
  const [portraitFile, setPortraitFile] = useState([])
  const [landscapeImage, setLandscapeImage] = useState({})
  const [portraitImage, setPortraitImage] = useState({})
  const [selectedItems, setSelectedItems] = useState([])
  const [categoryOptions, setCategoryOptions] = useState(null)

  const dispatch = useDispatch()

  useEffect(() => {
    if (brandingRules && allBrandingCategories) {
      const branding_rule = brandingRules.filter(function (entry) {
        return entry.rule_name === "Rule " + ruleNumber
      })
      if (branding_rule.length > 0) {
        let rule_categories = []
        branding_rule[0].rule_category.map((option) => {
          rule_categories.push({
            value: option.replace(/\s/g, "").replace(/[^a-zA-Z ]/g, ""),
            label: option,
          })
        })
        setSelectedItems(rule_categories)
        setPortraitImage(branding_rule[0].portrait_image)
        setLandscapeImage(branding_rule[0].landscape_image)
      }

      let branding_categories = []
      allBrandingCategories.map((option) => {
        branding_categories.push({
          value: (option.filter + " : " + option.value)
            .replace(/\s/g, "")
            .replace(/[^a-zA-Z ]/g, ""),
          label: option.filter + " : " + option.value,
        })
      })
      setCategoryOptions(branding_categories)
    }
  }, [
    brandingRules,
    ruleNumber,
    allBrandingCategories,
    setSelectedItems,
    setPortraitImage,
    setLandscapeImage,
    setCategoryOptions,
  ])

  const onChange = (event, data) => {
    let items = [...selectedItems]
    items.push(data.option)
    setSelectedItems(items)
  }

  const onPortraitChange = (file) => {
    setPortraitFile(file)
  }

  const onLandscapeChange = (file) => {
    setLandscapeFile(file)
  }

  const onSave = (ruleNumber) => {
    if (selectedItems.length === 0) {
      dispatch(
        setAlert(
          "Choose atleast one Branding Rule for Rule " + ruleNumber,
          "danger"
        )
      )
      return
    }
    if (landscapeFile.length === 0) {
      dispatch(
        setAlert("Choose Landscape Image for Rule " + ruleNumber, "danger")
      )
      return
    }
    if (portraitFile.length === 0) {
      dispatch(
        setAlert("Choose Portrait Image for Rule " + ruleNumber, "danger")
      )
      return
    }
    createBrandingRule(ruleNumber, selectedItems, landscapeFile, portraitFile)
  }

  return (
    <>
      {categoryOptions ? (
        <>
          <div className="card" style={{ width: "400px", height: "465px" }}>
            <div className="content">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <h4 className="header">Rule {ruleNumber}</h4>
                <Icon
                  name="close"
                  color="black"
                  size="large"
                  id={ruleNumber}
                  onClick={() => handleRemoveRule(ruleNumber)}
                />
              </div>
              <div> Choose Dynamic Branding Rules </div>
              <div style={{ width: "370px", height: "80px" }}>
                <Select
                  defaultValue={selectedItems}
                  isMulti
                  name="brandingRule"
                  options={categoryOptions}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={onChange}
                />
              </div>
              <br></br>
              <br></br>
              <div
                style={{
                  display: "flex",
                }}
              >
                <div
                  style={{
                    width: "180px",
                  }}
                >
                  <FileUpload
                    value={portraitFile}
                    onChange={onPortraitChange}
                    multiple={false}
                    rightLabel="to select files"
                    buttonLabel="click here"
                    buttonRemoveLabel="Remove all"
                    maxFileSize={10}
                    maxUploadFiles={1}
                    bannerProps={{ elevation: 0, variant: "outlined" }}
                    containerProps={{ elevation: 0, variant: "outlined" }}
                    title={"Drag & Drop Portrait Image"}
                    accept=".png"
                  />
                </div>
                <div
                  style={{
                    width: "10px",
                  }}
                ></div>
                <div
                  style={{
                    width: "180px",
                  }}
                >
                  <FileUpload
                    value={landscapeFile}
                    onChange={onLandscapeChange}
                    multiple={false}
                    rightLabel="to select files"
                    buttonLabel="click here"
                    buttonRemoveLabel="Remove all"
                    maxFileSize={10}
                    maxUploadFiles={1}
                    bannerProps={{ elevation: 0, variant: "outlined" }}
                    containerProps={{ elevation: 0, variant: "outlined" }}
                    title={"Drag & Drop Landscape Image"}
                    accept=".png"
                  />
                </div>
              </div>

              <div style={{ display: "flex" }}>
                {portraitImage.length > 0 ? (
                  <div style={{ width: "180px", margin: "0 auto" }}>
                    <Label image>
                      <Icon name="image" />
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Portrait Image
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <Icon name="delete" />
                    </Label>
                  </div>
                ) : (
                  <></>
                )}
                <div
                  style={{
                    width: "10px",
                  }}
                ></div>

                {landscapeImage.length > 0 ? (
                  <div style={{ width: "180px", margin: "0 auto" }}>
                    <Label image>
                      <Icon name="image" />
                      &nbsp;&nbsp;&nbsp;&nbsp;Landscape Image &nbsp;&nbsp;&nbsp;
                      <Icon name="delete" />
                    </Label>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
            <div className="extra content">
              <div className="right floated" id={ruleNumber}>
                <Button
                  id={ruleNumber}
                  color="blue"
                  size="mini"
                  onClick={() => onSave(ruleNumber)}
                >
                  SAVE
                </Button>
              </div>
              {landscapeFile.length > 0 && portraitFile.length > 0 ? (
                <div>
                  <Icon name="save" color="red" size="large" />
                  Unsaved changes
                </div>
              ) : (
                <div></div>
              )}
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  )
}

export default BrandingCard
