import React from "react"
import { Button, Card, Icon } from "semantic-ui-react"
import { Link } from "react-router-dom"

const SeederCard = (event) => {
  return (
    <Card>
      <Card.Content>
        <Card.Header>Bulk Upload / Update Participants</Card.Header>
        <Card.Meta>Seed Participant Information</Card.Meta>
        <Icon.Group size="large">
          <Icon name="users" />
        </Icon.Group>
        <Card.Description>
          Upload particpant information from csv file
        </Card.Description>
      </Card.Content>
      <Card.Content extra>
        <div className="ui two buttons">
          <Button basic color="green">
            <Link
              to={`/event/${event.id}/seed-participants`}
              className="btn btn-light"
            >
              Seed Participants
            </Link>
          </Button>
        </div>
      </Card.Content>
    </Card>
  )
}

export default SeederCard
