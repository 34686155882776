import React, { useState, useEffect, Fragment } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { createProfile, getCurrentProfile } from '../../actions/profile'

const EditProfile = ({ history }) => {
  const profile = useSelector((state) => state.profile.profile)

  // const user = useSelector((state) => state.auth.user)
  // let isAdmin = false
  // if (user != null) {
  //   isAdmin = user['is_admin']
  // }

  const [formData, setFormData] = useState({
    company: '',
    website: '',
    location: '',
    status: '',
    skills: '',
    githubusername: '',
    bio: '',
    twitter: '',
    facebook: '',
    linkedin: '',
    youtube: '',
    instagram: '',
  })
  const [displaySocialInputs, toggleSocialInputs] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    getCurrentProfile()
    setFormData({ ...profile })
  }, [profile])

  const {
    company,
    // website,
    location,
    status,
    // skills,
    // githubusername,
    // bio,
    twitter,
    facebook,
    linkedin,
    youtube,
    instagram,
  } = formData

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const onSubmit = (e) => {
    e.preventDefault()
    dispatch(createProfile(formData, history, true))
  }

  return (
    <Fragment>
      <h1 className='large text-primary'>Update Your Profile</h1>
      <p className='lead'>
        <i className='fas fa-user'></i> Let's update your info..
      </p>
      <small>* required field</small>
      <form className='form' onSubmit={onSubmit}>
        <div className='form-group'>
          <select name='status' value={status} onChange={onChange}>
            <option value='0'>* Select Professional Status</option>
            <option value='Developer'>Professional Photographer</option>
            <option value='Junior Developer'>Freelance Photographer</option>
            <option value='Senior Developer'>Lead Photographer</option>
            <option value='Manager'>Junior Photographer</option>
            <option value='Other'>Other</option>
          </select>
          <small className='form-text'>
            Give us an idea of where you are at in your career
          </small>
        </div>
        <div className='form-group'>
          <input
            type='text'
            placeholder='Company'
            name='company'
            value={company}
            onChange={onChange}
          />
          <small className='form-text'>
            Could be your own company or one you work for
          </small>
        </div>
        {/* <div className='form-group'>
          <input
            type='text'
            placeholder='Website'
            name='website'
            value={website}
            onChange={onChange}
          />
          <small className='form-text'>
            Could be your own or a company website
          </small>
        </div> */}
        <div className='form-group'>
          <input
            type='text'
            placeholder='Location'
            name='location'
            value={location}
            onChange={onChange}
          />
          <small className='form-text'>
            City & state suggested (eg. Bangalore, Delhi)
          </small>
        </div>
        {/* <div className='form-group'>
          <input
            type='text'
            placeholder='* Skills'
            name='skills'
            value={skills}
            onChange={onChange}
          />
          <small className='form-text'>
            Please use comma separated values (eg. HTML,CSS,JavaScript,PHP)
          </small>
        </div> */}
        {/* <div className='form-group'>
          <input
            type='text'
            placeholder='* Github Username'
            name='githubusername'
            value={githubusername}
            onChange={onChange}
          />
          <small className='form-text'>
            If you want your latest repos and a Github link, include your
            username
          </small>
        </div>
        <div className='form-group'>
          <textarea
            placeholder='* A short bio of yourself'
            name='bio'
            value={bio}
            onChange={onChange}></textarea>
          <small className='form-text'>Tell us a little about yourself</small>
        </div> */}

        <div className='my-2'>
          <button
            onClick={() => toggleSocialInputs(!displaySocialInputs)}
            type='button'
            className='btn btn-light'>
            Add Social Network Links
          </button>
          <span>Optional</span>
        </div>

        {displaySocialInputs && (
          <Fragment>
            <div className='form-group social-input'>
              <i className='fab fa-twitter fa-2x'></i>
              <input
                type='text'
                placeholder='Twitter URL'
                name='twitter'
                value={twitter}
                onChange={onChange}
              />
            </div>

            <div className='form-group social-input'>
              <i className='fab fa-facebook fa-2x'></i>
              <input
                type='text'
                placeholder='Facebook URL'
                name='facebook'
                value={facebook}
                onChange={onChange}
              />
            </div>

            <div className='form-group social-input'>
              <i className='fab fa-youtube fa-2x'></i>
              <input
                type='text'
                placeholder='YouTube URL'
                name='youtube'
                value={youtube}
                onChange={onChange}
              />
            </div>

            <div className='form-group social-input'>
              <i className='fab fa-linkedin fa-2x'></i>
              <input
                type='text'
                placeholder='Linkedin URL'
                name='linkedin'
                value={linkedin}
                onChange={onChange}
              />
            </div>

            <div className='form-group social-input'>
              <i className='fab fa-instagram fa-2x'></i>
              <input
                type='text'
                placeholder='Instagram URL'
                name='instagram'
                value={instagram}
                onChange={onChange}
              />
            </div>
          </Fragment>
        )}
        <input type='submit' className='btn btn-primary my-1' />
        <Link className='btn btn-light my-1' to='/dashboard'>
          Go Back
        </Link>
        {/* {isAdmin ? (
          <Link className='btn btn-light my-1' to='/admin/dashboard'>
            Go Back
          </Link>
        ) : (
          <Link className='btn btn-light my-1' to='/user/dashboard'>
            Go Back
          </Link>
        )} */}
      </form>
    </Fragment>
  )
}

export default withRouter(EditProfile)
