import React from 'react'
import { Button, Card, Icon } from 'semantic-ui-react'
import { Link } from 'react-router-dom'

const BrandingMasterCard = (event) => {
  return (
    <Card>
      <Card.Content>
        <Card.Header>Configure Branding</Card.Header>
        <Card.Meta>Configure Branding</Card.Meta>
        <Icon.Group size='large'>
          <Icon name='users' />
        </Icon.Group>
        <Card.Description>
          Configure Portrait and Landscape Branding Images
        </Card.Description>
      </Card.Content>
      <Card.Content extra>
        <div className='ui two buttons'>
          <Button basic color='green'>
            <Link
              to={`/event/${event.id}/branding-home`}
              className='btn btn-light'>
              Configure
            </Link>
          </Button>
        </div>
      </Card.Content>
    </Card>
  )
}

export default BrandingMasterCard
