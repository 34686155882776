import React, { Fragment, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import Spinner from '../../../shared/components/Spinner'
import ProfileTop from './ProfileTop'
import ProfileAbout from './ProfileAbout'
// import ProfileExperience from './ProfileExperience'
// import ProfileEducation from './ProfileEducation'
// import ProfileGithub from './ProfileGithub'
import { getProfileById } from '../../actions/profile'

const Profile = (props) => {
  const {
    profile: { profile, loading },
    auth,
  } = useSelector((state) => state)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getProfileById(props.match.params.id))
  }, [dispatch, props.match.params.id])

  return (
    <Fragment>
      {profile === null || loading ? (
        <Spinner />
      ) : (
        <Fragment>
          <Link to='/profiles' className='btn btn-light'>
            Back To Profiles
          </Link>
          {auth.isAuthenticated &&
            auth.loading === false &&
            auth.user.id === profile.user.id && (
              <Link to='/edit-profile' className='btn btn-dark'>
                Edit Profile
              </Link>
            )}
          <div className='profile-grid my-1'>
            <ProfileTop profile={profile} />
            <ProfileAbout profile={profile} />
            {/* <div className='profile-exp bg-white p-2'>
              <h2 className='text-primary'>Experience</h2>
              {profile.experience.length > 0 ? (
                <Fragment>
                  {profile.experience.map((experience) => (
                    <ProfileExperience
                      key={experience.id}
                      experience={experience}
                    />
                  ))}
                </Fragment>
              ) : (
                <h4>No Experience credentials</h4>
              )}
            </div> */}
            {/* <div className='profile-edu bg-white p-2'>
              <h2 className='text-primary'>Education</h2>
              {profile.education.length > 0 ? (
                <Fragment>
                  {profile.education.map((education) => (
                    <ProfileEducation
                      key={education.id}
                      education={education}
                    />
                  ))}
                </Fragment>
              ) : (
                <h4>No Education credentials</h4>
              )}
            </div> */}

            {/* {profile.githubusername && (
              <ProfileGithub username={profile.githubusername} />
            )} */}
          </div>
        </Fragment>
      )}
    </Fragment>
  )
}

export default Profile
