import React, { useEffect, Fragment } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { Button, Card, Icon, Message, Tab } from 'semantic-ui-react'
import { getParticipantPayment } from '../actions/paymentActions'

const ParticipantPayments = () => {
  const { loading, participantPayments } = useSelector(
    (state) => state.paymentReducer
  )

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getParticipantPayment())
  }, [dispatch])

  return (
    <div>
      <Fragment>
        <h1 className='large text-primary'>Participant Payments</h1>
        <Tab.Pane attached={false}>
          <Message>Create and configure payment modules</Message>
          <Card.Group>
            <Card>
              <Card.Content>
                <Card.Header>Create Payment Module</Card.Header>
                <Card.Meta>Create Payment Module</Card.Meta>
                <Icon.Group size='large'>
                  <Icon name='users' />
                </Icon.Group>
                <Card.Description>&nbsp;</Card.Description>
                <Card.Description>&nbsp;</Card.Description>
                <Card.Description>&nbsp;</Card.Description>
                <Card.Description>&nbsp;</Card.Description>
              </Card.Content>
              <Card.Content extra>
                <div className='ui two buttons'>
                  <Button basic color='green'>
                    <Link
                      to='/payments/participant/create'
                      className='btn btn-light'>
                      Create Payment Module
                    </Link>
                  </Button>
                </div>
              </Card.Content>
            </Card>
          </Card.Group>
        </Tab.Pane>

        <Tab.Pane attached={false}>
          <Message>Available payment modules</Message>
          <Card.Group>
            {participantPayments ? (
              participantPayments.map((participantPaymentModule) =>
                Object.keys(participantPaymentModule).map((item, index) => (
                  <Card key={index}>
                    <Card.Content>
                      <Card.Header>
                        {participantPaymentModule[item]['name']}
                      </Card.Header>
                      <Card.Meta>
                        Description{' '}
                        {participantPaymentModule[item]['description']}
                      </Card.Meta>
                      <Icon.Group size='large'>
                        <Icon name='users' />
                      </Icon.Group>
                      <Card.Description></Card.Description>
                    </Card.Content>
                    <Card.Content extra>
                      <div className='ui two buttons'>
                        <Button basic color='green'>
                          <Link
                            to={`/payments/participant/${item}/view`}
                            className='btn btn-light'>
                            View Payment Module
                          </Link>
                        </Button>
                      </div>
                    </Card.Content>
                  </Card>
                ))
              )
            ) : (
              <div>NO EVENTS FOUND</div>
            )}
          </Card.Group>
        </Tab.Pane>
      </Fragment>
    </div>
  )
}

export default ParticipantPayments
