import React, { useState, useEffect, Fragment } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Table } from "semantic-ui-react"
import ReadOnlyRow from "./ReadOnlyRow"
import EditableRow from "./EditableRow"
import {
  createParticipant,
  deleteParticipant,
  getAllEventParticipants,
  updateParticipant,
} from "../actions/participantActions"

const CreateEditParticipants = () => {
  const { event } = useSelector((state) => state.eventReducer)
  const { participants, loading } = useSelector(
    (state) => state.participantReducer
  )
  const dispatch = useDispatch()

  const [contacts, setContacts] = useState([])
  useEffect(() => {
    if (event) {
      dispatch(getAllEventParticipants(event.id))
    }
  }, [dispatch, event])

  useEffect(() => {
    if (participants && participants.length > 0) {
      setContacts(participants)
    }
  }, [participants])

  const [addFormData, setAddFormData] = useState({
    name: "",
    contact_number: "",
    bib_number: "",
    email_id: "",
    age: "",
    gender: "",
    age_group: "",
    date_of_birth: "",
  })

  const [editFormData, setEditFormData] = useState({
    name: "",
    contact_number: "",
    bib_number: "",
    email_id: "",
    age: "",
    gender: "",
    age_group: "",
    date_of_birth: "",
  })
  const [editContactId, setEditContactId] = useState(null)

  const handleAddFormChange = (event) => {
    event.preventDefault()

    const fieldName = event.target.getAttribute("name")
    const fieldValue = event.target.value

    const newFormData = { ...addFormData }
    newFormData[fieldName] = fieldValue

    setAddFormData(newFormData)
  }

  const handleEditFormChange = (event) => {
    event.preventDefault()

    const fieldName = event.target.getAttribute("name")
    const fieldValue = event.target.value

    const newFormData = { ...editFormData }
    newFormData[fieldName] = fieldValue

    setEditFormData(newFormData)
  }

  const handleAddFormSubmit = (e) => {
    e.preventDefault()

    const newContact = {
      name: addFormData.name,
      contact_number: addFormData.contact_number,
      bib_number: addFormData.bib_number,
      email_id: addFormData.email_id,
      age: addFormData.age,
      gender: addFormData.gender,
      event_id: event.id,
      age_group: addFormData.age_group,
      date_of_birth: addFormData.date_of_birth,
    }
    dispatch(createParticipant(newContact, event.id))
  }

  const handleEditFormSubmit = (e) => {
    e.preventDefault()
    const editedContact = {
      name: editFormData.name,
      contact_number: editFormData.contact_number,
      bib_number: editFormData.bib_number,
      email_id: editFormData.email_id,
      age: editFormData.age,
      gender: editFormData.gender,
      event_id: event.id,
      age_group: editFormData.age_group,
      date_of_birth: editFormData.date_of_birth,
    }
    setEditContactId(null)
    dispatch(updateParticipant(editedContact, event.id))
  }

  const handleEditClick = (event, contact) => {
    event.preventDefault()

    setEditContactId(contact.id)
    const formValues = {
      name: contact.name,
      contact_number: contact.contact_number,
      bib_number: contact.bib_number,
      email_id: contact.email_id,
      age: contact.age,
      gender: contact.gender,
      age_group: contact.age_group,
      date_of_birth: contact.date_of_birth,
    }
    setEditFormData(formValues)
  }

  const handleCancelClick = () => {
    setEditContactId(null)
  }

  const handleDeleteClick = (contactId) => {
    dispatch(deleteParticipant(contactId, event.id))
  }

  return (
    <Fragment>
      <h1 className="large text-primary">Manage Participants - {event.name}</h1>
      <br></br>
      <Fragment>
        <div className="app-container">
          <h3>Add Participants</h3>

          <form onSubmit={handleAddFormSubmit}>
            <div style={{ overflowX: "auto" }}>
              <Table celled>
                {/* <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Name</Table.HeaderCell>
                    <Table.HeaderCell>Bib Num</Table.HeaderCell>
                    <Table.HeaderCell>Gender</Table.HeaderCell>
                    <Table.HeaderCell>Age</Table.HeaderCell>
                    <Table.HeaderCell>Contact No</Table.HeaderCell>
                    <Table.HeaderCell>Email</Table.HeaderCell>
                    <Table.HeaderCell>DOB</Table.HeaderCell>
                    <Table.HeaderCell>Age Group</Table.HeaderCell>
                    <Table.HeaderCell></Table.HeaderCell>
                  </Table.Row>
                </Table.Header> */}
                <Table.Body>
                  <Table.Row>
                    <Table.Cell>
                      <div className="ui input focus">
                        <input
                          type="text"
                          name="name"
                          required="required"
                          placeholder="Participant Name"
                          onChange={handleAddFormChange}
                        />
                      </div>
                    </Table.Cell>
                    <Table.Cell>
                      <div className="ui input focus">
                        <input
                          type="text"
                          name="bib_number"
                          required="required"
                          placeholder="Bib Number"
                          onChange={handleAddFormChange}
                        />
                      </div>
                    </Table.Cell>
                    <Table.Cell>
                      <div className="ui input focus">
                        <input
                          type="text"
                          name="gender"
                          placeholder="Gender"
                          onChange={handleAddFormChange}
                        />
                      </div>
                    </Table.Cell>
                    <Table.Cell>
                      <div className="ui input focus">
                        <input
                          type="text"
                          name="age"
                          placeholder="Age"
                          onChange={handleAddFormChange}
                        />
                      </div>
                    </Table.Cell>

                    <Table.Cell>
                      <div className="ui input focus">
                        <input
                          type="text"
                          name="contact_number"
                          required="required"
                          placeholder="Participant Contact"
                          onChange={handleAddFormChange}
                        />
                      </div>
                    </Table.Cell>

                    <Table.Cell>
                      <div className="ui input focus">
                        <input
                          type="email"
                          name="email_id"
                          required="required"
                          placeholder="Email"
                          onChange={handleAddFormChange}
                        />
                      </div>
                    </Table.Cell>
                    <Table.Cell>
                      <div className="ui input focus">
                        <input
                          type="text"
                          name="date_of_birth"
                          placeholder="Date of birth"
                          onChange={handleAddFormChange}
                        />
                      </div>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>
                      <div className="ui input focus">
                        <input
                          type="text"
                          name="age_group"
                          placeholder="Age Group"
                          onChange={handleAddFormChange}
                        />
                      </div>
                    </Table.Cell>
                    <Table.Cell>
                      <div className="ui input focus">
                        <button
                          className="ui positive basic button"
                          type="submit"
                        >
                          Add
                        </button>
                      </div>
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </div>
          </form>

          <br></br>

          <h3>Participant Details</h3>
          <form onSubmit={handleEditFormSubmit}>
            <div style={{ overflowX: "auto" }}>
              <Table>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Name</Table.HeaderCell>
                    <Table.HeaderCell>Bib Num</Table.HeaderCell>
                    <Table.HeaderCell>Gender</Table.HeaderCell>
                    <Table.HeaderCell>Age</Table.HeaderCell>
                    <Table.HeaderCell>Contact No</Table.HeaderCell>
                    <Table.HeaderCell>Email</Table.HeaderCell>
                    <Table.HeaderCell>DOB</Table.HeaderCell>
                    <Table.HeaderCell>Age Group</Table.HeaderCell>
                    <Table.HeaderCell></Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {contacts.map((contact) => (
                    <>
                      {editContactId === contact.id ? (
                        <EditableRow
                          editFormData={editFormData}
                          handleEditFormChange={handleEditFormChange}
                          handleCancelClick={handleCancelClick}
                        />
                      ) : (
                        <ReadOnlyRow
                          contact={contact}
                          handleEditClick={handleEditClick}
                          handleDeleteClick={handleDeleteClick}
                        />
                      )}
                    </>
                  ))}
                </Table.Body>
              </Table>
            </div>
          </form>
        </div>
      </Fragment>
    </Fragment>
  )
}

export default CreateEditParticipants
