import React, { useEffect, Fragment } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { getCurrentProfile, deleteAccount } from '../../user/actions/profile'
import Spinner from '../../shared/components/Spinner'
import DashboardCards from './cards/DashboardCards'

const Dashboard = () => {
  const { user } = useSelector((state) => state.auth)
  const { profile, loading } = useSelector((state) => state.profile)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getCurrentProfile())
  }, [dispatch])

  return loading && profile == null ? (
    <Spinner />
  ) : (
    <Fragment>
      <h1 className='large text-primary'>Dashboard</h1>
      <p className='lead'>
        <i className='fas fa-user'></i> Welcome {user && user.name}
      </p>
      {profile !== null ? (
        <Fragment>
          <DashboardCards />
        </Fragment>
      ) : (
        <Fragment>
          <p>You have not yet setup a profile, please add some info</p>
          <Link to='/create-profile' className='btn btn-primary my-1'>
            Create Profile
          </Link>
        </Fragment>
      )}
    </Fragment>
  )
}

export default Dashboard
