import React, { Fragment, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link, NavLink } from 'react-router-dom'
import { logout } from '../../user/actions/auth'
import { SET_USER_ROLE } from '../../user/actions/types'
import './Navbar.css'

const Navbar = () => {
  const auth = useSelector((state) => state.auth)
  const dispatch = useDispatch()

  const { isAuthenticated, loading, user, role } = auth

  useEffect(() => {
    if (user) {
      let userRole = user['role_id']
      let role = 'NOT_DEFINED'
      if (userRole == 1) {
        role = 'ADMIN'
      } else if (userRole == 2) {
        role = 'PHOTOGRAPHER'
      } else if (userRole == 3) {
        role = 'LEAD_PHOTOGRAPHER'
      } else if (userRole == 4) {
        role = 'EVENT_COORDINATOR'
      } else if (userRole == 5) {
        role = 'CONTRACTOR'
      }
      dispatch({ type: SET_USER_ROLE, payload: role })
    }
  }, [dispatch, user])

  const onLogout = (e) => {
    e.preventDefault()
    dispatch(logout())
    document.location.href = '/'
  }

  const guestLinks = (
    <ul>
      <li>
        <NavLink to='/register'>Register</NavLink>
      </li>
      <li>
        <NavLink to='/login'>Login</NavLink>
      </li>
    </ul>
  )

  const authLinks = (
    <ul>
      <li>
        <Link tabIndex='-1' to='/dashboard'>
          <i tabIndex='-1' className='fas fa-user'></i>{' '}
          <span tabIndex='-1' className='hide-sm'>
            Dashboard
          </span>
        </Link>
      </li>
      <li>
        <>
          <a tabIndex='-1' href='/logout' onClick={onLogout}>
            <i tabIndex='-1' className='fas fa-sign-out-alt'></i>
            <span tabIndex='-1' className='hide-sm'>
              Logout
            </span>
          </a>
        </>
      </li>
    </ul>
  )

  const adminLinks = (
    <ul>
      <li>
        <Link to='/dashboard'>
          <i className='fas fa-user'></i>{' '}
          <span className='hide-sm'>Dashboard</span>
        </Link>
      </li>
      <li>
        <>
          <a href='/logout' onClick={onLogout}>
            <i className='fas fa-sign-out-alt'></i>
            <span className='hide-sm'>Logout</span>
          </a>
        </>
      </li>
    </ul>
  )

  return (
    <nav className='navigation-bar bg-dark'>
      {!loading && isAuthenticated ? (
        <h1>
          <>Veloscope Image Manager</>
        </h1>
      ) : (
        <h1>
          <Link to='/'>Veloscope Image Manager</Link>
        </h1>
      )}

      {role.length > 0 ? (
        !loading &&
        isAuthenticated && (
          <Fragment>{role == 'ADMIN' ? adminLinks : authLinks}</Fragment>
        )
      ) : (
        <></>
      )}

      {!loading && !isAuthenticated && <Fragment>{guestLinks}</Fragment>}
    </nav>
  )
}

export default Navbar
