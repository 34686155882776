import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { DragDropContext } from "react-beautiful-dnd"
import { BrandingFilter } from "./BrandingFilter"
import {
  getBrandingCategories,
  getBrandingFilterOptions,
  getBrandingFilters,
} from "../../actions/eventActions"

//TODO RULE CONFIGURATOE DELETE SHOUDL BE MAPPED

const onDragEnd = (result, columns, setColumns) => {
  if (!result.destination) return
  const { source, destination } = result

  if (source.droppableId !== destination.droppableId) {
    const sourceColumn = columns[source.droppableId]
    const destColumn = columns[destination.droppableId]
    const sourceItems = [...sourceColumn.items]
    const destItems = [...destColumn.items]
    const [removed] = sourceItems.splice(source.index, 1)
    destItems.splice(destination.index, 0, removed)
    setColumns({
      ...columns,
      [source.droppableId]: {
        ...sourceColumn,
        items: sourceItems,
      },
      [destination.droppableId]: {
        ...destColumn,
        items: destItems,
      },
    })
  } else {
    const column = columns[source.droppableId]
    const copiedItems = [...column.items]
    const [removed] = copiedItems.splice(source.index, 1)
    copiedItems.splice(destination.index, 0, removed)
    setColumns({
      ...columns,
      [source.droppableId]: {
        ...column,
        items: copiedItems,
      },
    })
  }
}

export const RuleConfigurator = () => {
  const { event, brandingFilters, brandingFilterOptions, brandingCategories } =
    useSelector((state) => state.eventReducer)
  const dispatch = useDispatch()
  const [columns, setColumns] = useState(null)

  useEffect(() => {
    dispatch(getBrandingFilters())
  }, [dispatch])

  useEffect(() => {
    if (brandingFilters) {
      dispatch(getBrandingFilterOptions(brandingFilters))
    }
  }, [dispatch, brandingFilters])

  useEffect(() => {
    dispatch(getBrandingCategories(event.id))
  }, [dispatch])

  useEffect(() => {
    if (brandingFilters && brandingCategories) {
      let toFilters = []
      let fromFilters = []
      brandingFilters.map((brandingFilter, index) => {
        if (brandingCategories[brandingFilter.toUpperCase()] !== undefined) {
          toFilters.push({
            id: index.toString(),
            content: brandingFilter.toUpperCase(),
          })
        } else {
          fromFilters.push({
            id: index.toString(),
            content: brandingFilter.toUpperCase(),
          })
        }
      })
      const filterGroup = {
        from: {
          name: "Available Filters",
          items: fromFilters,
        },
        to: {
          name: "Choose Filters & Configure Rules",
          items: toFilters,
        },
      }
      setColumns(filterGroup)
    }
  }, [brandingFilters, brandingCategories])

  return (
    <>
      {columns && brandingFilterOptions ? (
        <DragDropContext
          onDragEnd={(result) => onDragEnd(result, columns, setColumns)}
        >
          {Object.entries(columns).map(([columnId, column], index) => (
            <div className="ui segment" style={{ width: "46%" }} key={index}>
              <div className="column">
                <div>{column.name}</div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                  key={columnId}
                >
                  <BrandingFilter columnId={columnId} column={column} />
                </div>
              </div>
            </div>
          ))}
        </DragDropContext>
      ) : (
        <></>
      )}
    </>
  )
}
