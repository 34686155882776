import React, { useState, useEffect } from 'react'
import { Button, Label, Card, Segment, Header, Icon } from 'semantic-ui-react'
import { setUploadFile } from '../actions/uploadActions'
import { useSelector, useDispatch } from 'react-redux'

function UploadFile({ id, header, cloudImage }) {
  const { fileToUpload } = useSelector((state) => state.uploadReducer)

  const [image, setImage] = useState()
  const dispatch = useDispatch()
  function handleChange(e) {
    let data = { [e.target.id]: e.target.files[0] }
    dispatch(setUploadFile(data))
  }

  useEffect(() => {
    if (fileToUpload) {
      if (fileToUpload[id]) {
        setImage(fileToUpload[id])
      }
    }
  }, [fileToUpload, { id }, setImage])

  return (
    <Segment placeholder id={id}>
      <Label>{header}</Label>

      {image ? (
        <Card>
          <img src={URL.createObjectURL(image)} />
        </Card>
      ) : (
        <>
          {cloudImage ? (
            <Card>
              <img src={cloudImage} />
            </Card>
          ) : (
            <Header icon>
              <Icon name='file image outline' />
            </Header>
          )}
        </>
      )}

      <Label>
        <input id={id} type='file' onChange={handleChange} />
      </Label>
      <Button primary>Upload</Button>
    </Segment>
  )
}
export default UploadFile
