import React, { useEffect, Fragment } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getCurrentProfile } from '../../user/actions/profile'
import Spinner from '../../shared/components/Spinner'
import EventsCards from './cards/EventsCard'

const EventsScreen = () => {
  const { profile, loading } = useSelector((state) => state.profile)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getCurrentProfile())
  }, [dispatch])

  return loading && profile == null ? (
    <Spinner />
  ) : (
    <Fragment>
      <h1 className='large text-primary'>Events</h1>
      <Fragment>
        <EventsCards />
      </Fragment>
    </Fragment>
  )
}

export default EventsScreen
