import React from "react"
import { Button, Card, Icon } from "semantic-ui-react"
import { Link } from "react-router-dom"

const ClassificationBrandingCard = (event) => {
  return (
    <Card>
      <Card.Content>
        <Card.Header>Dyanamic Branding Rules</Card.Header>
        <Card.Meta>Configure Dynamic Branding Rule Engine</Card.Meta>
        <Icon.Group size="large">
          <Icon name="users" />
        </Icon.Group>
        <Card.Description>
          Configure Portrait and Landscape Dyanamic Branding Images
        </Card.Description>
      </Card.Content>
      <Card.Content extra>
        <div className="ui two buttons">
          {event.is_dynamic_branding_enabled ? (
            <Button basic color="green">
              <Link
                to={`/event/${event.id}/classification-branding-images`}
                className="btn btn-light"
              >
                Configure
              </Link>
            </Button>
          ) : (
            <Button basic color="gray" disabled>
              <Link
                to={`/event/${event.id}/classification-branding-images`}
                className="btn btn-light"
              >
                Configure
              </Link>
            </Button>
          )}
        </div>
      </Card.Content>
    </Card>
  )
}

export default ClassificationBrandingCard
