import React, { useState } from 'react'
import { Link, Redirect } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { login } from '../../actions/auth'

const Register = () => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  })

  const auth = useSelector((state) => state.auth)

  const { isAuthenticated, user } = auth

  const dispatch = useDispatch()

  const { email, password } = formData

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const onSubmit = async (e) => {
    e.preventDefault()
    dispatch(login(email, password))
  }

  // Redirect if logged in
  if (isAuthenticated) {
    let isAdmin = false
    if (user != null) {
      isAdmin = user['is_admin']
      if (isAdmin) {
        return <Redirect to='/dashboard' />
      }
    }
    return <Redirect to='/dashboard' />
  }

  return (
    <React.Fragment>
      <h1 className='large text-primary'>Sign In</h1>
      <p className='lead'>
        <i className='fas fa-user'></i> Sign Into Your Account
      </p>
      <form className='form' onSubmit={onSubmit}>
        <div className='form-group'>
          <input
            type='email'
            placeholder='Email Address'
            name='email'
            value={email}
            onChange={onChange}
          />
        </div>
        <div className='form-group'>
          <input
            type='password'
            placeholder='Password'
            name='password'
            minLength='6'
            value={password}
            onChange={onChange}
          />
        </div>
        <input type='submit' className='btn btn-primary' value='Login' />
      </form>
      <p className='my-1'>
        Don't have an account? <Link to='/register'>Sign Up</Link>
      </p>
    </React.Fragment>
  )
}

export default Register
