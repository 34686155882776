import React from "react"
import { Button, Card, Icon } from "semantic-ui-react"
import { Link } from "react-router-dom"

const ApplyBrandingCard = (event) => {
  return (
    <Card>
      <Card.Content>
        <Card.Header>Apply Generic Branding</Card.Header>
        <Card.Meta>Apply generic branding on all uploaded images</Card.Meta>
        <Icon.Group size="large">
          <Icon name="users" />
        </Icon.Group>
        <Card.Description>
          Applies generic branding on all the non branded images of the event.
          Generic branding images should be configured to execute the module.
          Incase if branding is not available while uploading images, this
          module helps to apply branding post uploading.
        </Card.Description>
      </Card.Content>
      <Card.Content extra>
        <div className="ui two buttons">
          {event.is_dynamic_branding_enabled ? (
            <Button basic color="grey" disabled>
              <Link
                to={`/event/${event.id}/apply-branding`}
                className="btn btn-light"
              >
                Configure
              </Link>
            </Button>
          ) : (
            <Button basic color="green">
              <Link
                to={`/event/${event.id}/apply-branding`}
                className="btn btn-light"
              >
                Configure
              </Link>
            </Button>
          )}
        </div>
      </Card.Content>
    </Card>
  )
}

export default ApplyBrandingCard
