import React, { Fragment, useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Tab, Message, Button } from 'semantic-ui-react'
import Spinner from '../../shared/components/Spinner'
import Select from 'react-select'
import DateFnsUtils from '@date-io/date-fns'
import {
  MuiPickersUtilsProvider,
  DatePicker,
  TimePicker,
} from 'material-ui-pickers'
import { getEventPhotoPoints } from '../../events/actions/eventActions'
import { searchImagesOnLocationDate } from '../actions/faceActions'

import './SearchLocation.module.css'

function SearchLocationDate() {
  const { event, photoPoints } = useSelector((state) => state.eventReducer)
  const { loading, locationTimeImageResults } = useSelector(
    (state) => state.faceReducer
  )
  const [selectedDate, handleDateChange] = useState(new Date())
  const [startTime, handleStartTime] = useState()
  const [endTime, handleEndTime] = useState()
  const [photoPointList, setPhotoPointList] = useState([])
  const [photoPoint, setPhotoPoint] = useState(null)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getEventPhotoPoints(event.id))
  }, [dispatch])

  useEffect(() => {
    let val = []
    if (photoPoints && photoPoints.length > 0) {
      val.push({ value: 'SELECT', label: 'Select Photo Point' })
      photoPoints.map((photoPoint) => {
        val.push({
          value: photoPoint,
          label: photoPoint,
        })
      })
      setPhotoPointList(val)
    }
  }, [photoPoints])

  const onPhotoPointChange = (e) => {
    setPhotoPoint(e['value'])
  }
  const onSubmit = (e) => {
    e.preventDefault()

    const year = selectedDate.getFullYear()
    const month = ('0' + (selectedDate.getMonth() + 1)).slice(-2)
    const day = ('0' + selectedDate.getDate()).slice(-2)
    const selectedDay = `${year}:${month}:${day}`

    const from_hour = ('0' + startTime.getHours()).slice(-2)
    const from_minute = ('0' + startTime.getMinutes()).slice(-2)
    const from_second = ('0' + startTime.getSeconds()).slice(-2)
    const fromTime = `${from_hour}:${from_minute}:${from_second}`

    const to_hour = ('0' + endTime.getHours()).slice(-2)
    const to_minute = ('0' + endTime.getMinutes()).slice(-2)
    const to_second = ('0' + endTime.getSeconds()).slice(-2)
    const toTime = `${to_hour}:${to_minute}:${to_second}`

    if (photoPoint === 'SELECT') {
      photoPoint = null
    }
    dispatch(
      searchImagesOnLocationDate(
        photoPoint,
        selectedDay + ' ' + fromTime,
        selectedDay + ' ' + toTime,
        event.id
      )
    )
  }

  return (
    <div>
      <Fragment>
        <h2 className='medium text-primary'>
          Search Images on Location & Date - {event.name}
        </h2>
        <Tab.Pane attached={false}>
          <Message>
            Filter Images on the Photo Points and Image taken time
          </Message>
          <form className='upload-steps' onSubmit={onSubmit}>
            <div id='search-location-container'>
              {photoPointList ? (
                <>
                  <div class='box1'>
                    <Select
                      className='basic-single'
                      classNamePrefix='select'
                      isDisabled={false}
                      isLoading={false}
                      isClearable={true}
                      isRtl={false}
                      isSearchable={true}
                      name='photoPoint'
                      id='photoPoint'
                      options={photoPointList}
                      onChange={(event) => onPhotoPointChange(event)}
                    />
                  </div>
                  <br></br>
                </>
              ) : (
                <>
                  <div class='box1'>
                    No Photo Points Configured for the event
                  </div>
                </>
              )}

              <div class='box2'>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    label='Choose the date'
                    value={selectedDate}
                    onChange={handleDateChange}
                    style={{ height: '50px' }}
                  />
                </MuiPickersUtilsProvider>
              </div>
              <br></br>
              <div class='box3'>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <TimePicker
                    label='Choose the start time'
                    value={startTime}
                    onChange={handleStartTime}
                    style={{ height: '50px' }}
                  />
                </MuiPickersUtilsProvider>
              </div>
              <br></br>
              <div class='box4'>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <TimePicker
                    label='Choose the end time'
                    value={endTime}
                    onChange={handleEndTime}
                    style={{ height: '50px' }}
                  />
                </MuiPickersUtilsProvider>
              </div>
              <br></br>
              <div class='box5'>
                <Button
                  variant='outlined'
                  style={{ height: '45px', width: '120px' }}>
                  Search
                </Button>
              </div>
            </div>
          </form>
        </Tab.Pane>
        {loading ? <Spinner /> : <></>}
        <Tab.Pane attached={false}>
          <Message>Search Results</Message>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            {locationTimeImageResults && locationTimeImageResults.length > 0 ? (
              locationTimeImageResults.map((match) =>
                match.branded.map((result) => (
                  <div
                    key={result.id}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '800px',
                      height: '1200px',
                    }}>
                    <img src={result.image} />
                    <br></br>
                    <br></br>
                    <div
                      className='form-group'
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}></div>
                  </div>
                ))
              )
            ) : (
              <></>
            )}
          </div>
        </Tab.Pane>
      </Fragment>
    </div>
  )
}
export default SearchLocationDate
