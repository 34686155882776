import React, { useState, useEffect } from "react"
import { Button, Label, Card, Segment, Header, Icon } from "semantic-ui-react"
import { setUploadFile } from "../actions/uploadActions"
import { useSelector, useDispatch } from "react-redux"

function UploadCsv({ id, header }) {
  const { fileToUpload } = useSelector((state) => state.uploadReducer)

  const [csvFile, setCsvFile] = useState()
  const dispatch = useDispatch()
  function handleChange(e) {
    let data = { [e.target.id]: e.target.files[0] }
    dispatch(setUploadFile(data))
  }

  useEffect(() => {
    if (fileToUpload) {
      if (fileToUpload[id]) {
        setCsvFile(fileToUpload[id])
      }
    }
  }, [fileToUpload, id])

  return (
    <Segment placeholder id={id}>
      <Label>{header}</Label>

      {csvFile ? (
        <Card>
          <input src={URL.createObjectURL(csvFile)} />
        </Card>
      ) : (
        <Header icon>
          <Icon name="file image outline" />
        </Header>
      )}

      <Label>
        <input
          type="file"
          name="file"
          onChange={handleChange}
          accept=".xlsx, .xls"
          style={{ display: "block", margin: "10px auto" }}
        />
      </Label>
      <Button primary>Process Data</Button>
    </Segment>
  )
}
export default UploadCsv
