import React, { useEffect } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Navbar from './views/shared/components/Navbar'
import Landing from './views/shared/components/Landing'
import Routes from './routes/Routes'

// Redux
import { Provider } from 'react-redux'
import store from './store'
import { loadUser } from './views/user/actions/auth'
import setAuthToken from './utils/setAuthToken'

import './App.css'

if (localStorage.token) {
  setAuthToken(localStorage.token)
}

const App = () => {
  useEffect(() => {
    store.dispatch(loadUser())
  }, [])

  return (
    <Provider store={store}>
      <Router>
        <React.Fragment>
          <Navbar />
          <Switch>
            <Route exact path='/' component={Landing} />
            <Route component={Routes} />
          </Switch>
        </React.Fragment>
      </Router>
    </Provider>
  )
}

export default App
