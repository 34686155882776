import React, { Fragment } from "react"
import { useSelector } from "react-redux"
import GenericBrandingCard from "./cards/GenericBrandingCard"
import ClassificationBrandingCard from "./cards/ClassificationBrandingCard"
import ApplyBrandingCard from "./cards/ApplyBrandingCard"
import { Card } from "semantic-ui-react"

const BrandingScreen = () => {
  const { event } = useSelector((state) => state.eventReducer)

  return (
    <Fragment>
      <h1 className="large text-primary">Configure Branding - {event.name}</h1>
      <Fragment>
        <Card.Group>
          <GenericBrandingCard {...event} />
          <ApplyBrandingCard {...event} />
          <ClassificationBrandingCard {...event} />
        </Card.Group>
      </Fragment>
    </Fragment>
  )
}

export default BrandingScreen
